import moment from "moment-timezone";

export class MessageFrom {
    id: string
    name: string

    constructor(id: string, name: string) {
        this.id = id;
        this.name = name;
    }
}

export class Message {
    id: string
    senderId: string
    text: string
    fromMe: boolean
    from: MessageFrom
    dateTime: moment.Moment

    constructor(id: string, senderId: string, text: string, fromMe: boolean, from: MessageFrom, dateTime: moment.Moment) {
        this.id = id;
        this.senderId = senderId;
        this.text = text;
        this.fromMe = fromMe;
        this.from = from;
        this.dateTime = dateTime;
    }
}
