import './RoomListItem.less'
import {observer} from "mobx-react";
import React from "react";
import {Avatar, Badge} from "antd";
import {Room} from "./store/room";
import {ImageUtils} from "../../services/utils/ImageUtils";
import {dateTimeHelper} from "../../services/utils/DateTimeHelper";

export interface IRoomListItemProps {
    room: Room
    selected: boolean
    selectRoom: (roomId: string) => Promise<void>
}

@observer
export class RoomListItem extends React.Component<IRoomListItemProps, any> {
    render() {
        const {room, selected} = this.props

        const latestMessage = () => {
            const directMemberLatestMessage = () => {
                if (!!room.directMember?.typing)
                    return `Печатает...`
                return room.latestMessage?.text
            }
            const multiMembersLatestMessage = () => {
                const anyWriting = room.typingMembersExceptMe.find(_ => true)
                if (!!anyWriting)
                    return `Печатает... ${anyWriting.name}`
                return room.latestMessage?.text
            }

            return <div className={'latest-message'}>
                {room.isDirect ? directMemberLatestMessage() : multiMembersLatestMessage()}
            </div>
        }

        const roomTitle = room.isDirect
            ? room.directMember!.name ?? room.directMember!.id
            : room.name ?? room.id
        return <div className={`room-list-item ${selected && 'room-list-item-selected'}`}
                    onClick={_ => this.props.selectRoom(room.id)}>
            <div className={'room-image'}>
                <Badge dot={!!room.onlineMembersExceptMe.length}
                       size={'small'}
                       status={"success"}><Avatar src={ImageUtils.UrlOrImageNotFountUrl(room.imageUrl)}
                                                  alt={ImageUtils.ImageNotFountUrl} size={'large'}/></Badge>
            </div>

            <div className={'info'}>
                <div className={'room-name'}>
                    {roomTitle}
                </div>
                {latestMessage()}
            </div>

            {room.latestMessage
                ? <div className={'status'}>
                    <div
                        className={'unread'}>{room.unreadNotificationCount > 0 && `${room.unreadNotificationCount}+`}</div>
                    <div className={'time'} title={dateTimeHelper.toDateAndTimeFormat(room.latestMessage.dateTime)}>
                        {dateTimeHelper.toTimeOrDateByDurationFormat(room.latestMessage.dateTime)}
                    </div>
                </div>
                : undefined
            }
        </div>;
    }
}
