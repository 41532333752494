import {RoomMember} from "./roomMember";
import {Message} from "./message";
import EventEmitter from "events";

export abstract class Room {
    abstract id: string
    abstract name: string
    abstract isDirect: boolean
    abstract imageUrl: string | undefined
    abstract tags: string[]
    abstract members: RoomMember[]
    abstract get typingMembersExceptMe(): RoomMember[]
    abstract get onlineMembersExceptMe(): RoomMember[]
    abstract directMember: RoomMember | undefined
    abstract unreadNotificationCount: number
    abstract get messages(): Message[];
    abstract get latestMessage(): Message | undefined;

    abstract setTyping: (isTyping: boolean) => Promise<void>;
    abstract sendMessage: (text: string) => Promise<void>;

    abstract get messagesLoading(): boolean;
    abstract loadPrevious: () => Promise<void>;
    abstract loadLatestReadMessage: () => Promise<string | undefined>;
    abstract clearLoaded: () => void;

    abstract markReadUntilLatestMessage: () => Promise<void>;
    abstract markReadUntilMessage: (message: Message) => Promise<void>;

    events = {
        emitter: new EventEmitter(),
        message: {
            key: 'Room.Message',
            emit: (msg: Message, timeStatus: 'old' | 'new') => this.events.emitter.emit(this.events.message.key, msg, timeStatus),
            on: (callback: (msg: Message, timeStatus: 'old' | 'new') => any) => this.events.emitter.on(this.events.message.key, callback),
            off: (callback: (msg: Message, timeStatus: 'old' | 'new') => any) => this.events.emitter.off(this.events.message.key, callback)
        }
    }
}
