import {inject, observer} from "mobx-react";
import React from "react";
import {computed, observable} from "mobx";
import {InstructorInfoDto} from "../../../services/api/CoreApiClient";
import {coreClientInstance} from "../../../services/api/coreClient";
import {InstructorCard} from "../../../components/Instructor/InstructorCard";
import {RouteComponentProps} from "react-router-dom";
import axios, {CancelTokenSource} from "axios";
import {Col, Form, Input, List, Row, Typography} from "antd";
import {AuthStore} from "../../../stores/auth/authStore";
import {InjectNames} from "../../../stores/initializeStores";
import {BranchFilter} from "../../../components/CompanyUnit/BranchFilter";
import {CompanyUnitStore} from "../../../stores/companyUnitStore";

interface IInstructorsSceneProps extends RouteComponentProps {
    authStore?: AuthStore
    companyUnitStore?: CompanyUnitStore
}

@inject(InjectNames.authStore, InjectNames.companyUnitStore)
@observer
export class InstructorsScene extends React.Component<IInstructorsSceneProps> {
    public static route = {
        path: '/instructors',
        pathFormat: () => `/instructors`
    }

    async componentDidMount() {
        await this.loadInstructors()
    }

    @observable search: string | undefined = undefined
    setSearch = async (search: string | undefined) => {
        this.search = search
    }

    @observable instructors: InstructorInfoDto[] = []

    @computed get instructorsDataSource() {
        const search = this.search?.toUpperCase()?.replace(/ /g, '')
        if (!search?.length)
            return this.instructors
        else
            return this.instructors.filter(item => `${item.name!.lastName}${item.name!.firstName}${item.name!.middleName}`.toUpperCase().includes(search)
                || `${item.contacts!.phone}`.toUpperCase().includes(search))
    }

    @observable instructorsLoading = false
    private source: CancelTokenSource | null = null
    loadInstructors = async () => {
        try {
            this.instructorsLoading = true
            if (!!this.source)
                this.source!.cancel("re-loading")
            this.source = axios.CancelToken.source()
            this.instructors = (await coreClientInstance.instructor.getInstructorsByFilterCreate({
                branchId: this.props.companyUnitStore!.branchFilter?.branchId
            }, {cancelToken: this.source.token})).data
                .sort((a, b) => a.name!.lastName!.localeCompare(b.name!.lastName!))
        } finally {
            this.instructorsLoading = false
        }
    }

    render() {
        const header = <React.Fragment>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <Typography.Title level={2}>Инструкторы</Typography.Title>
                <Form.Item>
                    <Input placeholder={'Поиск'} value={this.search} allowClear={true}
                           onChange={event => this.setSearch(event.target.value)}/>
                </Form.Item>
            </div>
        </React.Fragment>

        const filters = !!this.props.authStore!.authorizedUser?.hasMultiplyBranches
            && <Form.Item label={'Фильтр по городу'} labelCol={{span: 24}}>
                <BranchFilter onChange={this.loadInstructors}/>
            </Form.Item>


        const instructors = <List
            dataSource={this.instructorsDataSource}
            grid={{ gutter: 15, column: 2 }}
            loading={this.instructorsLoading}
            pagination={{
                hideOnSinglePage: true,
                defaultPageSize: 10,
                showSizeChanger: false,
                position: "both"
            }}
            renderItem={instructor => <List.Item key={instructor.userId}><InstructorCard {...this.props} instructor={instructor}/></List.Item>}
        />

        return <React.Fragment>
            <Row gutter={[15, 15]}>
                <Col span={24}>
                    {header}
                </Col>
                <Col span={8}>
                    {filters}
                </Col>
                <Col span={24}>
                    {instructors}
                </Col>
            </Row>
        </React.Fragment>

    }
}
