import axios, {AxiosError} from "axios"
import {InitializedStores} from "../../stores/initializeStores";
import {Modal} from "antd";

export interface ApiErrorResponse {
    "errorCode"?: number | null,
    "errors"?: any,
    "type"?: string,
    "title"?: string,
    "status": number,
    "detail"?: string,
    "instance"?: string,
}


class ErrorUtils {
    handleApiError = (error: AxiosError<ApiErrorResponse>) => {
        if (!error.response) {
            if (axios.isCancel(error)) {
                // Ignore
            } else {
                Modal.error({title: error.message})
            }
        } else {
            const axiosResponse = error.response
            const apiError = axiosResponse.data!
            switch (axiosResponse.status as number) {
                case 401:
                    if (InitializedStores.authStore.isAuthorized) {
                        Modal.error({title: apiError.title, cancelText: undefined})
                        InitializedStores.authStore.signOut()
                    }
                    break;
                default:
                    Modal.error({title: apiError.title, content: apiError.detail})
                    break;
            }
        }

        return Promise.reject(error);
    }
}

const errorUtils = new ErrorUtils();

export {errorUtils};
