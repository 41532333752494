import './Login/index.less';
import * as React from 'react';
import {observer} from "mobx-react";
import {RouteComponentProps, Switch} from "react-router-dom";
import {getNotAuthorizedRoutes} from "../../routing/RouteSettings";

declare interface IProps extends RouteComponentProps {
}

@observer
export default class UnauthorizedContainer extends React.Component<IProps> {
    public render() {
        return (
            <Switch>
                {getNotAuthorizedRoutes()}
            </Switch>
        );
    }
}

