import './ActiveRoomHeader.less'
import {observer} from "mobx-react";
import React from "react";
import {RoomMembersList} from "./RoomMembersList";
import {Room} from "./store/room";
import {Avatar} from "antd";
import {ImageUtils} from "../../services/utils/ImageUtils";

export interface IActiveRoomHeaderProps {
    room: Room
}

@observer
export class ActiveRoomHeader extends React.Component<IActiveRoomHeaderProps, any> {
    render() {
        const {room} = this.props
        const view = (imageUrl: string | undefined, name: string, isWriting: string | undefined, count: number | undefined) =>
            <div className={'active-room-header'}>
                <div className={'info'}>
                    <div className={'image'}>
                        <Avatar src={ImageUtils.UrlOrImageNotFountUrl(imageUrl)}
                                alt={ImageUtils.ImageNotFountUrl} size={'large'}/>
                    </div>
                    <div>
                        <div className={'name'}>
                            {name}
                        </div>
                        {isWriting !== undefined && <div className={'writing'}>{isWriting}</div>}
                    </div>
                </div>
                {count !== undefined && <RoomMembersList room={room} trigger={
                    <div className={'count'}>Участников: {room.members.length}</div>
                }/>}
            </div>
        const directMember = () => {
            const member = room.directMember!
            return view(member.imageUrl, member.name, member.typing ? 'Печатает...' : undefined, undefined)
        }
        const multiMembers = () => {
            const anyWriting = room.typingMembersExceptMe.find(_ => true)
            return view(room.imageUrl, room.name ?? room.id, !!anyWriting ? `${anyWriting.name} печатает...` : undefined, room.members.length)
        }

        return room.isDirect ? directMember() : multiMembers()
    }
}
