import moment from "moment-timezone";

class DateTimeHelper {
    public BackendDateFormat = 'YYYY-MM-DD'
    public fromBackendDateTime = (dateTime: string | null | undefined) => (!!dateTime ? moment(dateTime) : dateTime) as moment.Moment | null | undefined
    public toBackendDateTime = (dateTime: moment.Moment) => dateTime.toISOString()

    public toBackendDate = (dateTime: moment.Moment) => dateTime.format(this.BackendDateFormat)

    public BackendTimeZoneFormat = "hh:mm"
    public toBackendTimeZone = () => moment.utc().startOf("day").add(moment().utcOffset(), "minutes").format(this.BackendTimeZoneFormat)


    public DateTimeFormat = 'YYYY-MM-DDTHH:mm:ss'
    public toDateTimeFormat = (dateTime: moment.Moment) => dateTime.format(this.DateTimeFormat)
    public toDateTimeFormatOrNull = (dateTime: moment.Moment | null) => !!dateTime ? this.toDateTimeFormat(dateTime) : dateTime


    public DurationFormat = "HH:mm"
    public toTimeFormat = (dateTime: moment.Moment) => dateTime.format(this.DurationFormat)
    public toTimeFormatFromString = (dateTime: string) => this.toTimeFormat(moment(dateTime))

    public toTimeOrDateByDurationFormat = (dateTime: moment.Moment) => {
        let now = moment()
        if (this.isDifferentDates(now, dateTime))
            return dateTime.format('DD.MM')
        else {
            return this.toTimeFormat(dateTime)
        }
    }

    public DateAndTimeFormat = 'DD.MM.YYYY, HH:mm'
    public toDateAndTimeFormat = (dateTime: moment.Moment) => dateTime.format(this.DateAndTimeFormat)
    public toDateAndTimeFormatFromString = (dateTime: string) => this.toDateAndTimeFormat(moment(dateTime))

    public DateFormat = 'DD.MM.YYYY'
    public toDateFormat = (dateTime: moment.Moment) => dateTime.format(this.DateFormat)
    public toDateFormatFromString = (dateTime: string) => this.toDateFormat(moment(dateTime))

    public isDifferentDates = (dateTimeA: moment.Moment, dateTimeB: moment.Moment) => dateTimeA.dayOfYear() !== dateTimeB.dayOfYear()
    public isDifferentDatesFromString = (dateTimeA: string, dateTimeB: string) => this.isDifferentDates(moment(dateTimeA), moment(dateTimeB))


    public humanizeDurationFromString = (duration: string) => moment.duration(duration).humanize()
}

export const dateTimeHelper = new DateTimeHelper()
