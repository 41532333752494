import {CallsTable} from "../../../components/Calls/CallsTable";
import {inject, observer} from "mobx-react";
import {RouteComponentProps} from "react-router-dom";
import React from "react";
import {coreClientInstance} from "../../../services/api/coreClient";
import {observable} from "mobx";
import {CallDto, CallStatus, OfficeDto} from "../../../services/api/CoreApiClient";
import {Col, Form, Row} from "antd";
import {OfficeFilter} from "../../../components/CompanyUnit/OfficeFilter";
import {AuthStore} from "../../../stores/auth/authStore";
import {InjectNames} from "../../../stores/initializeStores";
import {CallStatusSelect} from "../../../components/Calls/CallStatusSelect";
import axios, {CancelTokenSource} from "axios";
import { CompanyUnitStore } from "../../../stores/companyUnitStore";

export interface ICallsSceneProps extends RouteComponentProps {
    authStore?: AuthStore
    companyUnitStore?: CompanyUnitStore
}

@inject(InjectNames.authStore, InjectNames.companyUnitStore)
@observer
export class CallsScene extends React.Component<ICallsSceneProps, any> {

    public static route = {
        path: '/calls'
    }

    async componentDidMount() {
        await this.loadCalls()
    }

    @observable calls: CallDto[] = []
    @observable callsLoading = true

    private loadCallsTokenSource: CancelTokenSource | null = null
    loadCalls = async () => {
        try {
            this.callsLoading = true

            if (!!this.loadCallsTokenSource)
                this.loadCallsTokenSource!.cancel("re-loading")
            this.loadCallsTokenSource = axios.CancelToken.source()

            this.calls = (await coreClientInstance.calls.getAllList({
                BranchId: this.props.companyUnitStore?.officeFilter?.branchId,
                OfficeId: this.props.companyUnitStore?.officeFilter?.officeId,
                Status: this.callStatusFilter
            }, {cancelToken: this.loadCallsTokenSource!.token})).data
        } finally {
            this.callsLoading = false
        }
    }

    //Filter
    @observable officeFilter: OfficeDto | undefined

    setOfficeFilter = (value: OfficeDto | undefined) => {
        this.officeFilter = value
        this.props.companyUnitStore?.setOfficeParam(value);
        return this.loadCalls()
    }

    @observable callStatusFilter: CallStatus = CallStatus.New

    setCallStatusFilter = (value: CallStatus) => {
        this.callStatusFilter = value
        return this.loadCalls()
    }

    //Filter

    changeCallStatus = async (callId: string, status: CallStatus) => {
        try {
            this.callsLoading = true
            await coreClientInstance.calls.changeStatusUpdate({callId: callId, status: status})
        } finally {
            this.callsLoading = false
        }
        this.calls = this.calls.filter(call => call.id !== callId)
    }

    render() {
        const statusFilter = <Form.Item label={'Статус'} labelCol={{span: 24}}>
            <CallStatusSelect mode={'button'} value={this.callStatusFilter} onChange={this.setCallStatusFilter}/>
        </Form.Item>
        const officeFilter = !!this.props.authStore!.authorizedUser?.hasMultiplyOffices
            && <Form.Item label={'Фильтр по филиалу'} labelCol={{span: 24}}>
                <OfficeFilter authorizedUser={this.props.authStore?.authorizedUser} onChange={this.setOfficeFilter}/>
            </Form.Item>
        const filter = <React.Fragment>
            <Row>
                <Col>{statusFilter}</Col>
                <Col>{officeFilter}</Col>
            </Row>
        </React.Fragment>

        return <React.Fragment>
            {filter}
            <CallsTable calls={this.calls} loading={this.callsLoading} changeCallStatus={this.changeCallStatus}/>
        </React.Fragment>
    }
}
