import React from "react";
import {BaseEventDto, EventType} from "../../services/api/CoreApiClient";
import {EnumFriendlyMapper} from "../../services/utils/EnumFriendlyMapper";
import {Avatar, Card, Typography} from "antd";
import Meta from "antd/es/card/Meta";
import {ImageUtils} from "../../services/utils/ImageUtils";
import {Link, RouteComponentProps} from "react-router-dom";
import {DrivingSessionDetailsScene} from "../../scenes/authorized/Sessions/DrivingSessionDetailsScene";
import {EventItemHeader} from "./EventItemHeader";
import {EventUserLink} from "./EventUserLink";

export interface IEventItemOptions {
    drivingOptions: {
        hideDrivingDetails?: boolean
        hideStudentDetails?: boolean
        hideInstructorDetails?: boolean
    }
}

export interface IEventItemProps extends RouteComponentProps {
    historyItem: BaseEventDto
    options?: IEventItemOptions
}

export class EventItem extends React.Component<IEventItemProps, any> {
    public static isSupported = (eventType: EventType) => true

    render() {
        switch (this.props.historyItem.type!) {
            case EventType.DrivingRequest:
            case EventType.DrivingRequestConfirmed:
            case EventType.DrivingRequestCanceled:
            case EventType.DrivingCanceled:
            case EventType.DrivingAssigned:
            case EventType.DrivingLessonPassed:
            case EventType.DrivingStartingSoon:
            case EventType.DrivingCancelRequest:
            case EventType.DrivingCancelRequestCanceled:
            case EventType.DrivingCancelRequestApproved:
                return <DrivingEventItem {...this.props}/>
            default:
                return <DefaultEventItem {...this.props}/>
        }
    }
}

class DefaultEventItem extends React.Component<IEventItemProps, any> {
    render() {
        const history = this.props.historyItem
        const {Text} = Typography;
        const dangerEvents: EventType[] = []

        const description =
            <span><Text
                type={dangerEvents.some(type => type === history.type) ? 'danger' : 'success'}>{EnumFriendlyMapper.mapEventType(history)}</Text>. {history.targetUsers?.map(user =>
                <EventUserLink key={user.userId} user={user}/>)}</span>
        return <Card>
            <Meta
                avatar={<Avatar size={40} shape={'circle'}
                                src={ImageUtils.UrlOrImageNotFountUrl(history.initializer?.imageUrl)}
                                alt={ImageUtils.ImageNotFountUrl}/>}
                title={<EventItemHeader {...this.props}/>}
                description={description}
            />
        </Card>
    }
}

class DrivingEventItem extends React.Component<IEventItemProps, any> {
    render() {
        const history = this.props.historyItem
        let actionName = EnumFriendlyMapper.mapEventType(history)
        let linkToDriving = undefined

        const options = this.props.options?.drivingOptions
        if (!options?.hideDrivingDetails)
            linkToDriving = <Link to={DrivingSessionDetailsScene.route.pathFormat(this.props.historyItem.driving!.sessionId!)}>Вождение</Link>

        const studentLink = !options?.hideStudentDetails && <EventUserLink user={history.driving!.student!}/>
        const instructorLink = !options?.hideInstructorDetails && <EventUserLink user={history.driving!.instructor!}/>
        const description = <span>{linkToDriving} {actionName} {studentLink} {instructorLink}</span>

        return <Card>
            <Meta
                avatar={<Avatar size={40} shape={'circle'}
                                src={ImageUtils.UrlOrImageNotFountUrl(history.initializer?.imageUrl)}
                                alt={ImageUtils.ImageNotFountUrl}/>}
                title={<EventItemHeader {...this.props}/>}
                description={description}
            />
        </Card>
    }
}
