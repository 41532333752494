import React from "react";
import {observable} from "mobx";
import moment, {Moment} from "moment-timezone";
import {inject, observer} from "mobx-react";
import {coreClientInstance} from "../../../services/api/coreClient";
import {BaseEventDto, EventType, OfficeDto} from "../../../services/api/CoreApiClient";
import {Col, DatePicker, Form, Row, Typography} from "antd";
import {RouteComponentProps} from "react-router-dom";
import {AuthStore} from "../../../stores/auth/authStore";
import {InjectNames} from "../../../stores/initializeStores";
import {OfficeFilter} from "../../../components/CompanyUnit/OfficeFilter";
import {dateTimeHelper} from "../../../services/utils/DateTimeHelper";
import {Events} from "../../../components/History/Events";
import {CompanyUnitStore} from "../../../stores/companyUnitStore";
import 'moment/locale/ru';
import locale from 'antd/es/date-picker/locale/ru_RU';


interface IHistorySceneProps extends RouteComponentProps {
    authStore?: AuthStore
    companyUnitStore?: CompanyUnitStore
}

@inject(InjectNames.authStore, InjectNames.companyUnitStore)
@observer
export class HistoryScene extends React.Component<IHistorySceneProps> {
    public static route = {
        path: '/history',
        pathFormat: () => `/history`,
    }

    async componentDidMount() {
        await Promise.all([this.loadHistory(), this.loadAvailableDates()])
    }

    @observable eventType?: EventType = undefined
    changeEventType = async (eventType: EventType | undefined) => {
        this.eventType = eventType
        await this.loadHistory()
    }

    @observable date: Moment = moment()
    changeDate = async (date: Moment) => {
        this.date = date
        await this.loadHistory()
    }

    @observable minMaxDate?: { min: moment.Moment, max: moment.Moment }
    disabledDate = (date: Moment) => {
        if (!this.minMaxDate)
            return true
        return date < this.minMaxDate?.min || date > this.minMaxDate?.max
    }
    loadAvailableDates = async () => {
        this.minMaxDate = {
            min: moment((await coreClientInstance.schedule.getAvailableDateTimesList()).data.minDateTime),
            max: moment()
        }
    }

    loadHistory = async () => {
        try {
            this.historyLoading = true
            this.history = (await coreClientInstance.events.getEventsByDateList({
                date: dateTimeHelper.toBackendDate(this.date),
                dateTimeZone: dateTimeHelper.toBackendTimeZone(),
                branchId: this.props.companyUnitStore?.officeFilter?.branchId,
                officeId: this.props.companyUnitStore?.officeFilter?.officeId,
                eventType: this.eventType
            })).data
        } finally {
            this.historyLoading = false
        }
    }

    @observable historyLoading = false
    @observable history: BaseEventDto[] = []
    @observable officeFilter: OfficeDto | undefined

    setOfficeFilter = (value: OfficeDto | undefined) => {
        this.officeFilter = value
        this.props.companyUnitStore?.setOfficeParam(value);
        return this.loadHistory()
    }

    render() {
        const header = <React.Fragment>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <Typography.Title level={2}>История действий</Typography.Title>
            </div>
        </React.Fragment>

        const filters = <Row gutter={15}>
            <Col>
                <Form.Item label={'Дата'} labelCol={{span: 24}}>
                    <DatePicker value={this.date} onChange={value => this.changeDate(value!)}
                                allowClear={false} disabledDate={this.disabledDate} disabled={!this.minMaxDate}
                                locale={locale}/>
                </Form.Item>
            </Col>
            {!!this.props.authStore!.authorizedUser?.hasMultiplyOffices &&
                <Col span={8}>
                    <Form.Item label={'Фильтр по филиалу'} labelCol={{span: 24}}>
                        <OfficeFilter authorizedUser={this.props.authStore!.authorizedUser}
                                      onChange={this.setOfficeFilter}/>
                    </Form.Item>
                </Col>
            }
            {/*<Col span={8}>*/}
            {/*    <Form.Item label={'Тип события'} labelCol={{span: 24}}>*/}
            {/*        <EventTypeFilter value={this.eventType} onChange={value => this.changeEventType(value)}/>*/}
            {/*    </Form.Item>*/}
            {/*</Col>*/}

        </Row>

        return <React.Fragment>
            {header}
            {filters}
            <Events {...this.props} historyList={this.history} loading={this.historyLoading} showDateDivider={false}
                    eventTypeTabs={{showType: 'loaded'}}/>
        </React.Fragment>
    }
}

