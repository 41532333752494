import { observer } from "mobx-react";
import React from "react";
import { coreClientInstance } from "../../services/api/coreClient";
import { observable } from "mobx";
import { NotificationsDto, PaginationRequestDto } from "../../services/api/CoreApiClient";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table/interface";
import { SpinOnCenter } from "../Loading/SpinOnCenter";
import moment from "moment-timezone";

interface IUserNotificationsProps{
  userId: string;
}

@observer
export class UserNotifications extends React.Component<IUserNotificationsProps>{

  @observable paginationSettings: PaginationRequestDto = {
    pageNumber: 1,
    pageSize : 10
  }
  @observable notifCount : number = 0
  @observable notificationsList: NotificationsDto[] = []
  @observable loading: boolean = false;

  async componentDidMount() {
    await this.loadData();
  }

  loadData = async() => {
    try{
      this.loading = true
      const request = (await coreClientInstance.notificationProxy.getNotificationsByUserIdList({UserId: this.props.userId, "Pagination.PageNumber": this.paginationSettings.pageNumber, "Pagination.PageSize": this.paginationSettings.pageSize})).data
      this.notifCount = request.totalCount ?? 0
      this.notificationsList = request.items ?? [];
    }
    finally {
      this.loading = false
    }
  }

  setPageNumber = (input: number) => {
    this.paginationSettings.pageNumber = input;
    this.loadData()
  }

  setPageSize = (input: number) => {
    this.paginationSettings.pageSize = input;
    this.loadData()
  }

  render() {

    const columns: ColumnsType<NotificationsDto> = [
      {
        title: "Дата получения уведомления",
        dataIndex: "dateTime",
        render: (_, record) => <div>{moment(record.dateTime).format('DD.MM.YY, HH:mm')}</div>
      },
      {
        title: "Заголовок",
        dataIndex: "title"
      },
      {
        title: "Сообщение",
        dataIndex: "description"
      },
      {
        title: "Просмотрено",
        dataIndex: "isViewed",
        render: (_, record) => <div>{record.isViewed ? "Да" : "Нет"}</div>
      }
    ]

    return <React.Fragment>
      {this.loading 
        ? <SpinOnCenter/> 
        : <Table columns={columns} 
            rowKey='id'
            dataSource={this.notificationsList}
            pagination={{
              total: this.notifCount,
              pageSize: this.paginationSettings.pageSize,
              current: this.paginationSettings.pageNumber,
              onChange: (e) => this.setPageNumber(e),
              onShowSizeChange: (_, size) => this.setPageSize(size),
              locale: {items_per_page: 'записей на страницу'}
            }}
          />
      }
    </React.Fragment>
  }
}