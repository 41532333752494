import { Button, Col, Modal, Row } from 'antd';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { SessionCategory } from '../../../../services/api/CoreApiClient';
import { coreClientInstance } from '../../../../services/api/coreClient';

interface IResetExamProps{
    visible: boolean;
    sessionId: string;
    studentId: string;
    entryId?: string;
    onCanceled: () => any;
    onConfirmed: () => any;
}

@observer
export default class SessionResetExamModal extends React.Component<IResetExamProps> {

    @observable load: boolean = false;

    handleConfirm = async() => {
        try {
            this.load = true;
            if (this.props.entryId) {
                await coreClientInstance.schedule.resetExamEntryCreate({
                    entryId: this.props.entryId,
                    examId: this.props.sessionId
                }, {
                    studentId: this.props.studentId
                })
            }
            else (
                await coreClientInstance.schedule.resetSessionRequestCreate({
                    sessionId: this.props.sessionId,
                    category: SessionCategory.Exam
                }, {
                    studentId: this.props.studentId
                })
            )
        }
        finally {
            this.load = false
            this.props.onConfirmed();
        }
    }

    render() {
        console.log(this.props.entryId)
        return (
            <Modal visible={this.props.visible} title={'Отменить запись студента'} footer={false}
                    onCancel={this.props.onCanceled} closeIcon={<span/>}>
                <Row>Вы действительно хотите отменить запись ученика на экзамен?</Row>
                <Row justify={'end'} gutter={15} style={{marginTop:'15px'}}>
                    <Col><Button htmlType={'button'} onClick={this.props.onCanceled}>Отменить</Button></Col>
                    <Col><Button htmlType={'submit'} type={'primary'} loading={this.load} onClick={() => this.handleConfirm()}>Подтвердить</Button></Col>
                </Row>
            </Modal>
        )
    }
}