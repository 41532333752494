import {observer} from "mobx-react";
import React from "react";
import {StudentDetailsScene} from "../../scenes/authorized/Students/StudentDetailsScene";
import {computed} from "mobx";
import {Avatar, Card, Col, Row, Typography} from "antd";
import {ImageUtils} from "../../services/utils/ImageUtils";
import {RouteComponentProps} from "react-router-dom";
import {StudentShotInfoDto} from "../../services/api/CoreApiClient";
import {AuditOutlined, PhoneOutlined} from "@ant-design/icons";


interface IStudentShotInfoCardProps extends RouteComponentProps {
    student: StudentShotInfoDto
}

@observer
export class StudentShotInfoCard extends React.Component<IStudentShotInfoCardProps, any> {

    routeToStudentDetails = () => {
        this.props.history.push(StudentDetailsScene.route.pathFormat(this.props.student!.studentUserId!))
    }

    @computed get student() {
        return this.props.student!
    }

    render() {
        return <Card className={'mouse-hover'} onClick={this.routeToStudentDetails}>
            <Row>
                <Col style={{marginRight: 12}} ><Avatar size={80} shape={'circle'} src={ImageUtils.UrlOrImageNotFountUrl(this.student.imageUrl)} alt={ImageUtils.ImageNotFountUrl}/></Col>
                <Col span={12}>
                    <div><Typography.Title level={5}>{`${this.student.name?.lastName} ${this.student.name?.firstName} ${this.student.name?.middleName}`}</Typography.Title></div>
                    <div>Ученик</div>
                </Col>
            </Row>
            <div style={{marginTop: 15}}>
                <div><PhoneOutlined/> +7{this.student.studentPhone}</div>
                <Row gutter={[15, 15]}>
                    <Col span={10}>
                       {/* <div><FieldNumberOutlined /> Вождений: {this.student.scheduledTalonsCount}/{this.student.purchasedTalonsCount}</div>*/}
                    </Col>
                    <Col span={14} style={{textAlign: 'right'}}>
                        <div><AuditOutlined /> {this.student.drivingCategory?.name}</div>
                    </Col>
                </Row>
            </div>
        </Card>;
    }
}
