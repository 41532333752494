import { observable } from "mobx";

export class StudentStore {

    @observable
    public studentFilteringData: IStudentParams = {
        groupNumber: null,
        instructorId: 'withoutInstructor',
        sorting: "increase",
        withoutMobileApp: undefined,
        pageSize: 12,
        pageNumber: 1,
        recordsCount: 0
    };

    public setSortingParam = (input: any) => {
        this.studentFilteringData.sorting = input;
    }

    public setGroupNumberParam = (input: any) => {
        this.studentFilteringData.groupNumber = input;
    }

    public setInstructorParam = (input: any) => {
        this.studentFilteringData.instructorId = input;
    }

    public setWithoutAppParam = (input: any) => {
        this.studentFilteringData.withoutMobileApp = input === false ? undefined : true;
    }

    public setPageNumberParam = (input: number) => {
        this.studentFilteringData.pageNumber = input
    }

    public setRecordCount = (input: number) => {
        this.studentFilteringData.recordsCount = input
    }
}

export interface IStudentParams {
    sorting: 'increase' | 'decrease';
    groupNumber: string | null;
    instructorId?: any;
    withoutMobileApp?: boolean;
    pageSize?: number;
    pageNumber?: number;
    recordsCount: number;
}