import * as React from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {inject, observer} from "mobx-react";
import AppLayoutScene from "./Layout/AppLayoutScene";
import {observable} from "mobx";
import {getAuthorizedMenuList, getAuthorizedRoutes} from "../../routing/RouteSettings";
import {AuthStore} from "../../stores/auth/authStore";
import {InjectNames} from "../../stores/initializeStores";
import {message} from "antd";
import {notificationsClient} from "../../services/api/notificationsClient";
import {MatrixChatStore} from "../../components/Chats/store/matrixChatStore";

interface IProps extends RouteComponentProps {
    authStore?: AuthStore
    chatStore?: MatrixChatStore
}

@inject(InjectNames.authStore, InjectNames.chatStore)
@observer
export default class AuthorizedContainer extends React.Component<IProps> {
    async componentDidMount() {
        try {
            await this.props.authStore!.loadAuthorizedUser()
            this.loading = false
        } catch (e) {
            console.error(e)
            message.error('Не получилось загрузить информацию о текущем пользователе.')
            setTimeout(() => this.props.authStore!.signOut(), 3000)
        }
        await this.props.chatStore!.connect()
    }

    async componentWillUnmount() {
        if (!!this.currentToken)
            await notificationsClient.notification.logOutFirebaseTokenCreate({token: this.currentToken})
        await this.props.chatStore!.disconnect()
    }

    private currentToken?: string;
    @observable loading = true;

    render() {
        const allowedMenuItems = getAuthorizedMenuList()
        const allowedRoutes = getAuthorizedRoutes()
        return (
            <AppLayoutScene {...this.props}
                            loading={this.loading}
                            menuItems={allowedMenuItems}
                            routes={allowedRoutes}/>
        );
    }
}
