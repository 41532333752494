import React from "react";
import {BaseEventDto, EventType} from "../../services/api/CoreApiClient";
import {RouteComponentProps} from "react-router-dom";
import {observer} from "mobx-react";
import {computed} from "mobx";
import {Divider, List, Tabs} from "antd";
import {EventItem, IEventItemOptions} from "./EventItem";
import {dateTimeHelper} from "../../services/utils/DateTimeHelper";
import {EnumFriendlyMapper} from "../../services/utils/EnumFriendlyMapper";

export interface IEventsProps extends RouteComponentProps {
    historyList?: BaseEventDto[] | null
    loading: boolean
    eventItemOptions?: IEventItemOptions
    showDateDivider: boolean,
    eventTypeTabs?: {
        showType: 'loaded' | 'enum',
        hideAllTab?: boolean,
        changeEventType?: (eventType: EventType | undefined) => any
    }
}

@observer
export class Events extends React.Component<IEventsProps, any> {
    private allEventsTabKey = 'all'

    @computed get dataSource() {
        if (!!this.props.historyList?.length)
            return this.props.historyList.filter(event => EventItem.isSupported(event.type!))
        else
            return []
    }

    @computed get eventTypes() {
        let result: EventType[] = []
        if (this.props.eventTypeTabs?.showType === 'loaded')
            result = this.dataSource.reduce((eventTypesInHistory, history) => {
                if (!eventTypesInHistory.some(addedType => addedType === history.type)) {
                    eventTypesInHistory.push(history.type!)
                }
                return eventTypesInHistory;
            }, [] as EventType[])
        else if (this.props.eventTypeTabs?.showType === 'enum')
            result = Object.getOwnPropertyNames(EventType).filter(eventType => EventItem.isSupported(eventType as EventType)) as EventType[]

        return result.sort((a, b) => EnumFriendlyMapper.mapEventTypeEnum(a).localeCompare(EnumFriendlyMapper.mapEventTypeEnum(b)))
    }

    changeEventType = (activeKey: string) => {
        if (this.props.eventTypeTabs?.changeEventType) {
            const eventType = activeKey === this.allEventsTabKey ? undefined : activeKey as EventType;
            this.props.eventTypeTabs.changeEventType(eventType);
        }

    }

    render() {
        const renderItem = (history: BaseEventDto) => {
            // Из-за пагинации нужно искать предыдущий элемент по индексу
            const prevHistory = this.dataSource[this.dataSource.findIndex(item => item.id === history.id) - 1]
            const showDateDivider = this.props.showDateDivider
                && (!prevHistory
                    || (!!prevHistory && dateTimeHelper.isDifferentDatesFromString(history.initializedDateTime!, prevHistory.initializedDateTime!)))

            return <List.Item key={history.id!}>
                {
                    showDateDivider &&
                    <Divider plain>{dateTimeHelper.toDateFormatFromString(history.initializedDateTime!)}</Divider>
                }
                <EventItem {...this.props} options={this.props.eventItemOptions} historyItem={history}/>
            </List.Item>
        }

        const renderEvents = (dataSource: BaseEventDto[]) => <List
            dataSource={dataSource}
            grid={{gutter: 15, column: 1}}
            loading={this.props.loading}
            pagination={{
                hideOnSinglePage: true,
                defaultPageSize: 10,
                showSizeChanger: false,
                position: "both"
            }}
            renderItem={renderItem}
        />

        if (this.props.eventTypeTabs)
            return <Tabs type={'card'} onChange={this.changeEventType}>
                {!this.props.eventTypeTabs.hideAllTab &&
                    <Tabs.TabPane key={this.allEventsTabKey} tabKey={this.allEventsTabKey}
                                  tab={'Все'}>
                        {renderEvents(this.dataSource)}
                    </Tabs.TabPane>
                }

                {this.eventTypes?.map(eventType => (
                    <Tabs.TabPane key={eventType} tabKey={eventType}
                                  tab={EnumFriendlyMapper.mapEventTypeEnum(eventType as EventType)}>
                        {renderEvents(this.dataSource.filter(history => history.type === eventType))}
                    </Tabs.TabPane>
                ))}
            </Tabs>
        else
            return renderEvents(this.dataSource)
    }
}
