import React from "react";
import "./App.less";
import {inject, observer} from "mobx-react";
import {RouteComponentProps, withRouter} from "react-router";
import AuthorizedContainer from "./scenes/authorized/AuthorizedContainer";
import { AuthStore } from "./stores/auth/authStore";
import {InjectNames} from "./stores/initializeStores";
import UnauthorizedContainer from "./scenes/unauthorized/UnauthorizedContainer";

interface IAppProps extends RouteComponentProps {
    authStore?: AuthStore
}

@inject(InjectNames.authStore)
@observer
class App extends React.Component<IAppProps> {
    componentDidMount() {

    }

    render() {
        if (this.props.authStore!.isAuthorized) {
            return <AuthorizedContainer {...this.props}/>
        } else {
            return <UnauthorizedContainer {...this.props}/>
        }
    }
}

export default withRouter(App);
