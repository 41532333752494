import {computed, observable, reaction} from "mobx";
import {RootStore} from "./initializeStores";
import {IReactionDisposer} from "mobx/lib/internal";
import {BranchDto, OfficeDto} from "../services/api/CoreApiClient";

export class CompanyUnitStore {
    constructor(root: RootStore) {
        this.root = root
        // @ts-ignore
        this.authorizedUserReaction = reaction(() => this.root.authStore.authorizedUser, () => {
            this.setDefaultBranch()
            this.setDefaultOffice()
        })
    }

    private readonly root: RootStore
    private readonly authorizedUserReaction: IReactionDisposer

    // TODO make private
    @observable
    public unitFilteringData: ICompanyUnit = {
        branchFilterParam: undefined,
        officeFilterParam: undefined,
    };

    @computed get branchFilter() {
        return this.unitFilteringData.branchFilterParam
    }

    private setDefaultBranch = () => {
        if (this.root.authStore.authorizedUser?.hasMultiplyBranches)
            this.unitFilteringData.branchFilterParam = this!.sortedBranches[0]
    }

    public setBranchParam = (input: any) => {
        this.unitFilteringData.branchFilterParam = input;
    }

    @computed get sortedBranches() {
        const branches = !!this.root.authStore.authorizedUser?.hasMultiplyBranches
            ?
            this.root.authStore.authorizedUser?.multiplyBranches
                ?.slice()
                ?.sort((a, b) => a.branchName!.localeCompare(b.branchName!))
                ?.sort((a, b) => a.companyName!.localeCompare(b.companyName!))
            : []

        return branches ?? []
    }

    @computed get officeFilter() {
        return this.unitFilteringData.officeFilterParam
    }

    private setDefaultOffice = () => {
        if (this.root.authStore.authorizedUser?.hasMultiplyOffices)
            this.unitFilteringData.officeFilterParam = this!.sortedOffices[0]
    }

    public setOfficeParam = (input: any) => {
        this.unitFilteringData.officeFilterParam = input;
    }

    @computed get sortedOffices() {
        const offices = !!this.root.authStore.authorizedUser?.hasMultiplyOffices
            ?
            this.root.authStore.authorizedUser?.multiplyOffices
                ?.slice()
                ?.sort((a, b) => a.officeName!.localeCompare(b.officeName!))
                ?.sort((a, b) => a.branchName!.localeCompare(b.branchName!))
                ?.sort((a, b) => a.companyName!.localeCompare(b.companyName!))
            : []

        return offices ?? []
    }
}

export interface ICompanyUnit {
    branchFilterParam?: BranchDto;
    officeFilterParam?: OfficeDto;
}
