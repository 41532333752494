import React from "react";
import {RouteComponentProps} from "react-router-dom";
import {coreClientInstance} from "../../../services/api/coreClient";
import {Avatar, Button, Card, Col, Image, Row, Spin, Tabs, Typography} from "antd";
import {BaseEventDto, DrivingSessionDetailsDto, SessionCategory, TalonStatus} from "../../../services/api/CoreApiClient";
import {observer} from "mobx-react";
import {computed, observable} from "mobx";
import {
    DrivingCancellationModal,
    IDrivingCancellationTarget
} from "../../../components/DrivingSession/DrivingCancellationModal";
import {StudentDetailsScene} from "../Students/StudentDetailsScene";
import {ImageUtils} from "../../../services/utils/ImageUtils";
import {InstructorDetailsScene} from "../Instructors/InstructorDetailsScene";
import {ArrowLeftOutlined, CarOutlined, FieldNumberOutlined, PhoneOutlined} from "@ant-design/icons";
import {SessionsScene} from "./SessionsScene";
import {dateTimeHelper} from "../../../services/utils/DateTimeHelper";
import {Events} from "../../../components/History/Events";
import {SpinOnCenter} from "../../../components/Loading/SpinOnCenter";

interface IRouteProps {
    sessionId: string
}

interface IProps extends RouteComponentProps<IRouteProps> {

}

@observer
export class DrivingSessionDetailsScene extends React.Component<IProps> {

    public static route = {
        path: '/driving-details/:sessionId',
        pathFormat: (sessionId: string) => `/driving-details/${sessionId}`,
    }

    async componentDidMount() {
        this.loading = true
        await this.loadDriving()
        await this.loadHistory()
    }

    @observable loading = true
    @observable loadingHistory = true
    @observable talon: DrivingSessionDetailsDto | null = null
    @observable talonEvents: BaseEventDto[] = []

    private loadDriving = async () => {
        this.loading = true
        try {
            this.talon = (await coreClientInstance.schedule.getDrivingSessionByIdList({id: this.props.match.params.sessionId})).data
        } finally {
            this.loading = false
        }

    }

    private loadHistory = async () => {
        this.loadingHistory = true
        try {
            this.talonEvents = (await coreClientInstance.events.getEventsBySessionIdList({id: this.props.match.params.sessionId})).data
        } finally {
            this.loadingHistory = false
        }

    }

    confirmDrivingTalon = async () => {
        this.loadingHistory = true
        this.talon!.talonStatus = TalonStatus.Assigned;
        await coreClientInstance.schedule.confirmSessionRequestCreate({
            category: SessionCategory.Practice,
            optLockDateTime: this.talon?.optLockDateTime,
            sessionId: this.props.match.params.sessionId,
            concurrencyId: this.talon?.concurrencyId});
        await new Promise(resolve => setTimeout(resolve, 3000))
        this.loadHistory();
    }
    routeToStudentDetails = () => this.props.history.push(StudentDetailsScene.route.pathFormat(this.talon!.student?.studentUserId!))
    routeToInstructorDetails = () => this.props.history.push(InstructorDetailsScene.route.pathFormat(this.talon!.instructor?.instructorUserId!))

    private mapDetails = (talon: DrivingSessionDetailsDto | null) => {
        if (!talon)
            return <React.Fragment/>

        const student = this.talon?.student;
        const instructor = this.talon?.instructor;

        return <React.Fragment>
            <Row gutter={[30, 30]}>
                <Col span={24} style={{fontWeight: 'bold'}}>
                    <Row gutter={[15, 15]}>
                        <Col span={12}><Typography.Title level={4}>{`Вождение`}</Typography.Title></Col>
                        <Col span={12} style={{textAlign: 'end'}}>
                            {talon.talonStatus !== TalonStatus.WaitApprove ?
                             null
                            : <Button onClick={this.confirmDrivingTalon} type={'primary'}>Подтвердить вождение
                            </Button>}
                            <Button style={{marginLeft: '5px'}} onClick={this.showDrivingCancellation}
                                    disabled={talon.talonStatus === TalonStatus.Canceled}>Отменить
                            </Button>
                        </Col>
                        {!!talon.dateTime?.length &&
                            <Col span={24}><Image
                                src={'/time.svg'}/> {`Дата занятия: ${dateTimeHelper.toDateAndTimeFormatFromString(talon.dateTime!)}`}
                            </Col>}
                        {!!talon.place?.address?.length &&
                            <Col span={24}><Image src={'/place.svg'}/> {talon.place.address}</Col>}
                    </Row>
                </Col>
                <Col span={24}>
                    <Row gutter={[30, 30]}>
                        <Col span={12}>
                            <Card className={'mouse-hover'} onClick={this.routeToStudentDetails}>
                                <Row>
                                    <Col style={{marginRight: 12}}><Avatar size={80} shape={'circle'}
                                                                           src={ImageUtils.UrlOrImageNotFountUrl(student?.studentImageUrl)}
                                                                           alt={ImageUtils.ImageNotFountUrl}/></Col>
                                    <Col span={12}>
                                        <div><Typography.Title
                                            level={5}>{student ? `${student?.name?.lastName} ${student?.name?.firstName} ${student?.name?.middleName}` : "Не определен"}</Typography.Title>
                                        </div>
                                        <div>{student? "Ученик" : "Частное лицо"}</div>
                                    </Col>
                                </Row>
                                <div style={{marginTop: 15}}>
                                    <div><PhoneOutlined/>{student? '+7' + student?.studentPhone : ' Не указан'}</div>
                                    <div>
                                        <FieldNumberOutlined/> Вождений: {student? student?.studentScheduledTalons : 0}/{student? student?.studentPurchasedTalonsCount : 0}
                                    </div>
                                </div>
                            </Card>
                        </Col>
                        <Col span={12}>
                            <Card className={'mouse-hover'} onClick={this.routeToInstructorDetails}>
                                <Row>
                                    <Col style={{marginRight: 12}}><Avatar size={80} shape={'circle'}
                                                                           src={ImageUtils.UrlOrImageNotFountUrl(instructor?.instructorImageUrl)}
                                                                           alt={ImageUtils.ImageNotFountUrl}/></Col>
                                    <Col span={12}>
                                        <div><Typography.Title
                                            level={5}>{`${instructor?.name?.lastName} ${instructor?.name?.firstName} ${instructor?.name?.lastName}`}</Typography.Title>
                                        </div>
                                        <div>Инструктор</div>
                                    </Col>
                                </Row>
                                <div style={{marginTop: 15}}>
                                    <div><PhoneOutlined/> +7{instructor?.instructorPhone}</div>
                                    <div><CarOutlined/> {instructor?.instructorCar?.model}</div>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </React.Fragment>
    }
    showDrivingCancellation = () => {
        this.drivingCancellationVisible = true
    }
    hideDrivingCancellationAndReload = async () => {
        this.drivingCancellationVisible = false
        this.talon!.talonStatus = TalonStatus.Canceled;
        this.loadingHistory = true;
        await new Promise(resolve => setTimeout(resolve, 3000))
        return this.loadHistory()
    }
    hideDrivingCancellation = () => {
        this.drivingCancellationVisible = false
    }
    @observable drivingCancellationVisible = false

    @computed get drivingCancellationTarget() {
        if (!this.talon)
            return null

        return {...this.talon} as IDrivingCancellationTarget
    }

    render() {

        const details = this.mapDetails(this.talon)

        const history = <Events {...this.props} historyList={this.talonEvents} showDateDivider={true}
                                eventItemOptions={{
                                    drivingOptions: {
                                        hideDrivingDetails: true,
                                        hideStudentDetails: true,
                                        hideInstructorDetails: true
                                    }
                                }}
                                loading={false}/>

        const backButton = <Row style={{marginBottom: 30}}>
            <Button type={'text'}
                    icon={<ArrowLeftOutlined/>}
                    onClick={_ => this.props.history.push(SessionsScene.route.pathFormat())}>ЗАНЯТИЯ</Button>
        </Row>

        return <React.Fragment>

            {this.drivingCancellationTarget && <DrivingCancellationModal visible={this.drivingCancellationVisible}
                                                                         closeModal={this.hideDrivingCancellation}
                                                                         onDrivingCanceled={this.hideDrivingCancellationAndReload}
                                                                         driving={this.drivingCancellationTarget}/>
            }

            {backButton}
            {this.loading
                ?
                <SpinOnCenter/>
                :
                <Row gutter={[30, 30]}>
                    <Col span={24}>{details}</Col>
                    {this.loadingHistory
                        ?<Col span={24} style={{marginTop: '2%'}}>
                            <Row justify={'center'} >
                                <Spin size={'large'}/>
                            </Row>
                        </Col>
                        :<Col span={24}>
                            <Tabs type={'card'}>
                                <Tabs.TabPane key={'history'} tab={'История'}>
                                    {history}
                                </Tabs.TabPane>
                            </Tabs>
                        </Col>
                    }
                </Row>}

        </React.Fragment>
    }
}
