import React from "react";
import {inject, observer} from "mobx-react";
import {InjectNames} from "../../../stores/initializeStores";
import {Col, Row} from "antd";
import {RoomsGroupedByTags} from "../../../components/Chats/RoomsGroupedByTags";
import {ActiveRoom} from "../../../components/Chats/ActiveRoom";
import Search from "antd/es/input/Search";
import {MatrixChatStore} from "../../../components/Chats/store/matrixChatStore";
import {SpinOnCenter} from "../../../components/Loading/SpinOnCenter";


interface IChatSceneProps {
    /** Should be connected/disconnected in AuthorizedContainer */
    chatStore: MatrixChatStore
}

@inject(InjectNames.chatStore)
@observer
export class ChatScene extends React.Component<IChatSceneProps> {
    public static route = {
        path: '/chats'
    }

    async componentWillUnmount() {
        await this.props.chatStore.roomList?.selectRoom(undefined)
    }

    render() {
        const {chatStore} = this.props
        return <>
            <Row style={{height: '100%'}} gutter={15}>
                <Col xs={12} xxl={6} style={{height: '100%'}}>
                    {chatStore.loading
                        ? <SpinOnCenter/>
                        : <>
                            <div style={{height: '5%'}}><Search placeholder={'Поиск'} allowClear={true}
                                                                onSearch={chatStore.roomList?.search}/></div>
                            <div style={{height: '95%'}}><RoomsGroupedByTags chatStore={chatStore}/></div>
                        </>
                    }
                </Col>
                <Col xs={12} xxl={18} style={{height: '100%'}}>
                    <ActiveRoom key={chatStore.roomList?.activeRoom?.id}
                                room={chatStore.roomList?.activeRoom}/>
                </Col>
            </Row>
        </>;
    }
}
