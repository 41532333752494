import React, {CSSProperties} from "react";
import {Calendar, EventPropGetter, momentLocalizer, View} from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css"
import {computed, observable} from "mobx";
import moment from "moment-timezone";
import {DateTimeStatus, DrivingTalonType, InstructorCalendarDto, SessionCategory} from "../../services/api/CoreApiClient";
import {coreClientInstance} from "../../services/api/coreClient";
import {observer} from "mobx-react";
import {RouteComponentProps} from "react-router-dom";
import {DrivingSessionDetailsScene} from "../../scenes/authorized/Sessions/DrivingSessionDetailsScene";
import {
    DrivingCreationByInstructorWorkTimeComponent
} from "../../scenes/authorized/Instructors/components/DrivingCreationByInstructorWorkTimeComponent";
import {SpinOnCenter} from "../Loading/SpinOnCenter";
import {
    AddWorkTimeExclusionButtonComponent
} from "../../scenes/authorized/Instructors/components/AddWorkTimeExclusionButtonComponent";
import {EnumFriendlyMapper} from "../../services/utils/EnumFriendlyMapper";
import {dateTimeHelper} from "../../services/utils/DateTimeHelper";

const calendarLocalizer = momentLocalizer(moment)

interface InstructorScheduleProps extends RouteComponentProps {
    instructorUserId: string
}


type ItemType = 'freeTime' | 'busyTime' | 'exclusion' | 'burnedTime'

interface BookingEvent {
    id: string;
    type: ItemType;
    title: string;
    start: Date;
    end: Date;
    resourceId: string;
    studentUserIds: string[];
    categoryId: string | undefined;
    canRouteToSessionDetails: boolean;
    isPastTime: boolean
}

@observer
export class InstructorSchedule extends React.Component<InstructorScheduleProps> {

    async componentDidMount() {
        await this.load()
    }

    load = async () => {
        try {
            this.calendarLoading = true
            this.setDateRangeToState()
            this.calendar = (await coreClientInstance.schedule.getInstructorCalendarList({
                InstructorUserId: this.props.instructorUserId,
                UseTimeZone: dateTimeHelper.toBackendTimeZone(),
                FromDate: dateTimeHelper.toBackendDate(this.dateRange![0]),
                ToDate: dateTimeHelper.toBackendDate(this.dateRange![1]),
            })).data
        } finally {
            this.calendarLoading = false
        }
    }

    @computed get resource() {
        return [{resourceId: this.props.instructorUserId, resourceName: this.props.instructorUserId}]
    }

    @observable calendarLoading = false
    @observable calendar?: InstructorCalendarDto

    @computed get dataSource() {
        const now = moment()

        const driving: BookingEvent[] = this.calendar?.workTimes
            ?.filter(workTime => !!workTime.driving && workTime.driving.category === SessionCategory.Practice)
            ?.map(workTime => ({
                id: workTime.driving!.id!,
                resourceId: this.props.instructorUserId,
                start: dateTimeHelper.fromBackendDateTime(workTime.start!)!.toDate()!,
                end: dateTimeHelper.fromBackendDateTime(workTime.end!)!.toDate(),
                title: workTime.driving && workTime.driving.students.length > 0 ?  `${workTime.driving?.students[0]!.student.lastName} ${workTime.driving?.students![0]!.student.firstName} ${workTime.driving?.students![0]!.student.middleName} `
                    + `${workTime.driving?.status === DateTimeStatus.Canceled ? "Талон сгорел - " : ""} \n`
                    + `${workTime.driving.talonType === DrivingTalonType.Extra ? "Дополнительный талон" : ""} \n`
                    + workTime.drivingCategory?.name : "Частное лицо",
                type: workTime.driving?.status === DateTimeStatus.Canceled ? "burnedTime" : "busyTime",
                isPastTime: moment(workTime.start).diff(now) < 0,
                studentUserIds: workTime.driving!.students!.map(x => x.studentUserId!),
                categoryId: workTime.drivingCategory?.id,
                canRouteToSessionDetails: true
            })) ?? [] as BookingEvent[]

        const exams: BookingEvent[] = this.calendar?.workTimes
            ?.filter(workTime => !!workTime.driving && (workTime.driving.category === SessionCategory.Offset || workTime.driving.category === SessionCategory.Exam))
            ?.map(workTime => ({
                id: workTime.driving!.id!,
                resourceId: this.props.instructorUserId,
                start: dateTimeHelper.fromBackendDateTime(workTime.start!)!.toDate()!,
                end: dateTimeHelper.fromBackendDateTime(workTime.end!)!.toDate(),
                title: workTime.driving?.sessionName ? workTime.driving?.sessionName : "",
                type: "busyTime",
                isPastTime: moment(workTime.start).diff(now) < 0,
                studentUserIds: workTime.driving!.students!.map(x => x.studentUserId!),
                categoryId: workTime.drivingCategory?.id,
                canRouteToSessionDetails: false
            })) ?? [] as BookingEvent[]

        const exclusions: BookingEvent[] = this.calendar?.exclusions
            ?.map(exclusion => ({
                id: exclusion.id!,
                resourceId: this.props.instructorUserId,
                start: dateTimeHelper.fromBackendDateTime(exclusion.start!)!.toDate(),
                end: dateTimeHelper.fromBackendDateTime(exclusion.end!)!.toDate(),
                title: EnumFriendlyMapper.mapExclusionReason(exclusion.reason!),
                type: "exclusion",
                isPastTime: moment(exclusion.start).diff(now) < 0,
                studentUserIds: [],
                categoryId: undefined,
                canRouteToSessionDetails: false
            })) ?? [] as BookingEvent[]

        const workTimes: BookingEvent[] = this.calendar?.workTimes
            ?.filter(workTime => !workTime.driving)
            ?.map(workTime => ({
                id: workTime.id!,
                resourceId: this.props.instructorUserId,
                start: dateTimeHelper.fromBackendDateTime(workTime.start!)!.toDate(),
                end: dateTimeHelper.fromBackendDateTime(workTime.end!)!.toDate(),
                title: '+ ' + workTime.drivingCategory?.name,
                type: "freeTime",
                isPastTime: moment(workTime.start).diff(now) < 0,
                studentUserIds: [],
                categoryId: workTime.drivingCategory?.id,
                canRouteToSessionDetails: false
            })) ?? []

        return driving.concat(exams).concat(workTimes).concat(exclusions)
    }

    @computed get startTime() {
        const min = this.dataSource
            .map(event => moment(event.start))
            .reduce((previous, current) => current.hours() < previous.hours() ? current : previous,
                moment())
        if (min.creationData().input)
            return new Date(min.creationData().input!.toString())
        else
            return min.startOf('day').toDate();
    }

    @computed get endTime() {
        const max = this.dataSource
            .map(event => moment(event.end))
            .reduce((previous, current) => (current.hours() === 0 ? current.subtract(1, 'seconds').hours() : current.hours()) > previous.hours() ? current : previous,
                moment().startOf('day'))
        return max.toDate()
    }

    eventPropGetter: EventPropGetter<BookingEvent> = (event, start, end, isSelected) => {
        const commonStyle: CSSProperties = {}

        switch (event.type) {
            case 'freeTime':
                return {
                    style: {
                        ...commonStyle,
                        backgroundColor: event.isPastTime ? "rgba(255,255,255)" : "rgb(129,129,129)",
                        cursor: (event.isPastTime || !event.canRouteToSessionDetails) ? 'default' : 'pointer',
                        color: event.isPastTime ? 'rgb(153, 151, 150)' : undefined,
                        borderColor: event.isPastTime ? "rgb(153, 151, 150)" : "rgb(255,255,255)",
                    } as CSSProperties
                }
            case 'busyTime':
                return {
                    style: {
                        ...commonStyle,
                        cursor: !event.canRouteToSessionDetails ? 'default' : undefined,
                        backgroundColor: "rgb(164,164,164)",
                        borderColor: "rgb(255,255,255)",
                    } as CSSProperties
                }
            case 'exclusion':
                return {
                    style: {
                        ...commonStyle,
                        cursor: 'default',
                        backgroundColor: "rgb(105,17,17)",
                        borderColor: "rgb(255,255,255)"
                    } as CSSProperties
                }
            case 'burnedTime':
                return {
                    style: {
                        ...commonStyle,
                        cursor: 'default',
                        color: 'rgb(153, 151, 150)',
                        backgroundColor: "#fff",
                        borderColor: "rgb(153, 151, 150)"
                    } as CSSProperties
                }
        }
    }

    onSelectEvent: ((event: BookingEvent, e: React.SyntheticEvent<HTMLElement>) => void) = async (event) => {
        if (event.type === 'freeTime' && !event.isPastTime) {
            await this.showDrivingCreationModal(event)
        } else if (event.type === 'busyTime' && event.canRouteToSessionDetails) {
            this.props.history.push(DrivingSessionDetailsScene.route.pathFormat(event.id))
        }
    }

    @observable selectedEvent: BookingEvent | undefined = undefined
    showDrivingCreationModal = async (event: BookingEvent) => {
        this.selectedEvent = event
    }
    hideDrivingCreationModal = () => {
        this.selectedEvent = undefined
    }
    onDrivingCreated = async () => {
        this.hideDrivingCreationModal()
        await this.load()
    }

    onExclusionCreated = async () => {
        await this.load()
    }

    @observable view: View = 'week'
    onView: ((view: View) => void) = async view => {
        this.view = view
        await this.load()
    }


    // @ts-ignore
    dateRange: [moment.Moment, moment.Moment]
    // defaultCalendarDate = this.dateRangeDefault[0].toDate()
    // @ts-ignore
    calendarDate: Date = moment().toDate()

    changeCalendarDate = async (newDate: Date) => {
        this.calendarDate = newDate
        await this.load()
    }
    setDateRangeToState = () => {
        /* if view is day: from moment(date).startOf('day') to moment(date).endOf('day');
        if view is week: from moment(date).startOf('isoWeek') to moment(date).endOf('isoWeek');
        if view is month: from moment(date).startOf('month').subtract(7, 'days') to moment(date).endOf('month').add(7, 'days'); i do additional 7 days math because you can see adjacent weeks on month view (that is the way how i generate my recurrent events for the Big Calendar, but if you need only start-end of month - just remove that math);
        if view is agenda: from moment(date).startOf('day') to moment(date).endOf('day').add(1, 'month'); */
        switch (this.view) {
            case "month":
                this.dateRange = [moment(this.calendarDate).startOf('month').subtract(7, 'days'), moment(this.calendarDate).endOf('month').add(7, 'days')]
                break;
            case "week":
            case "work_week":
                this.dateRange = [moment(this.calendarDate).startOf('week'), moment(this.calendarDate).endOf('week')]
                break;
            case "day":
                this.dateRange = [moment(this.calendarDate).startOf('day'), moment(this.calendarDate).endOf('day')]
                break;
            case "agenda":
                this.dateRange = [moment(this.calendarDate).startOf('day'), moment(this.calendarDate).endOf('day').add(1, 'month')]
                break;
        }
    }
    render() {
        return <React.Fragment>
            {!!this.selectedEvent &&
                <DrivingCreationByInstructorWorkTimeComponent
                    visible={true}
                    instructorUserId={this.props.instructorUserId} start={moment(this.selectedEvent.start)}
                    categoryId={this.selectedEvent.categoryId!}
                    onCanceled={this.hideDrivingCreationModal} onCreated={this.onDrivingCreated}/>
            }
            <div style={{marginBottom: 15}}>
                <AddWorkTimeExclusionButtonComponent
                    instructorUserId={this.props.instructorUserId} onCreated={this.onExclusionCreated}/>
            </div>

            <div>
                {this.calendarLoading
                    ? <SpinOnCenter/>
                    : <Calendar
                        style={{height: '100%', width: '100%'}}
                        events={this.dataSource}
                        views={{
                            week: true,
                            day: true
                        }}

                        resources={this.resource}
                        resourceIdAccessor={resource => resource.resourceId}
                        resourceTitleAccessor={resource => resource.resourceName}
                        view={this.view}
                        onView={this.onView}
                        date={this.calendarDate}
                        onNavigate={this.changeCalendarDate}
                        defaultDate={this.calendarDate}
                        showMultiDayTimes
                        eventPropGetter={this.eventPropGetter}
                        onSelectEvent={this.onSelectEvent}
                        localizer={calendarLocalizer}
                        min={this.startTime}
                        max={this.endTime}
                        formats={{
                            eventTimeRangeFormat: (range, culture, localizer) => moment(range.start).format("HH:mm")
                        }}
                        messages={{
                            week: 'Неделя',
                            day: 'День',
                            today: 'Сегодня',
                            previous: 'Назад',
                            next: 'Вперед'
                        }}
                    />
                }
            </div>
        </React.Fragment>
    }
}
