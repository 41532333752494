import {AuthStore} from "./auth/authStore";
import {coreClientInstance} from "../services/api/coreClient";
import {MatrixChatStore} from "../components/Chats/store/matrixChatStore";
import { CompanyUnitStore } from "./companyUnitStore";
import { StudentStore } from "./studentStore";

export interface IDisposable {
    dispose: () => Promise<void>;
}

export class RootStore {
    constructor() {
        this.initStores()
    }

    authStore!: AuthStore
    chatStore!: MatrixChatStore
    companyUnitStore!: CompanyUnitStore
    studentStore!: StudentStore

    private initStores = () => {
        this.authStore = new AuthStore(this, coreClientInstance)
        this.chatStore = new MatrixChatStore()
        this.companyUnitStore = new CompanyUnitStore(this)
        this.studentStore = new StudentStore()
    }

}

const rootStore = new RootStore();

export const InitializedStores = {
    rootStore: rootStore,
    authStore: rootStore.authStore,
    chatStore: rootStore.chatStore,
    companyUnitStore: rootStore.companyUnitStore,
    studentStore: rootStore.studentStore
}


export const InjectNames = {
    authStore: "authStore",
    chatStore: "chatStore",
    companyUnitStore: "companyUnitStore",
    studentStore: "studentStore"
}

