import React from "react";
import {coreClientInstance} from "../../../services/api/coreClient";
import {Checkbox, Col, Form, Input, List, Row, Select, Typography} from "antd";
import {
    GroupDto,
    InstructorSimpleDto,
    OfficeDto,
    StudentDetailsDto,
    StudentsFilter
} from "../../../services/api/CoreApiClient";
import {inject, observer} from "mobx-react";
import {computed, observable} from "mobx";
import axios, {CancelTokenSource} from "axios";
import {RouteComponentProps} from "react-router-dom";
import {StudentDetailsScene} from "./StudentDetailsScene";
import {StudentDetailsCard} from "../../../components/Student/StudentDetailsCard";
import {OfficeFilter} from "../../../components/CompanyUnit/OfficeFilter";
import {AuthStore} from "../../../stores/auth/authStore";
import {InjectNames} from "../../../stores/initializeStores";
import { StudentStore } from "../../../stores/studentStore";
import { CompanyUnitStore } from "../../../stores/companyUnitStore";

interface IStudentsSceneProps extends RouteComponentProps {
    authStore?: AuthStore
    studentStore?: StudentStore
    companyUnitStore?: CompanyUnitStore
}

@inject(InjectNames.authStore, InjectNames.studentStore, InjectNames.companyUnitStore)
@observer
export class StudentsScene extends React.Component<IStudentsSceneProps> {
    public static route = {
        path: '/students',
        pathFormat: () => StudentsScene.route.path,
    }

    async componentDidMount() {
        await Promise.all([this.loadGroupsFilters(), this.loadInstructorsFilter(), this.loadStudents()])
    }

    private source: CancelTokenSource | null = null

    loadStudents = async () => {
        try {
            this.studentsLoading = true

            if (!!this.source)
                this.source!.cancel("re-loading")

            //const data = (await coreClientInstance.student.getStudentsByFilterCreate(this.filter, {cancelToken: this.source?.token})).data

            this.source = axios.CancelToken.source()
            //this.students = data.studentDetails!
            this.students = (await coreClientInstance.student.getStudentsByFilterCreate(this.filter, {cancelToken: this.source.token})).data
            this.source = null
            //this.props.studentStore?.setRecordCount(data.recordCount!)
        } finally {
            this.studentsLoading = false
        }
    }

    @computed get filter() {
        let filter: StudentsFilter = {
            groupId: !!this.props.studentStore!.studentFilteringData.groupNumber?.length ? this.props.studentStore!.studentFilteringData.groupNumber! : undefined,
            branchId: this.props.companyUnitStore?.officeFilter?.branchId,
            officeId: this.props.companyUnitStore?.officeFilter?.officeId,
            withoutMobileApp: this.props.studentStore?.studentFilteringData.withoutMobileApp,
            pageNumber: this.props.studentStore?.studentFilteringData.pageNumber,
            pageSize: 12
        }
        const selecterInstructor = this.props.studentStore?.studentFilteringData.instructorId;
        if (selecterInstructor === "withoutInstructor")
            filter.onlyWithoutInstructor = true
        else if (!!selecterInstructor?.length)
            filter.instructorUserId = selecterInstructor!
        return filter
    }


    @observable students: StudentDetailsDto[] = []
    @observable studentsLoading = false

    @computed get studentsDataSource() {
        const search = this.textSearch?.toUpperCase()?.replace(/ /g, '')

        let dataSource = !!this.students?.length ? this.students.slice() : []

        // search
        if (!!search?.length)
            dataSource = dataSource.filter(item => (`${item.name!.lastName}${item.name!.firstName}${item.name!.middleName}`.toUpperCase()).includes(search)
                || (`${item.studentUserId}`.toUpperCase()).includes(search)
                || (`${item.studentEmail}`.toUpperCase()).includes(search))

        // sort
        if (this.props.studentStore!.studentFilteringData.sorting === "increase")
            dataSource = dataSource.sort((a, b) => (`${a.name!.lastName}${a.name!.firstName}${a.name!.middleName}`)!.localeCompare(`${b.name!.lastName}${b.name!.firstName}${b.name!.middleName}`))
        else
            dataSource = dataSource.sort((a, b) => (`${b.name!.lastName}${b.name!.firstName}${b.name!.middleName}`)!.localeCompare(`${a.name!.lastName}${a.name!.firstName}${a.name!.middleName}`))

        return dataSource
    }

    changeWithoutApp = async (input: any) => {
        this.props.studentStore?.setWithoutAppParam(input)
        await Promise.all([this.loadStudents(), this.loadInstructorsFilter()])
        await this.checkInstructorAvailableAfterUpdate()
    }

    @observable groups: GroupDto[] = []
    selectGroup = async (id: string | null) => {
        this.props.studentStore?.setGroupNumberParam(id);
        await Promise.all([this.loadStudents(), this.loadInstructorsFilter()])
        await this.checkInstructorAvailableAfterUpdate()
    }
    checkGroupAvailableAfterUpdate = async () => {
        if (!!this.props.studentStore!.studentFilteringData.groupNumber) {
            const selectedGroupIsAvailable = !!this.groups.find(group => group.groupId === this.props.studentStore!.studentFilteringData.groupNumber)

            if (!selectedGroupIsAvailable)
                await this.selectGroup(null)
        }
    }
    loadGroupsFilters = async () => {
        this.groups = (await coreClientInstance.instructor.getGroupsByFilterCreate({
            instructorUserId: this.filter.instructorUserId,
            branchId: this.filter.branchId,
            officeId: this.filter.officeId,
        })).data.sort((a, b) => a.groupName!.localeCompare(b.groupName!))
    }


    @observable instructors: InstructorSimpleDto[] = []
    selectInstructorUserId = async (userId: SelectedInstructorUserIdType) => {
        this.props.studentStore?.setInstructorParam(userId);
        await Promise.all([this.loadStudents(), this.loadGroupsFilters()])
        await this.checkGroupAvailableAfterUpdate()
    }
    checkInstructorAvailableAfterUpdate = async () => {
        const selectedInstructor = this.props.studentStore?.studentFilteringData.instructorId
        if (!!selectedInstructor && selectedInstructor !== 'withoutInstructor') {
            const selectedInstrIsAvailable = !!this.instructors.find(instructor => instructor.userId === selectedInstructor)

            if (!selectedInstrIsAvailable)
                await this.selectInstructorUserId(null)
        }
    }
    loadInstructorsFilter = async () => {
        this.instructors = (await coreClientInstance.instructor.getSimpleInstructorsByFilterCreate({
            groupId: this.filter.groupId,
            branchId: this.filter.branchId
        })).data.sort((a, b) => a.name!.lastName!.localeCompare(b.name!.lastName!))
    }

    @observable textSearch: string | undefined = undefined;
    setTextSearch = async (value: string | undefined) => {
        this.textSearch = !!value?.length ? value : undefined
    }

    @observable officeFilter: OfficeDto | undefined
    setOfficeFilter = async (value: OfficeDto | undefined) => {
        this.officeFilter = value
        this.props.companyUnitStore?.setOfficeParam(value);
        await Promise.all([this.loadGroupsFilters(), this.loadInstructorsFilter(), this.loadStudents()])

        await this.checkInstructorAvailableAfterUpdate()
        await this.checkGroupAvailableAfterUpdate()
    }

    setPageNumber = async (input: number) => {
        this.props.studentStore?.setPageNumberParam(input)
        await this.loadStudents();
    }

    routeToStudentDetails = (studentUserId: string) => this.props.history.push(StudentDetailsScene.route.pathFormat(studentUserId))

    render() {

        const header = <React.Fragment>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <Typography.Title level={2}>Ученики</Typography.Title>
                <Form.Item>
                    <Input placeholder={'Поиск'} value={this.textSearch} allowClear={true}
                           onChange={event => this.setTextSearch(event.target.value)}/>
                </Form.Item>
            </div>
        </React.Fragment>

        const filters = <React.Fragment>
            {!!this.props.authStore!.authorizedUser?.hasMultiplyOffices
                && <Row gutter={15}>
                    <Col span={8}>
                        <Form.Item label={'Фильтр по филиалу'} labelCol={{span: 24}}>
                            <OfficeFilter authorizedUser={this.props.authStore!.authorizedUser} onChange={this.setOfficeFilter}/>
                        </Form.Item>
                    </Col>
                </Row>
            }

            <Row gutter={15}>
                <Col span={6}>
                    <Form.Item label={'Сортировка'} labelCol={{span: 24}}>
                        <Select value={this.props.studentStore?.studentFilteringData.sorting} onChange={value => this.props.studentStore?.setSortingParam(value)}>
                            <Select.Option value={'increase'}>А-Я</Select.Option>
                            <Select.Option value={'decrease'}>Я-А</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label={'Номер группы'} labelCol={{span: 24}}>
                        <Select value={this.props.studentStore?.studentFilteringData?.groupNumber} onChange={this.selectGroup}
                                allowClear={true}
                                showSearch={true} optionFilterProp={'children'}>
                            {this.groups.map(group =>
                                <Select.Option key={`${group.companyId}${group.branchId}${group.groupId}`}
                                               value={group.groupId}>{group.groupName}</Select.Option>)}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label={'Инструктор'} labelCol={{span: 24}}>
                        <Select value={this.props.studentStore?.studentFilteringData.instructorId} onChange={this.selectInstructorUserId}
                                allowClear={true}
                                showSearch={true} optionFilterProp={'children'}>
                            <Select.OptGroup label={'Прочее'}>
                                <Select.Option value={'withoutInstructor'}>Без инструктора</Select.Option>
                            </Select.OptGroup>
                            <Select.OptGroup label={'Инструктора'}>
                                {this.instructors.map(instructor =>
                                    <Select.Option key={instructor.userId}
                                                   value={instructor.userId}>{`${instructor.name?.lastName} ${instructor.name?.firstName} ${instructor.name?.middleName}`}</Select.Option>)}
                            </Select.OptGroup>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6} style={{marginTop:'40px'}}>
                    <Form.Item label={''} labelCol={{span: 24}}>
                        <Checkbox defaultChecked={this.props.studentStore?.studentFilteringData.withoutMobileApp} onChange={(e) => this.changeWithoutApp(e.target.checked)}>Без мобильного приложения</Checkbox>
                    </Form.Item>
                </Col>
            </Row>
        </React.Fragment>

        const cards = <List
            dataSource={this.studentsDataSource}
            grid={{gutter: 15, column: 3}}
            loading={this.studentsLoading}
            pagination={{
                //onChange: (e) => this.setPageNumber(e),
                hideOnSinglePage: true,
                //current: this.filter.pageNumber,
                //total: this.props.studentStore?.studentFilteringData.recordsCount,
                defaultPageSize: 12,
                showSizeChanger: false,
                position: "both"
            }}
            renderItem={student => <List.Item key={student.studentUserId}><StudentDetailsCard {...this.props} student={student}/></List.Item>}
        />

        return <React.Fragment>
            {header}
            {filters}
            {cards}
        </React.Fragment>
    }
}

type SelectedInstructorUserIdType = string | null | 'withoutInstructor'
