import {observer} from "mobx-react";
import React from "react";
import {Button, Form} from "antd";
import {Room} from "./store/room";
import {observable} from "mobx";
import {FormInstance} from "antd/es";
import TextArea, {TextAreaRef} from "antd/es/input/TextArea";

export interface IMessageInputProps {
    room: Room
}

@observer
export class MessageInput extends React.Component<IMessageInputProps, any> {

    componentWillUnmount() {
        this.props.room.setTyping(false).finally()
    }

    private readonly maxTextLength = 1000
    @observable sending = false
    inputRef: TextAreaRef | null = null
    formRef: FormInstance | null = null
    sendMsg = async (values: { text: string | undefined }) => {
        if (values.text) {
            try {
                this.sending = true
                await this.props.room.sendMessage(values.text)
                this.formRef?.resetFields()
            } finally {
                this.sending = false
                this.inputRef?.focus()
            }
        }
    }
    handleInput = (args: any) => {
        this.props.room.setTyping(!!args.target.value?.length).finally();
        return args.target.value
    }
    onKeyDown = (e: any) => {
        const enter = 13
        if(e.keyCode === enter && e.shiftKey === false) {
            e.preventDefault();
            this.formRef?.submit();
        }
    }

    render() {
        return <>
            <Form ref={ref => this.formRef = ref!} onFinish={this.sendMsg}>
                <div style={{display: 'flex', width: '100%'}}>
                    <Form.Item style={{width: '100%'}} name={'text'} getValueFromEvent={this.handleInput}
                               rules={[{
                                   max: this.maxTextLength,
                                   message: `Сообщение не должно превышать ${this.maxTextLength} символов.`
                               }]}>
                        <TextArea ref={ref => this.inputRef = ref!}
                                  style={{width: '100%'}}
                                  autoSize={{maxRows: 10}}
                                  autoFocus={true}
                                  autoComplete={'off'}
                                  onKeyDown={this.onKeyDown}
                                  disabled={this.sending}/>
                    </Form.Item>
                    <Button htmlType={'submit'} type={'default'} loading={this.sending}>
                        <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M1.4 17.4L18.85 9.92002C19.66 9.57002 19.66 8.43002 18.85 8.08002L1.4 0.600017C0.74 0.310017 0.00999999 0.800017 0.00999999 1.51002L0 6.12002C0 6.62002 0.37 7.05002 0.87 7.11002L15 9.00002L0.87 10.88C0.37 10.95 0 11.38 0 11.88L0.00999999 16.49C0.00999999 17.2 0.74 17.69 1.4 17.4Z"
                                fill="#CF0D14"/>
                        </svg>
                    </Button>
                </div>


            </Form>
        </>;
    }
}
