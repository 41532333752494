import { Button, Col, Modal, Row } from 'antd';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { SessionCategory } from '../../../../services/api/CoreApiClient';
import { coreClientInstance } from '../../../../services/api/coreClient';

interface IConfirmSessionProps{
    visible: boolean;
    studentId: string;
    sessionId: string;
    onCanceled: () => any;
    onConfirmed: () => any;
}

@observer
export default class SessionConfirmModal extends React.Component<IConfirmSessionProps> {

    @observable load: boolean = false;

    handleConfirm = async () => {
        try { 
            this.load = true;
            await coreClientInstance.schedule.confirmSessionRequestCreate({
                sessionId: this.props.sessionId,
                category: SessionCategory.Exam,
                optLockDateTime: ""
            }, {
                studentId: this.props.studentId
            })
        }
        finally {
            this.load = false;
            this.props.onConfirmed();
        }
    }

    render() {
        return(
            <Modal visible={this.props.visible} title={'Подтвердить присутствие ученика'} footer={false}
                    onCancel={this.props.onCanceled} closeIcon={<span/>}>
                <Row>Вы действительно хотите подтвердить присутствие ученика на экзамене?</Row>
                <Row justify={'end'} gutter={15}>
                    <Col><Button htmlType={'button'} onClick={this.props.onCanceled}>Отменить</Button></Col>
                    <Col><Button htmlType={'submit'} type={'primary'} loading={this.load} onClick={() => this.handleConfirm()}>Подтвердить</Button></Col>
                </Row>
            </Modal>
        )
    }
}