import {Room} from "./room";

export abstract class RoomList {
    abstract get rooms(): Room[]

    abstract get activeRoom(): Room | undefined

    abstract search: (text: string | undefined) => Promise<void>
    abstract selectRoom: (roomId: string) => Promise<void>
}
