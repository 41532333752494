import {Api, HttpClient} from "./NotificationsApiClient";
import {InitializedStores} from "../../stores/initializeStores";
import {errorUtils} from "../utils/ErrorUtils";

const apiConfig = new HttpClient({
// @ts-ignore
    baseURL: window.$ENV.NOTIFICATIONS_API_URL,
})
const notificationsClient = new Api(apiConfig)

// Add a request interceptor
notificationsClient.http.instance.interceptors.request.use(function (config: any) {
    // Do something before request is sent
    let token = InitializedStores.authStore.getToken

    if (!!token) {
        config.headers.Authorization = 'Bearer ' + token;
    }

    return config;
}, function (error: any) {
    // Do something with request error
    return Promise.reject(error);
});

// Add a response interceptor
notificationsClient.http.instance.interceptors.response.use(function (response: any) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, errorUtils.handleApiError);

export {notificationsClient}
