import React from "react";
import {CallDto, CallReason, CallStatus, CancellationReason, SessionCategory} from "../../services/api/CoreApiClient";
import {observer} from "mobx-react";
import {computed} from "mobx";
import {Table} from "antd";
import {ColumnsType} from "antd/lib/table/interface";
import {dateTimeHelper} from "../../services/utils/DateTimeHelper";
import moment from "moment-timezone/moment-timezone-utils";
import {EnumFriendlyMapper} from "../../services/utils/EnumFriendlyMapper";
import {Link} from "react-router-dom";
import {StudentDetailsScene} from "../../scenes/authorized/Students/StudentDetailsScene";
import {InstructorDetailsScene} from "../../scenes/authorized/Instructors/InstructorDetailsScene";
import {CallStatusSelect} from "./CallStatusSelect";
import {DrivingSessionDetailsScene} from "../../scenes/authorized/Sessions/DrivingSessionDetailsScene";


export interface ICallsProps {
    calls?: CallDto[] | null
    loading: boolean
    changeCallStatus: (callId: string, status: CallStatus) => Promise<any>
}

interface IDataSource {
    id: string;
    status: CallStatus;
    callReason: CallReason;
    cancellationReason?: CancellationReason
    created: moment.Moment;
    studentUserId: string;
    studentPhone: string;
    studentUserName: string;
    instructorUserId?: string;
    instructorPhone?: string;
    instructorUserName?: string;
    session?: {
        sessionId: string;
        startDateTime: moment.Moment;
        cancellationReason?: CancellationReason;
        sessionCategory: SessionCategory;
    }
}

@observer
export class CallsTable extends React.Component<ICallsProps, any> {
    @computed get dataSource() {
        let result: IDataSource[] = []
        if (!!this.props.calls?.length)
            result = this.props.calls.map(call => ({
                id: call.id!,
                created: dateTimeHelper.fromBackendDateTime(call.created!)!,
                callReason: call.callReason!,
                cancellationReason: call.session?.cancellationReason,
                status: call.status!,
                studentUserId: call.student!.userId!,
                studentPhone: call.student!.phone!,
                studentUserName: `${call.student!.lastName} ${call.student!.firstName} ${call.student!.middleName}`,
                instructorUserId: call.instructor?.userId,
                instructorPhone: call?.instructor?.phone,
                instructorUserName: !!call.instructor ? `${call.instructor!.lastName} ${call.instructor!.firstName} ${call.instructor!.middleName}` : undefined,
                session: call.session ? {...call.session, startDateTime: moment(call.session.startDateTime)} : undefined
            }))
        return result
    }

    render() {
        let columns: ColumnsType<IDataSource> = [
            {
                title: "Дата создания",
                dataIndex: 'created',
                render: (_, record) => <div>{dateTimeHelper.toDateAndTimeFormat(record.created)}</div>,
                defaultSortOrder: 'descend',
                sorter: (a, b) => a.created.diff(b.created),
                showSorterTooltip: false,
            },
            {
                title: "Занятие",
                dataIndex: 'id',
                render: (_, record) => record.session &&
                    (record.session.sessionCategory === SessionCategory.Practice
                        ? <Link to={DrivingSessionDetailsScene.route.pathFormat(record.session.sessionId)}>{dateTimeHelper.toDateAndTimeFormat(record.session.startDateTime)}</Link>
                        : dateTimeHelper.toDateAndTimeFormat(record.session.startDateTime)),
                showSorterTooltip: false,
            },
            {
                title: "Ученик",
                dataIndex: 'studentUserName',
                render: (_, record) => <Link to={StudentDetailsScene.route.pathFormat(record.studentUserId)}>{record.studentUserName}</Link>,
                sorter: (a, b) => a.studentUserName.localeCompare(b.studentUserName),
                showSorterTooltip: false,
            },
            {
                title: "Телефон ученика",
                dataIndex: 'studentUserId',
                render: (_, record) => `+7${record.studentPhone}`
            },
            {
                title: "Причина",
                dataIndex: 'callReason',
                render: (_, record) => EnumFriendlyMapper.mapCallReason(record.callReason, record.cancellationReason),
                sorter: (a, b) => a.callReason.localeCompare(b.callReason),
                showSorterTooltip: false,
            },
            {
                title: "Инструктор",
                dataIndex: 'instructorUserName',
                render: (_, record) => record.instructorUserId && <Link to={InstructorDetailsScene.route.pathFormat(record.instructorUserId)}>{record.instructorUserName}</Link>,
                sorter: (a, b) => (a.instructorUserName ?? '').localeCompare(b.instructorUserName ?? ''),
                showSorterTooltip: false,
            },
            {
                title: "Статус",
                dataIndex: 'status',
                render: (_, record) => <CallStatusSelect mode={'select'} value={record.status} onChange={newStatus => this.props.changeCallStatus(record.id, newStatus)}/>
            }
        ]
        return <Table
            dataSource={this.dataSource}
            rowKey={'id'}
            columns={columns}
            loading={this.props.loading}
        />
    }
}
