import React from "react";
import {Button, Col, Form, Row, Tabs, Typography} from "antd";
import {inject, observer} from "mobx-react";
import {computed, observable} from "mobx";
import {coreClientInstance} from "../../../services/api/coreClient";
import moment from "moment-timezone";
import {CreateDrivingSessionScene} from "./CreateDrivingSessionScene";
import {RouteComponentProps} from "react-router-dom";
import {DrivingSessionDetailsScene} from "./DrivingSessionDetailsScene";
import {DrivingTable} from "../../../components/DrivingSession/DrivingTable";
import {DrivingSessionDto, OfficeDto} from "../../../services/api/CoreApiClient";
import {OfficeFilter} from "../../../components/CompanyUnit/OfficeFilter";
import {AuthStore} from "../../../stores/auth/authStore";
import {InjectNames} from "../../../stores/initializeStores";
import { CompanyUnitStore } from "../../../stores/companyUnitStore";

interface IProps extends RouteComponentProps{
    authStore?: AuthStore
    companyUnitStore?: CompanyUnitStore
}

interface IDrivingSession {
    id: string;
    isCompleted: boolean;
    isCancellationAllowed: boolean;
    dateTime: moment.Moment;
    placeName: string;
    instructorUserId: string;
    instructorName: string;
    studentUserId: string;
    studentName: string;
}

@inject(InjectNames.authStore, InjectNames.companyUnitStore)
@observer
export class SessionsScene extends React.Component<IProps> {

    public static route = {
        path: '/sessions',
        pathFormat: () => SessionsScene.route.path,
    }

    async componentDidMount() {
        await this.loadTable()
    }

    //Table
    @observable private tableLoading = false
    @observable private dataSource: DrivingSessionDto[] = []

    @computed get completed() {
        return this.dataSource.filter(item => item.isCompleted)
    }

    @computed get future() {
        return this.dataSource.filter(item => !item.isCompleted)
    }

    loadTable = async () => {
        try {
            this.tableLoading = true
            this.dataSource = (await coreClientInstance.schedule.getDrivingSessionsByManagerList({
                branchId: this.props.companyUnitStore?.officeFilter?.branchId,
                officeId: this.props.companyUnitStore?.officeFilter?.officeId
            })).data
        } finally {
            this.tableLoading = false
        }
    }
    //Table

    //Navigation
    routeToAddDriving = () => {
        this.props.history.push(CreateDrivingSessionScene.route.pathFormat())
    }


    routeToUpdateDriving = (talon: IDrivingSession) => {
        this.props.history.push(DrivingSessionDetailsScene.route.pathFormat(talon.id))
    }
    //Navigation

    //Filter
    @observable officeFilter: OfficeDto | undefined

    setOfficeFilter = (value: OfficeDto | undefined) => {
        this.officeFilter = value
        this.props.companyUnitStore?.setOfficeParam(value);
        return this.loadTable()
    }
    //Filter

    render() {
        const header = <React.Fragment>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <Typography.Title level={2}>Занятия</Typography.Title>
                <Button type={'primary'} onClick={this.routeToAddDriving}>Добавить</Button>
            </div>
        </React.Fragment>

        const filter = !!this.props.authStore!.authorizedUser?.hasMultiplyOffices
            && <Row>
                <Col span={8}>
                    <Form.Item label={'Фильтр по филиалу'} labelCol={{span: 24}}>
                        <OfficeFilter authorizedUser={this.props.authStore!.authorizedUser} onChange={this.setOfficeFilter}/>
                    </Form.Item>
                </Col>
            </Row>

        return <React.Fragment>
            {header}
            {filter}
            <Tabs type={'card'}>
                <Tabs.TabPane key={'future'} tab={'Предстоящие'}>
                    <DrivingTable {...this.props} driving={this.future} load={this.loadTable} loading={this.tableLoading}/>
                </Tabs.TabPane>
                <Tabs.TabPane key={'completed'} tab={'Завершенные'}>
                    <DrivingTable {...this.props} driving={this.completed} load={this.loadTable} loading={this.tableLoading}/>
                </Tabs.TabPane>
            </Tabs>
        </React.Fragment>;
    }
}
