import React from "react";
import {coreClientInstance} from "../../../services/api/coreClient";
import {RouteComponentProps} from "react-router-dom";
import {computed, observable} from "mobx";
import {
    BaseEventDto,
    DrivingSessionDto,
    InstructorInfoDto,
    StudentShotInfoDto
} from "../../../services/api/CoreApiClient";
import {Avatar, Button, Card, Col, Empty, Row, RowProps, Tabs} from "antd";
import Title from "antd/lib/typography/Title";
import {ImageUtils} from "../../../services/utils/ImageUtils";
import {ArrowLeftOutlined, AuditOutlined, CarOutlined, PhoneOutlined} from "@ant-design/icons";
import {SpinOnCenter} from "../../../components/Loading/SpinOnCenter";
import {observer} from "mobx-react";
import moment from "moment-timezone";
import {StudentShotInfoCard} from "../../../components/Student/StudentShotInfoCard";
import {DrivingTable} from "../../../components/DrivingSession/DrivingTable";
import {InstructorsScene} from "./InstructorsScene";
import {InstructorSchedule} from "../../../components/DrivingSession/InstructorSchedule";
import {Events} from "../../../components/History/Events";
import {dateTimeHelper} from "../../../services/utils/DateTimeHelper";
import { UserNotifications } from "../../../components/Notifications/UserNotifications";

interface IRouteProps {
    instructorUserId: string
}

interface IInstructorDetailsSceneProps extends RouteComponentProps<IRouteProps> {
}

@observer
export class InstructorDetailsScene extends React.Component<IInstructorDetailsSceneProps, any> {
    public static route = {
        path: '/instructor-details/:instructorUserId',
        pathFormat: (userId: string) => `/instructor-details/${userId}`
    }

    async componentDidMount() {
        await this.load()
    }

    getUserId = () => {
        return this.props.match.params.instructorUserId;
    }

    load = async () => {
        await this.loadInstructorDetails()
        switch (this.tabKey) {
            case "schedule":
                break;
            case "students":
                await this.loadStudents()
                break;
            case "driving":
                await this.loadDriving()
                break;
            case "history":
                await this.loadHistory()
                break;
        }
    }

    @observable instructor: InstructorInfoDto | undefined = undefined
    @observable instructorLoading = false
    loadInstructorDetails = async () => {
        this.instructor = (await coreClientInstance.instructor.getInstructorByUserIdList({userId: this.getUserId()})).data
    }

    @observable students: StudentShotInfoDto[] = []
    @observable studentsLoading = false
    loadStudents = async () => {
        try {
            this.studentsLoading = true
            this.students = (await coreClientInstance.student.getStudentsByInstructorList({instructorUserId: this.getUserId()})).data
                .sort((a, b) => a.name!.lastName!.localeCompare(b.name!.lastName!))
        } finally {
            this.studentsLoading = false
        }
    }

    @observable history: BaseEventDto[] = []
    @observable historyLoading = false
    loadHistory = async () => {
        try {
            this.historyLoading = true
            this.history = (await coreClientInstance.events.getEventsByUserIdList({userId: this.getUserId()})).data
        } finally {
            this.historyLoading = false
        }
    }

    @observable driving: DrivingSessionDto[] = []
    @observable drivingLoading = false
    loadDriving = async () => {
        try {
            this.drivingLoading = true
            this.driving = (await coreClientInstance.schedule.getDrivingSessionsByInstructorUserIdList({instructorUserId: this.getUserId()})).data
        } finally {
            this.drivingLoading = false
        }
    }

    @computed get userName() {
        if (!this.instructor)
            return undefined
        return `${this.instructor.name!.lastName} ${this.instructor.name!.firstName} ${this.instructor.name!.middleName}`
    }

    private tabKey: TabKeyType = 'schedule'

    setTabKey = async (key: TabKeyType) => {
        this.tabKey = key
        await this.load()
    }


    render() {
        const infoRowProps: RowProps = {gutter: [30, 30]}
        const infoCollProps = {span: 8, style: {width: 350}}

        const details = !this.instructor ? <Card loading={this.instructorLoading}/> : <React.Fragment>
            <Row gutter={15}>
                <Col span={24}><Title level={3}>{this.userName}</Title></Col>
                <Col>
                    <Avatar src={ImageUtils.UrlOrImageNotFountUrl(this.instructor.imageUrl)}
                            alt={ImageUtils.ImageNotFountUrl} size={190} shape={'square'}/>
                </Col>
                <Col>
                    <Row {...infoRowProps}>
                        <Col {...infoCollProps}><PhoneOutlined/> +7{this.instructor!.contacts?.phone}</Col>

                    </Row>

                    <Row {...infoRowProps}>
                        <Col {...infoCollProps}><CarOutlined/> {this.instructor.cars?.map(car => car.model).join(", ")}
                        </Col>
                        <Col {...infoCollProps}><AuditOutlined/> {this.instructor!.categories?.map(category => category.name + " ")}
                        </Col>
                    </Row>
                    <Row {...infoRowProps}>
                        <Col>Стаж
                            преподавания: {this.instructor!.teachingExperience && dateTimeHelper.humanizeDurationFromString(this.instructor!.teachingExperience!)}</Col>
                        <Col>Стаж
                            вождения: {this.instructor!.drivingExperience && dateTimeHelper.humanizeDurationFromString(this.instructor!.drivingExperience!)}</Col>
                    </Row>
                    <Row {...infoRowProps}>
                        <Col>Черты характера: {this.instructor!.description}</Col>
                    </Row>
                </Col>
                {/*<Col>*/}
                {/*    <Row {...infoRowProps}>*/}
                {/*      */}
                {/*        <Col {...infoCollProps}><AuditOutlined /> {this.instructor!.categories?.map(category => category.name)}</Col>*/}
                {/*        <Col {...infoCollProps}><CarOutlined/> {this.instructor.cars?.map(car => car.model).join(", ")}</Col>*/}
                {/*    </Row>*/}

                {/*    <Row>*/}
                {/*        <Col><FileTextOutlined/></Col>*/}
                {/*        <Col>*/}
                {/*            <Row>*/}
                {/*                <Col span={24}>Стаж*/}
                {/*                    вождения: {moment.duration(this.instructor!.drivingExperience).humanize()}</Col>*/}
                {/*                <Col span={24}>Стаж*/}
                {/*                    преподавания: {moment.duration(this.instructor!.teachingExperience).humanize()}</Col>*/}
                {/*                <Col span={24}>Черты характера: {this.instructor!.description}</Col>*/}
                {/*            </Row>*/}
                {/*        </Col>*/}
                {/*    </Row>*/}

                {/*</Col>*/}
            </Row>
        </React.Fragment>

        const students = this.studentsLoading
            ? <SpinOnCenter/>
            : !this.students?.length ? <Empty/> : <div>
                <Row gutter={[30, 30]}>
                    {this.students?.map(student => <Col key={student.studentUserId} span={8}>
                        <StudentShotInfoCard {...this.props} student={student}/>
                    </Col>)}
                </Row>
            </div>

        const talons = this.drivingLoading
            ? <SpinOnCenter/>
            : <DrivingTable {...this.props} driving={this.driving} load={this.load} loading={this.drivingLoading}
                            hideInstructor={true}/>

        const history = <Events {...this.props} historyList={this.history} loading={this.historyLoading} showDateDivider={true}
                                eventItemOptions={{drivingOptions: {hideInstructorDetails: true}}} eventTypeTabs={{showType: 'loaded'}}/>

        const backButton = <Button type={'text'}
                                   icon={<ArrowLeftOutlined/>}
                                   onClick={_ => this.props.history.push(InstructorsScene.route.pathFormat())}>ИНСТРУКТОРЫ</Button>

        return <React.Fragment>
            <Row gutter={[30, 30]}>
                <Col span={24}>{backButton}</Col>
                <Col span={24}>{details}</Col>
                <Col span={24}>
                    <Tabs type={'card'} activeKey={this.tabKey} onChange={value => this.setTabKey(value as TabKeyType)}>
                        <Tabs.TabPane tab={'Расписание'} key={'schedule'}>
                            {this.tabKey === "schedule" && <InstructorSchedule {...this.props}
                                                                               instructorUserId={this.getUserId()}/>}
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={'Ученики'} key={'students'}>
                            {students}
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={'Занятия'} key={'driving'}>
                            {this.drivingLoading ? <SpinOnCenter/> : <div>{talons}</div>}
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={'История действий'} key={'history'}>
                            {this.historyLoading ? <SpinOnCenter/> : <div>{history}</div>}
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={'Уведомления'} key={'notifications'}>
                            <UserNotifications userId={this.getUserId()} />
                        </Tabs.TabPane>
                    </Tabs>
                </Col>
            </Row>
        </React.Fragment>;
    }
}

type TabKeyType = 'schedule' | 'students' | 'driving' | 'history'
