/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AddWorkTimeExclusionDto {
  instructorUserId?: string;

  /** @format date-time */
  from?: string;

  /** @format date-time */
  to?: string;
  reason?: ExclusionReason;
}

export interface AddWorkTimeInUpdateWorkTimesDto {
  categoryId?: string;

  /** @format date-time */
  from?: string;

  /** @format date-time */
  to?: string;
}

export interface AnswerDto {
  questionId?: string;
  values?: string[];
}

export interface AnswerOptionDto {
  answerId?: string;
  text?: string;
}

export interface AppInfoDto {
  type?: VersionType;
  googlePlayUrl?: string;
  appStoreUrl?: string;
  actualVersion?: string;

  /** @format date-span */
  remindLaterDuration?: string;
}

export interface AuthorizedUserDto {
  userId?: string;
  userType?: UserType;
  company?: CompanyDto;
  branch?: BranchDto;
  office?: OfficeDto;

  /** @format date-span */
  timeZone?: string;
  hasMultiplyBranches?: boolean;
  multiplyBranches?: BranchDto[];
  hasMultiplyOffices?: boolean;
  multiplyOffices?: OfficeDto[];
}

export interface AvailableDateTimesDto {
  /** @format date-time */
  minDateTime?: string;

  /** @format date-time */
  maxDateTime?: string;
  branchWorkTime?: BranchWorkTimeDto;
}

export interface AvailableExclusionTimeDto {
  fromList?: string[];
  toList?: string[];
}

export interface BaseContactsDto {
  phone?: string;
  email?: string;
}

export interface BaseEventDto {
  /** @format uuid */
  id?: string;
  type?: EventType;

  /** @format date-time */
  initializedDateTime?: string;
  initializer?: EventInitializerDto;
  targetUsers?: EventUserDto[];
  driving?: DrivingEventDto;
}

export interface BookingInfo {
  bookingTime?: BookingTime;
  isAgreedWithPreassignedTime?: boolean;
  isDecidedToVisit?: boolean;

  /** @format date-time */
  decisionDeadline?: string;
}

export interface BookingTime {
  entryId?: string;

  /** @format date-time */
  bookedTime?: string;
}

export interface BranchDto {
  companyId?: string;
  companyName?: string;
  branchId?: string;
  branchName?: string;
}

export interface BranchWorkTimeDto {
  /** @format date-time */
  startWorkTime?: string;

  /** @format date-time */
  endWorkTime?: string;
}

export interface BuildTokenClaimsRequest {
  phone?: string;
}

export interface BuildTokenClaimsResponse {
  claimsToAddOrOverride?: Record<string, string>;
}

export interface CallDto {
  /** @format uuid */
  id: string;
  status: CallStatus;
  callReason: CallReason;

  /** @format date-time */
  created: string;
  student?: StudentInCallDto;
  instructor?: InstructorInCallDto;
  session?: SessionInCallDto;
}

export enum CallReason {
  DrivingCanceled = "DrivingCanceled",
  IgnoredExamAssignment = "IgnoredExamAssignment",
}

export enum CallStatus {
  New = "New",
  CallLater = "CallLater",
  Failed = "Failed",
  Completed = "Completed",
}

export interface CancelDrivingSessionDto {
  id?: string;
  optLockDateTime?: string;
  cancellationReason?: CancellationReason;

  /** @format uuid */
  concurrencyId?: string;
}

export enum CancellationReason {
  StudentTimeOff = "StudentTimeOff",
  StudentTruancy = "StudentTruancy",
  StudentHasLatePayments = "StudentHasLatePayments",
  InstructorChanged = "InstructorChanged",
  InstructorTimeOff = "InstructorTimeOff",
  InstructorAbsenteeism = "InstructorAbsenteeism",
  InstructorGotSick = "InstructorGotSick",
  InstructorCarMalfunction = "InstructorCarMalfunction",
  CarAccident = "CarAccident",
  DrivingRequestExpired = "DrivingRequestExpired",
  Other = "Other",
}

export interface CarDto {
  category?: DrivingCategoryDto;
  model?: string;
  year?: string;
  color?: string;
  number?: string;
  gearbox?: Gearbox;
}

export interface ChangeStatusSessionResponse {
  /** @format int32 */
  talonsCount?: number;

  /** @format uuid */
  concurrencyId?: string;
}

export interface ChoiceInstructorDto {
  instructorUserId?: string;
}

export interface ChooseSessionDateDto {
  sessionId?: string;
  studentUserId?: string;
  instructorUserId?: string;
  drivingCategoryId?: string;
  category?: SessionCategory;

  /** @format date-time */
  dateTime?: string;
  talonType?: DrivingTalonType;
}

export interface ChooseSessionDateResponse {
  sessionId?: string;

  /** @format int32 */
  talonsCount?: number;

  /** @format uuid */
  concurrencyId?: string;
}

export interface CompanyDto {
  companyId?: string;
  companyName?: string;
}

export interface ConfirmSessionRequestDto {
  sessionId?: string;
  optLockDateTime?: string;
  category?: SessionCategory;

  /** @format uuid */
  concurrencyId?: string;
}

export interface CredentialsDto {
  baseUrl: string;
  accessToken: string;
  refreshToken: string;

  /** @format date-time */
  validUntilUtc: string;
  matrixUserId: string;
}

export enum DateTimeStatus {
  Immutable = "Immutable",
  Mutable = "Mutable",
  WaitApprove = "WaitApprove",
  StartingSoon = "StartingSoon",
  WaitDecision = "WaitDecision",
  Canceled = "Canceled",
  RequestDrivingCanceled = "RequestDrivingCanceled",
  WaitCancel = "WaitCancel",
  WaitCancelDecision = "WaitCancelDecision",
}

export interface DrivingCategoryDto {
  id?: string;
  name?: string;
  isUniversalCategory?: boolean;
}

export interface DrivingCategoryWorkTimePlanningDto {
  id?: string;
  name?: string;

  /** @format date-span */
  duration?: string;
  isUniversalCategory?: boolean;
}

export interface DrivingEventDto {
  sessionId?: string;

  /** @format int32 */
  orderNumber?: number;

  /** @format date-time */
  drivingDateTime?: string;
  cancellationReason?: CancellationReason;
  instructor?: EventUserDto;
  student?: EventUserDto;
}

export interface DrivingSessionDetailsDto {
  id?: string;

  /** @format int32 */
  orderNumber?: number;
  talonStatus?: TalonStatus;
  cancellationReason?: CancellationReason;
  isCancellationAllowed?: boolean;

  /** @format date-time */
  dateTime?: string;
  optLockDateTime?: string;
  place?: PlaceDto;
  student?: StudentInSessionDetails;
  instructor?: InstructorInSessionDetails;
  talonType?: DrivingTalonType;

  /** @format uuid */
  concurrencyId?: string;
}

export interface DrivingSessionDto {
  id?: string;

  /** @format int32 */
  orderNumber?: number;
  cancellationReason?: CancellationReason;
  talonStatus?: TalonStatus;

  /** @format date-time */
  dateTime?: string;
  optLockDateTime?: string;
  isCompleted?: boolean;
  isCancellationAllowed?: boolean;

  /** @format uuid */
  concurrencyId?: string;
  place?: PlaceDto;
  instructor?: InstructorInSession;
  student?: StudentInSession;
}

export enum DrivingTalonType {
  Common = "Common",
  Extra = "Extra",
}

export enum EducationActionType {
  CallTo = "CallTo",
}

export interface EducationCompletionCallAction {
  phone?: string;
  type?: EducationActionType;
}

export interface EducationCompletionInfo {
  title?: string;
  subtitle?: string;
  action?: EducationCompletionCallAction;
}

export interface EventInitializerDto {
  imageUrl?: string;
  userType?: UserType;
  userId?: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
}

export enum EventType {
  DrivingRequest = "DrivingRequest",
  DrivingLessonPassed = "DrivingLessonPassed",
  DrivingStartingSoon = "DrivingStartingSoon",
  NotifyPayment = "NotifyPayment",
  DrivingRequestConfirmed = "DrivingRequestConfirmed",
  DrivingCanceled = "DrivingCanceled",
  DrivingAssigned = "DrivingAssigned",
  OffsetFailed = "OffsetFailed",
  DrivingRequestCanceled = "DrivingRequestCanceled",
  StudentCanAssignedOnExam = "StudentCanAssignedOnExam",
  CustomNotifications = "CustomNotifications",
  DrivingCancelRequest = "DrivingCancelRequest",
  DrivingCancelRequestCanceled = "DrivingCancelRequestCanceled",
  DrivingCancelRequestApproved = "DrivingCancelRequestApproved",
}

export interface EventUserDto {
  userType?: UserType;
  userId?: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
}

export interface ExamAdmission {
  name?: string;
  icon?: string;
}

export interface ExamEntryDto {
  id?: string;

  /** @format int32 */
  bookingCount?: number;

  /** @format date-time */
  startTime?: string;
}

export interface ExamEntryRequestDto {
  examId?: string;
  entryId?: string;
}

export interface ExaminerData {
  /** @format int32 */
  remainingRecruitsCount?: number;
}

export enum ExclusionReason {
  InstructorTimeOff = "InstructorTimeOff",
  InstructorAbsenteeism = "InstructorAbsenteeism",
  InstructorGotSick = "InstructorGotSick",
  InstructorCarMalfunction = "InstructorCarMalfunction",
  CarAccident = "CarAccident",
  Other = "Other",
}

export interface FileUploadRequest {
  contentBase64?: string;
}

export enum Gearbox {
  Automatic = "Automatic",
  Manual = "Manual",
}

export interface GeolocationDto {
  /** @format double */
  latitude?: number;

  /** @format double */
  longitude?: number;
}

export interface GetCancelReasonsResponse {
  cancellationReason?: CancellationReason;
  translatedReason?: string;
}

export interface GroupDto {
  groupId?: string;
  groupName?: string;
  companyId?: string;
  branchId?: string;
  officeId?: string;
}

export interface GroupsFilter {
  instructorUserId?: string;
  companyId?: string;
  branchId?: string;
  officeId?: string;
}

export interface InfoForInstructorDto {
  informationText?: string;
}

export interface InfoForUserDto {
  informationText?: string;
}

export interface InstallmentPlanDto {
  /** @format string */
  startDate?: string;

  /** @format string */
  endDate?: string;

  /** @format float */
  sum?: number;

  /** @format float */
  paymentSize?: number;
}

export interface InstructorCalendarDto {
  dateRange?: TimeRangeDto;
  workTimes?: WorkTimeDto[];
  exclusions?: WorkTimeExclusionDto[];
}

export interface InstructorCalendarSummaryDto {
  dateRange?: TimeRangeDto;

  /** @format date-span */
  usedTimeZone?: string;
  scheduleWorkDays?: WorkDayDto[];
}

export interface InstructorInCallDto {
  userId: string;
  phone?: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
}

export interface InstructorInfoDto {
  name?: NameDto;
  userId?: string;
  imageUrl?: string;

  /** @format date-span */
  drivingExperience?: string;

  /** @format date-span */
  teachingExperience?: string;
  description?: string;
  cars?: CarDto[];
  categories?: DrivingCategoryDto[];
  contacts?: BaseContactsDto;
  status?: InstructorInfoStatus;
  rating?: RatingDto;
}

export enum InstructorInfoStatus {
  Available = "Available",
  Unavailable = "Unavailable",
}

export interface InstructorInSession {
  instructorUserId?: string;
  name?: NameDto;
}

export interface InstructorInSessionDetails {
  instructorUserId?: string;
  instructorPhone?: string;
  instructorImageUrl?: string;
  instructorCar?: CarDto;
  name?: NameDto;
}

export interface InstructorSessionDto {
  id?: string;
  name?: string;
  description?: string;
  category?: SessionCategory;

  /** @format date-time */
  dateTime?: string;
  optLockDateTime?: string;

  /** @format date-span */
  duration?: string;
  isInternalExam?: boolean;
  shouldBookStudents?: boolean;
  dateTimeStatus?: DateTimeStatus;
  cancellationReason?: string;
  isImportant?: boolean;
  drivingCategory?: DrivingCategoryDto;
  place?: PlaceDto;
  studentsUserIds?: string[];
  allowDrivingCancelByRequest?: boolean;
  allowForcedCancelDriving?: boolean;
  talonType?: DrivingTalonType;

  /** @format uuid */
  concurrencyId?: string;
}

export interface InstructorSessionsWithStudentListDto {
  sessions?: InstructorSessionDto[];
  studentList?: StudentShortDetail[];
}

export interface InstructorsFilter {
  groupId?: string;
  branchId?: string;
}

export interface InstructorSimpleDto {
  userId?: string;
  phone?: string;
  name?: NameDto;
}

export interface InstructorUserProfileDto {
  userId?: string;
  name?: NameDto;

  /** @format date-span */
  timeZone?: string;
  imageUrl?: string;
  contacts?: BaseContactsDto;

  /** @format date-span */
  drivingExperience?: string;

  /** @format date-span */
  teachingExperience?: string;
  description?: string;
  cars?: CarDto[];
  notificationSettings?: NotificationSettingsDto;
  examinerData?: ExaminerData;
  rating?: RatingDto;
  allowDrivingWithoutStudent?: boolean;
}

export interface MatrixPushContentDto {
  body?: string;
  msgtype?: string;
}

export interface MatrixPushDeviceDto {
  pushkey?: string;
}

export interface MatrixPushDto {
  notification?: MatrixPushNotificationDto;
}

export interface MatrixPushNotificationDto {
  content?: MatrixPushContentDto;
  devices?: MatrixPushDeviceDto[];
  room_id?: string;
  room_name?: string;
  sender?: string;
  sender_display_name?: string;
  type?: string;
  id?: string;
  event_id?: string;
}

export interface MatrixPushResponseDto {
  rejected?: string[];
}

export interface MigrationResult {
  dbNamename?: string;
  success?: boolean;
  errorMessage?: string;
}

export interface MobileData {
  actionText?: string;
  deepLink?: string;
}

export interface NameDto {
  firstName?: string;
  lastName?: string;
  middleName?: string;
}

export interface NotificationProxyDto {
  fierbaseToken?: string;
}

export interface NotificationsDto {
  /** @format uuid */
  id?: string;
  title?: string;
  description?: string;

  /** @format date-time */
  dateTime?: string;
  isViewed?: boolean;
  mobileData?: MobileData;
}

export interface NotificationsDtoPaginationResponseDto {
  /** @format int32 */
  totalCount?: number;
  items?: NotificationsDto[];
}

export interface NotificationSettingsDto {
  isEventsEnabled?: boolean;
  isMessagesEnabled?: boolean;
}

export interface OAuthToken {
  accessToken?: string;
  refreshToken?: string;
}

export interface OfficeDto {
  companyId?: string;
  companyName?: string;
  branchId?: string;
  branchName?: string;
  officeId?: string;
  officeName?: string;
}

export interface PaginationRequestDto {
  /** @format int32 */
  pageSize?: number;

  /** @format int32 */
  pageNumber?: number;
}

export interface PaymentDto {
  /** @format float */
  size?: number;

  /** @format string */
  date?: string;
}

export interface PaymentInfoDto {
  installmentPlan?: InstallmentPlanDto;

  /** @format string */
  nextPaymentDate?: string;

  /** @format float */
  alreadyPaid?: number;

  /**
   * @format float
   * @min 0
   * @max 1
   */
  paymentProgress?: number;

  /** @format float */
  nextPaymentSize?: number;
  isOverdue?: boolean;
}

export interface PhoneVerificationRequest {
  phone?: string;
}

export interface PlaceDto {
  id?: string;
  address?: string;
  geolocation?: GeolocationDto;
}

export interface ProfileWithCategoryDto {
  userId?: string;
  category?: DrivingCategoryDto;
}

export interface QuestionDto {
  questionId?: string;
  text?: string;

  /** @format int32 */
  orderNumber?: number;
  viewType?: ViewType;
  previousId?: string;
  ratingQuestion?: RatingQuestionDto;

  /** @format int32 */
  previousRatingMin?: number;

  /** @format int32 */
  previousRatingMax?: number;
  answerOptions?: AnswerOptionDto[];
}

export interface RatingDto {
  /** @format double */
  value?: number;

  /** @format int32 */
  maxValue?: number;

  /** @format int32 */
  pollsCount?: number;
}

export interface RatingQuestionDto {
  /** @format int32 */
  ratingSize?: number;
}

export interface RemoveAllWorkTimeRequest {
  removeCurrentDay?: boolean;
}

export interface ResetSessionDateDto {
  sessionId?: string;
  optLockDateTime?: string;
  category?: SessionCategory;

  /** @format uuid */
  concurrencyId?: string;
}

export interface ScheduledSurveyDto {
  surveyId?: string;
  sessionId?: string;
  sessionCategory?: SessionCategory;

  /** @format int32 */
  questionCount?: number;
  userId?: string;
}

export enum SessionActionType {
  None = "None",
  ExamConfirmation = "ExamConfirmation",
  BookingAnExam = "BookingAnExam",
  DoubleСonfirmation = "DoubleСonfirmation",
}

export enum SessionCategory {
  Theory = "theory",
  Practice = "practice",
  Offset = "offset",
  Exam = "exam",
  Other = "other",
}

export interface SessionInCallDto {
  sessionId: string;

  /** @format date-time */
  startDateTime: string;
  cancellationReason?: CancellationReason;
  sessionCategory: SessionCategory;
}

export interface SessionInWorkTimeDto {
  id: string;
  category: SessionCategory;
  sessionName?: string;
  students: StudentInWorkTimeDto[];
  optLockDateTime?: string;
  status?: DateTimeStatus;
  talonType?: DrivingTalonType;

  /** @format uuid */
  concurrencyId?: string;
}

export interface SessionIsAvailableDto {
  isAvailable?: boolean;
  reason?: string;
}

export interface SessionsInWorkDayDto {
  drivingCategory?: DrivingCategoryDto;

  /** @format int32 */
  sessionsCount?: number;
  sessionCategory?: SessionCategory;
  sessionName?: string;
  isInternalExam?: boolean;
}

export enum SessionStatus {
  Success = "Success",
  Fail = "Fail",
  Absence = "Absence",
  Presence = "Presence",
  None = "None",
}

export interface SetSessionResultDto {
  sessionId?: string;
  success?: boolean;
  studentUserId?: string;
  category?: SessionCategory;
}

export interface ShortSurveyDto {
  surveyId?: string;
  title?: string;
  subtitle?: string;

  /** @format date-time */
  creationTime?: string;
  isPassed?: boolean;
}

export enum SignInType {
  Otp = "Otp",
  Password = "Password",
}

export type SignInTypeDto = object;

export interface SimpleSessionMasterDto {
  masterUserId?: string;
  name?: NameDto;
  isInstructor?: boolean;
  imageUrl?: string;
}

export interface SimpleSessionStudentDto {
  studentUserId?: string;
  name?: NameDto;
  imageUrl?: string;

  /** @format int32 */
  talonsCount?: number;
  sessionStatus?: SessionStatus;
  shouldSetSessionResult?: boolean;
}

export interface StudentCalendarDto {
  workTimes?: StudentWorkTimeDto[];
}

export interface StudentDetailsDto {
  studentUserId?: string;
  studentPhone?: string;
  studentEmail?: string;
  instructorUserId?: string;
  group?: GroupDto;
  imageUrl?: string;
  name?: NameDto;
  studentStatus?: ExamAdmission;
  examReadiness?: boolean;

  /** @format int32 */
  availableToBookingTalonsCount?: number;

  /** @format int32 */
  scheduledTalonsCount?: number;

  /** @format int32 */
  purchasedTalonsCount?: number;
  drivingCategory?: DrivingCategoryDto;

  /** @format int32 */
  burnedTalons?: number;

  /** @format date-time */
  lastActivityTime?: string;
  canRefuseFrom?: boolean;
  talonDetails?: TalonCountsDto;
  hasAutoConfirmedDrivings?: boolean;
}

export interface StudentExamInfoDto {
  studentId?: string;
  studentName?: NameDto;
  imageUrl?: string;
  isBooked?: boolean;
  isApproved?: boolean;
}

export interface StudentInCallDto {
  userId: string;
  phone?: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
}

export interface StudentInSession {
  studentUserId?: string;
  name?: NameDto;
}

export interface StudentInSessionDetails {
  studentUserId?: string;
  studentPhone?: string;
  studentImageUrl?: string;

  /** @format int32 */
  studentAvailableToBookingTalons?: number;

  /** @format int32 */
  studentScheduledTalons?: number;

  /** @format int32 */
  studentPurchasedTalonsCount?: number;
  name?: NameDto;
}

export interface StudentInWorkTimeDto {
  studentUserId: string;
  student: NameDto;
}

export interface StudentSessionDto {
  id?: string;
  name?: string;
  description?: string;
  category?: SessionCategory;

  /** @format date-time */
  dateTime?: string;
  optLockDateTime?: string;

  /** @format date-span */
  duration?: string;
  bookingInformation?: BookingInfo;
  place?: PlaceDto;
  masterUserId?: string;
  simpleSessionMasterDto?: SimpleSessionMasterDto;
  cancellationReason?: string;
  isImportant?: boolean;
  sessionActionType?: SessionActionType;
  status?: SessionStatus;
  dateTimeStatus?: DateTimeStatus;
  allowDrivingCancelByRequest?: boolean;
  allowForcedCancelDriving?: boolean;
  talonType?: DrivingTalonType;

  /** @format uuid */
  concurrencyId?: string;
}

export interface StudentSessionInWorkTimeDto {
  id: string;
  category: SessionCategory;
  actionType?: SessionActionType;
  sessionName?: string;
  isCanceled?: boolean;
  confirmed?: boolean;
  studentAccepted?: boolean;
  bookingId?: string;

  /** @format date-time */
  bookedTime?: string;
  drivingTalonType?: DrivingTalonType;

  /** @format uuid */
  concurrencyId?: string;
}

export interface StudentsFilter {
  groupId?: string;
  instructorUserId?: string;
  onlyWithoutInstructor?: boolean;
  withoutMobileApp?: boolean;

  /** @format int32 */
  pageSize?: number;

  /** @format int32 */
  pageNumber?: number;
  branchId?: string;
  officeId?: string;
}

export interface StudentShortDetail {
  id?: string;
  name?: NameDto;
  imageUrl?: string;
  category?: DrivingCategoryDto;
  isAdmittedToExam?: boolean;
  hasAutoConfirmedDrivings?: boolean;
}

export interface StudentShortDetailsWithTalons {
  /** @format int32 */
  talonsCount?: number;
  customInfoMessage?: string;
  id?: string;
  name?: NameDto;
  imageUrl?: string;
  category?: DrivingCategoryDto;
  isAdmittedToExam?: boolean;
  hasAutoConfirmedDrivings?: boolean;
}

export interface StudentShortForExaminerDto {
  studentId?: string;
  studentPhone?: string;
  name?: NameDto;
  imageUrl?: string;
  additionalInfoMessage?: string;
  drivingCategory?: DrivingCategoryDto;
}

export interface StudentShotInfoDto {
  studentUserId?: string;
  studentPhone?: string;
  name?: NameDto;
  imageUrl?: string;

  /** @format int32 */
  talonsCount?: number;
  hasAutoConfirmedDrivings?: boolean;
  drivingCategory?: DrivingCategoryDto;
}

export interface StudentStatus {
  studentSubmittedForExam?: boolean;
}

export interface StudentsWithUpcomingsSessions {
  studentId?: string;
  instructorSession?: InstructorSessionDto;
}

export interface StudentUserProfileDto {
  userId?: string;
  name?: NameDto;

  /** @format date-span */
  timeZone?: string;
  imageUrl?: string;

  /** @format int32 */
  talonsCount?: number;
  talonDetails?: TalonCountsDto;
  group?: string;
  drivingCategory?: DrivingCategoryDto;
  contacts?: BaseContactsDto;
  instructorUserId?: string;
  waitApproveInstructorUserId?: string;
  isEducationCompleted?: boolean;
  notificationSettings?: NotificationSettingsDto;
  userProfileSettings?: UserProfileSettings;
  enableDrivingByExtraTalons?: boolean;
}

export interface StudentVideoLessonDto {
  videoUrl?: string;
  name?: string;
  videoPreview?: VideoPreview;
}

export interface StudentWorkTimeDto {
  /** @format uuid */
  id?: string;

  /** @format date-time */
  start: string;

  /** @format date-time */
  end: string;
  drivingCategory?: DrivingCategoryDto;
  driving?: StudentSessionInWorkTimeDto;
}

export interface SubmitDrivingToCancelRequest {
  sessionId?: string;

  /** @format uuid */
  concurrencyId?: string;
}

export interface SurveyAnswerDto {
  surveyId?: string;
  answers?: AnswerDto[];
}

export interface SurveyDto {
  surveyId?: string;
  completed?: boolean;
  rejected?: boolean;

  /** @format date-time */
  remindDateTime?: string;
  isReminderAllowed?: boolean;
  sessionId?: string;
  sessionCategory?: SessionCategory;
  title?: string;
  userId?: string;
  canRefuse?: boolean;
  questions?: QuestionDto[];
}

export interface SurveyFilters {
  filterId?: string;
  name?: string;

  /** @format int32 */
  notPassedCount?: number;
}

export interface SurveyFiltersDto {
  defaultFilterId?: string;
  filterList?: SurveyFilters[];
}

export interface TalonCountsDto {
  /** @format int32 */
  free?: number;

  /** @format int32 */
  used?: number;

  /** @format int32 */
  planned?: number;

  /** @format int32 */
  burnt?: number;

  /** @format int32 */
  paid?: number;

  /** @format int32 */
  exam?: number;

  /** @format int32 */
  preExam?: number;

  /** @format int32 */
  postExam?: number;

  /** @format int32 */
  extra?: number;
}

export enum TalonStatus {
  WaitApprove = "WaitApprove",
  Assigned = "Assigned",
  Canceled = "Canceled",
  WaitCancel = "WaitCancel",
}

export interface TimeRangeDto {
  /** @format date-time */
  startDateTime?: string;

  /** @format date-time */
  endDateTime?: string;
}

export interface UpdateBookStatusDto {
  sessionId?: string;
  studentList?: UpdatedStudentBookStatusDto[];
}

export interface UpdatedInstructorProfileDto {
  notificationSettings: NotificationSettingsDto;
}

export interface UpdatedStudentBookStatusDto {
  studentId?: string;
  isBook?: boolean;
}

export interface UpdatedStudentProfileDto {
  email?: string;
  notificationSettings?: NotificationSettingsDto;
  userProfileSettings?: UserProfileSettings;
}

export interface UpdateWorkTimesDto {
  deleteIdList?: string[];
  addList?: AddWorkTimeInUpdateWorkTimesDto[];
}

export interface UserProfileSettings {
  notificationSettings?: NotificationSettingsDto;
  enableDrivingAutoConfirm?: boolean;
}

export enum UserType {
  Student = "Student",
  Instructor = "Instructor",
  Teacher = "Teacher",
  Manager = "Manager",
}

export interface VerificationInfoDto {
  attemptId?: string;
  message?: string;
  signInType?: SignInType;
  signInInfo?: SignInTypeDto;
}

export interface VerificationRequest {
  accessCode?: string;
  attemptId?: string;
}

export interface VerificationSuccessResponse {
  token?: OAuthToken;
}

export enum VersionType {
  None = "None",
  Optional = "Optional",
  Critical = "Critical",
}

export interface VideoPreview {
  previewUrl?: string;

  /** @format int32 */
  previewWidth?: number;

  /** @format int32 */
  previewHeight?: number;
}

export enum ViewType {
  Rating = "Rating",
  YesNo = "YesNo",
  Text = "Text",
  OneOf = "OneOf",
  FewOf = "FewOf",
}

export interface WorkDayDto {
  /** @format string */
  date?: string;
  plannedSessions?: SessionsInWorkDayDto[];
  burnedSessions?: SessionsInWorkDayDto[];
}

export interface WorkTimeDto {
  /** @format uuid */
  id?: string;

  /** @format date-time */
  start: string;

  /** @format date-time */
  end: string;
  drivingCategory?: DrivingCategoryDto;
  driving?: SessionInWorkTimeDto;
}

export interface WorkTimeExclusionDto {
  /** @format uuid */
  id: string;
  reason: ExclusionReason;

  /** @format date-time */
  start: string;

  /** @format date-time */
  end: string;
}

export interface WorkTimeForPlanningDto {
  /** @format date-time */
  startTime?: string;
  listEndTime?: string[];
}

export interface WorkTimePlanningInfoDto {
  /** @format date-span */
  stepDuration?: string;

  /** @format date-span */
  timeZone?: string;

  /** @format date-span */
  canPlanDrivingAfter?: string;

  /** @format date-time */
  startDateTime?: string;

  /** @format date-time */
  endDateTime?: string;
  drivingCategories?: DrivingCategoryWorkTimePlanningDto[];
}

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, ResponseType } from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.instance.defaults.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  private createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      formData.append(
        key,
        property instanceof Blob
          ? property
          : typeof property === "object" && property !== null
          ? JSON.stringify(property)
          : `${property}`,
      );
      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = (format && this.format) || void 0;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      requestParams.headers.common = { Accept: "*/*" };
      requestParams.headers.post = {};
      requestParams.headers.put = {};

      body = this.createFormData(body as Record<string, unknown>);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
        ...(requestParams.headers || {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Actonica.DrivingSchool.ApiGateway
 * @version v1
 */
export class Api<SecurityDataType extends unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  appInfo = {
    /**
     * No description
     *
     * @tags AppInfo
     * @name CheckAppVersionList
     * @request GET:/AppInfo/CheckAppVersion
     */
    checkAppVersionList: (query?: { Version?: string }, params: RequestParams = {}) =>
      this.http.request<AppInfoDto, any>({
        path: `/AppInfo/CheckAppVersion`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AppInfo
     * @name GetPerfomanceList
     * @request GET:/AppInfo/GetPerfomance
     */
    getPerfomanceList: (params: RequestParams = {}) =>
      this.http.request<string[], any>({
        path: `/AppInfo/GetPerfomance`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  auth = {
    /**
     * No description
     *
     * @tags Auth
     * @name PhoneVerificationCreate
     * @request POST:/auth/phone-verification
     */
    phoneVerificationCreate: (body: PhoneVerificationRequest, params: RequestParams = {}) =>
      this.http.request<VerificationInfoDto, any>({
        path: `/auth/phone-verification`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name OtpVerificationCreate
     * @request POST:/auth/otp-verification
     */
    otpVerificationCreate: (body: VerificationRequest, params: RequestParams = {}) =>
      this.http.request<VerificationSuccessResponse, any>({
        path: `/auth/otp-verification`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name GetAuthorizedUserList
     * @request GET:/auth/GetAuthorizedUser
     */
    getAuthorizedUserList: (params: RequestParams = {}) =>
      this.http.request<AuthorizedUserDto, any>({
        path: `/auth/GetAuthorizedUser`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  token = {
    /**
     * No description
     *
     * @tags Auth
     * @name TokenList
     * @request GET:/token
     */
    tokenList: (params: RequestParams = {}) =>
      this.http.request<OAuthToken, any>({
        path: `/token`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  authCallback = {
    /**
     * No description
     *
     * @tags AuthCallback
     * @name BuildTokenClaimsCreate
     * @request POST:/AuthCallback/BuildTokenClaims
     */
    buildTokenClaimsCreate: (body: BuildTokenClaimsRequest, params: RequestParams = {}) =>
      this.http.request<BuildTokenClaimsResponse, any>({
        path: `/AuthCallback/BuildTokenClaims`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  calls = {
    /**
     * No description
     *
     * @tags Calls
     * @name GetAllList
     * @request GET:/Calls/GetAll
     */
    getAllList: (query?: { BranchId?: string; OfficeId?: string; Status?: any }, params: RequestParams = {}) =>
      this.http.request<CallDto[], any>({
        path: `/Calls/GetAll`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Calls
     * @name ChangeStatusUpdate
     * @request PUT:/Calls/ChangeStatus
     */
    changeStatusUpdate: (query?: { callId?: string; status?: any }, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/Calls/ChangeStatus`,
        method: "PUT",
        query: query,
        ...params,
      }),
  };
  matrix = {
    /**
     * No description
     *
     * @tags Chats
     * @name PushV1NotifyCreate
     * @request POST:/_matrix/push/v1/notify
     */
    pushV1NotifyCreate: (body: MatrixPushDto, params: RequestParams = {}) =>
      this.http.request<MatrixPushResponseDto, any>({
        path: `/_matrix/push/v1/notify`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  chats = {
    /**
     * No description
     *
     * @tags Chats
     * @name GetCredentialsCreate
     * @request POST:/Chats/GetCredentials
     */
    getCredentialsCreate: (params: RequestParams = {}) =>
      this.http.request<CredentialsDto, any>({
        path: `/Chats/GetCredentials`,
        method: "POST",
        format: "json",
        ...params,
      }),
  };
  database = {
    /**
     * No description
     *
     * @tags Database
     * @name MigrateList
     * @request GET:/Database/Migrate
     */
    migrateList: (query?: { password?: string }, params: RequestParams = {}) =>
      this.http.request<MigrationResult[], any>({
        path: `/Database/Migrate`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  dataSync = {
    /**
     * No description
     *
     * @tags DataSync
     * @name SyncList
     * @request GET:/DataSync/Sync
     */
    syncList: (params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/DataSync/Sync`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DataSync
     * @name SyncChatsList
     * @request GET:/DataSync/SyncChats
     */
    syncChatsList: (
      query?: {
        registerManagers?: boolean;
        registerInstructors?: boolean;
        registerStudents?: boolean;
        addManagersChat?: boolean;
        addStudentsContacts?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<void, any>({
        path: `/DataSync/SyncChats`,
        method: "GET",
        query: query,
        ...params,
      }),
  };
  dev = {
    /**
     * No description
     *
     * @tags Dev
     * @name GetJsonList
     * @request GET:/Dev/GetJson
     */
    getJsonList: (params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/Dev/GetJson`,
        method: "GET",
        ...params,
      }),
  };
  events = {
    /**
     * No description
     *
     * @tags Events
     * @name GetEventsBySessionIdList
     * @request GET:/Events/GetEventsBySessionId
     */
    getEventsBySessionIdList: (query?: { id?: string }, params: RequestParams = {}) =>
      this.http.request<BaseEventDto[], any>({
        path: `/Events/GetEventsBySessionId`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Events
     * @name GetEventsByUserIdList
     * @request GET:/Events/GetEventsByUserId
     */
    getEventsByUserIdList: (query?: { userId?: string }, params: RequestParams = {}) =>
      this.http.request<BaseEventDto[], any>({
        path: `/Events/GetEventsByUserId`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Events
     * @name GetEventsByDateList
     * @request GET:/Events/GetEventsByDate
     */
    getEventsByDateList: (
      query?: { date?: string; branchId?: string; officeId?: string; dateTimeZone?: string; eventType?: any },
      params: RequestParams = {},
    ) =>
      this.http.request<BaseEventDto[], any>({
        path: `/Events/GetEventsByDate`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  files = {
    /**
     * No description
     *
     * @tags Files
     * @name GetFileDetail
     * @request GET:/Files/GetFile/{id}
     */
    getFileDetail: (id: string, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/Files/GetFile/${id}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Files
     * @name GetStudentImageDetail
     * @request GET:/Files/GetStudentImage/{imageKey}
     */
    getStudentImageDetail: (imageKey: string, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/Files/GetStudentImage/${imageKey}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Files
     * @name GetInstructorImageDetail
     * @request GET:/Files/GetInstructorImage/{imageKey}
     */
    getInstructorImageDetail: (imageKey: string, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/Files/GetInstructorImage/${imageKey}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Files
     * @name UploadMyImageCreate
     * @request POST:/Files/UploadMyImage
     */
    uploadMyImageCreate: (body: FileUploadRequest, params: RequestParams = {}) =>
      this.http.request<string, any>({
        path: `/Files/UploadMyImage`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Files
     * @name DeleteMyImageDelete
     * @request DELETE:/Files/DeleteMyImage
     */
    deleteMyImageDelete: (params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/Files/DeleteMyImage`,
        method: "DELETE",
        ...params,
      }),
  };
  instructor = {
    /**
     * No description
     *
     * @tags Instructor
     * @name GetInstructorsAvailableToChangeByStudentList
     * @request GET:/Instructor/GetInstructorsAvailableToChangeByStudent
     */
    getInstructorsAvailableToChangeByStudentList: (query?: { studentUserId?: string }, params: RequestParams = {}) =>
      this.http.request<InstructorInfoDto[], any>({
        path: `/Instructor/GetInstructorsAvailableToChangeByStudent`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Instructor
     * @name GetInstructorByUserIdList
     * @request GET:/Instructor/GetInstructorByUserId
     */
    getInstructorByUserIdList: (query?: { userId?: string }, params: RequestParams = {}) =>
      this.http.request<InstructorInfoDto, any>({
        path: `/Instructor/GetInstructorByUserId`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Instructor
     * @name GetMyProfileList
     * @request GET:/Instructor/GetMyProfile
     */
    getMyProfileList: (params: RequestParams = {}) =>
      this.http.request<InstructorUserProfileDto, any>({
        path: `/Instructor/GetMyProfile`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Instructor
     * @name GetMyInfoList
     * @request GET:/Instructor/GetMyInfo
     */
    getMyInfoList: (params: RequestParams = {}) =>
      this.http.request<InfoForInstructorDto, any>({
        path: `/Instructor/GetMyInfo`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Instructor
     * @name UpdateMyProfileUpdate
     * @request PUT:/Instructor/UpdateMyProfile
     */
    updateMyProfileUpdate: (body: UpdatedInstructorProfileDto, params: RequestParams = {}) =>
      this.http.request<InstructorUserProfileDto, any>({
        path: `/Instructor/UpdateMyProfile`,
        method: "PUT",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Instructor
     * @name GetSimpleInstructorsByFilterCreate
     * @request POST:/Instructor/GetSimpleInstructorsByFilter
     */
    getSimpleInstructorsByFilterCreate: (body: InstructorsFilter, params: RequestParams = {}) =>
      this.http.request<InstructorSimpleDto[], any>({
        path: `/Instructor/GetSimpleInstructorsByFilter`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Instructor
     * @name GetInstructorsByFilterCreate
     * @request POST:/Instructor/GetInstructorsByFilter
     */
    getInstructorsByFilterCreate: (body: InstructorsFilter, params: RequestParams = {}) =>
      this.http.request<InstructorInfoDto[], any>({
        path: `/Instructor/GetInstructorsByFilter`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Instructor
     * @name GetGroupsByFilterCreate
     * @request POST:/Instructor/GetGroupsByFilter
     */
    getGroupsByFilterCreate: (body: GroupsFilter, params: RequestParams = {}) =>
      this.http.request<GroupDto[], any>({
        path: `/Instructor/GetGroupsByFilter`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  notificationProxy = {
    /**
     * No description
     *
     * @tags NotificationProxy
     * @name LogInFirebaseTokenCreate
     * @request POST:/NotificationProxy/LogInFirebaseToken
     */
    logInFirebaseTokenCreate: (body: NotificationProxyDto, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/NotificationProxy/LogInFirebaseToken`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags NotificationProxy
     * @name LogOutFirebaseTokenCreate
     * @request POST:/NotificationProxy/LogOutFirebaseToken
     */
    logOutFirebaseTokenCreate: (body: NotificationProxyDto, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/NotificationProxy/LogOutFirebaseToken`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags NotificationProxy
     * @name GetNotificationsCreate
     * @request POST:/NotificationProxy/GetNotifications
     */
    getNotificationsCreate: (body: PaginationRequestDto, params: RequestParams = {}) =>
      this.http.request<NotificationsDtoPaginationResponseDto, any>({
        path: `/NotificationProxy/GetNotifications`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags NotificationProxy
     * @name GetUnreadNotificationsCountList
     * @request GET:/NotificationProxy/GetUnreadNotificationsCount
     */
    getUnreadNotificationsCountList: (params: RequestParams = {}) =>
      this.http.request<number, any>({
        path: `/NotificationProxy/GetUnreadNotificationsCount`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags NotificationProxy
     * @name ReadAllNotificationsCreate
     * @request POST:/NotificationProxy/ReadAllNotifications
     */
    readAllNotificationsCreate: (params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/NotificationProxy/ReadAllNotifications`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags NotificationProxy
     * @name ReadNotificationCreate
     * @request POST:/NotificationProxy/ReadNotification
     */
    readNotificationCreate: (query?: { id?: string }, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/NotificationProxy/ReadNotification`,
        method: "POST",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags NotificationProxy
     * @name GetNotificationsByUserIdList
     * @request GET:/NotificationProxy/GetNotificationsByUserId
     */
    getNotificationsByUserIdList: (
      query?: { UserId?: string; "Pagination.PageSize"?: number; "Pagination.PageNumber"?: number },
      params: RequestParams = {},
    ) =>
      this.http.request<NotificationsDtoPaginationResponseDto, any>({
        path: `/NotificationProxy/GetNotificationsByUserId`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  paymentInfo = {
    /**
     * No description
     *
     * @tags PaymentInfo
     * @name GetMyPaymentStoryList
     * @request GET:/PaymentInfo/GetMyPaymentStory
     */
    getMyPaymentStoryList: (params: RequestParams = {}) =>
      this.http.request<PaymentDto[], any>({
        path: `/PaymentInfo/GetMyPaymentStory`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PaymentInfo
     * @name GetMyPaymentInfoList
     * @request GET:/PaymentInfo/GetMyPaymentInfo
     */
    getMyPaymentInfoList: (params: RequestParams = {}) =>
      this.http.request<PaymentInfoDto, any>({
        path: `/PaymentInfo/GetMyPaymentInfo`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  place = {
    /**
     * No description
     *
     * @tags Place
     * @name GetInstructorPlaceList
     * @request GET:/Place/GetInstructorPlace
     */
    getInstructorPlaceList: (query?: { instructorUserId?: string }, params: RequestParams = {}) =>
      this.http.request<PlaceDto, any>({
        path: `/Place/GetInstructorPlace`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  rating = {
    /**
     * No description
     *
     * @tags Rating
     * @name GetNotPassedSurveysCountList
     * @request GET:/Rating/GetNotPassedSurveysCount
     */
    getNotPassedSurveysCountList: (params: RequestParams = {}) =>
      this.http.request<number, any>({
        path: `/Rating/GetNotPassedSurveysCount`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Rating
     * @name GetSurveyFiltersList
     * @request GET:/Rating/GetSurveyFilters
     */
    getSurveyFiltersList: (params: RequestParams = {}) =>
      this.http.request<SurveyFiltersDto, any>({
        path: `/Rating/GetSurveyFilters`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Rating
     * @name GetAllSurveysList
     * @request GET:/Rating/GetAllSurveys
     */
    getAllSurveysList: (
      query?: { FilteId?: string; PageSize?: number; PageNumber?: number },
      params: RequestParams = {},
    ) =>
      this.http.request<ShortSurveyDto[], any>({
        path: `/Rating/GetAllSurveys`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Rating
     * @name GetSurveyList
     * @request GET:/Rating/GetSurvey
     */
    getSurveyList: (query?: { surveyId?: string }, params: RequestParams = {}) =>
      this.http.request<SurveyDto, any>({
        path: `/Rating/GetSurvey`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Rating
     * @name GetScheduledSurveysList
     * @request GET:/Rating/GetScheduledSurveys
     */
    getScheduledSurveysList: (params: RequestParams = {}) =>
      this.http.request<ScheduledSurveyDto[], any>({
        path: `/Rating/GetScheduledSurveys`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Rating
     * @name SendSurveyCreate
     * @request POST:/Rating/SendSurvey
     */
    sendSurveyCreate: (body: SurveyAnswerDto, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/Rating/SendSurvey`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Rating
     * @name RemindCreate
     * @request POST:/Rating/Remind
     */
    remindCreate: (query?: { surveyId?: string }, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/Rating/Remind`,
        method: "POST",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Rating
     * @name RejectCreate
     * @request POST:/Rating/Reject
     */
    rejectCreate: (query?: { surveyId?: string }, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/Rating/Reject`,
        method: "POST",
        query: query,
        ...params,
      }),
  };
  schedule = {
    /**
     * No description
     *
     * @tags Schedule
     * @name GetStudentScheduleList
     * @request GET:/Schedule/GetStudentSchedule
     */
    getStudentScheduleList: (params: RequestParams = {}) =>
      this.http.request<StudentSessionDto[], any>({
        path: `/Schedule/GetStudentSchedule`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name GetInstructorScheduleList
     * @request GET:/Schedule/GetInstructorSchedule
     */
    getInstructorScheduleList: (params: RequestParams = {}) =>
      this.http.request<InstructorSessionDto[], any>({
        path: `/Schedule/GetInstructorSchedule`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name GetInstructorScheduleWithStudentList
     * @request GET:/Schedule/GetInstructorScheduleWithStudent
     */
    getInstructorScheduleWithStudentList: (params: RequestParams = {}) =>
      this.http.request<InstructorSessionsWithStudentListDto, any>({
        path: `/Schedule/GetInstructorScheduleWithStudent`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name CheckDrivingPlanningIsAvailableList
     * @request GET:/Schedule/CheckDrivingPlanningIsAvailable
     */
    checkDrivingPlanningIsAvailableList: (query?: { planningDate?: string }, params: RequestParams = {}) =>
      this.http.request<SessionIsAvailableDto, any>({
        path: `/Schedule/CheckDrivingPlanningIsAvailable`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name GetStudentsWithUpcomingsSessionsList
     * @request GET:/Schedule/GetStudentsWithUpcomingsSessions
     */
    getStudentsWithUpcomingsSessionsList: (params: RequestParams = {}) =>
      this.http.request<StudentsWithUpcomingsSessions[], any>({
        path: `/Schedule/GetStudentsWithUpcomingsSessions`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name GetInstructorScheduleByIdList
     * @request GET:/Schedule/GetInstructorScheduleById
     */
    getInstructorScheduleByIdList: (query?: { id?: string; sessionCategory?: any }, params: RequestParams = {}) =>
      this.http.request<InstructorSessionDto, any>({
        path: `/Schedule/GetInstructorScheduleById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name GetInstructorScheduleByStudentUserIdList
     * @request GET:/Schedule/GetInstructorScheduleByStudentUserId
     */
    getInstructorScheduleByStudentUserIdList: (query?: { studentUserId?: string }, params: RequestParams = {}) =>
      this.http.request<InstructorSessionDto[], any>({
        path: `/Schedule/GetInstructorScheduleByStudentUserId`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name GetStudentScheduleByIdList
     * @request GET:/Schedule/GetStudentScheduleById
     */
    getStudentScheduleByIdList: (query?: { id?: string; sessionCategory?: any }, params: RequestParams = {}) =>
      this.http.request<StudentSessionDto, any>({
        path: `/Schedule/GetStudentScheduleById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name GetDrivingSessionsByManagerList
     * @request GET:/Schedule/GetDrivingSessionsByManager
     */
    getDrivingSessionsByManagerList: (query?: { branchId?: string; officeId?: string }, params: RequestParams = {}) =>
      this.http.request<DrivingSessionDto[], any>({
        path: `/Schedule/GetDrivingSessionsByManager`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name GetDrivingSessionsByStudentUserIdList
     * @request GET:/Schedule/GetDrivingSessionsByStudentUserId
     */
    getDrivingSessionsByStudentUserIdList: (query?: { studentUserId?: string }, params: RequestParams = {}) =>
      this.http.request<DrivingSessionDto[], any>({
        path: `/Schedule/GetDrivingSessionsByStudentUserId`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name GetDrivingSessionsByInstructorUserIdList
     * @request GET:/Schedule/GetDrivingSessionsByInstructorUserId
     */
    getDrivingSessionsByInstructorUserIdList: (query?: { instructorUserId?: string }, params: RequestParams = {}) =>
      this.http.request<DrivingSessionDto[], any>({
        path: `/Schedule/GetDrivingSessionsByInstructorUserId`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name CancelDrivingSessionByIdCreate
     * @request POST:/Schedule/CancelDrivingSessionById
     */
    cancelDrivingSessionByIdCreate: (body: CancelDrivingSessionDto, params: RequestParams = {}) =>
      this.http.request<ChangeStatusSessionResponse, any>({
        path: `/Schedule/CancelDrivingSessionById`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name GetCancelReasonsList
     * @request GET:/Schedule/GetCancelReasons
     */
    getCancelReasonsList: (params: RequestParams = {}) =>
      this.http.request<GetCancelReasonsResponse[], any>({
        path: `/Schedule/GetCancelReasons`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name GetDrivingSessionByIdList
     * @request GET:/Schedule/GetDrivingSessionById
     */
    getDrivingSessionByIdList: (query?: { id?: string }, params: RequestParams = {}) =>
      this.http.request<DrivingSessionDetailsDto, any>({
        path: `/Schedule/GetDrivingSessionById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name ChooseSessionDateCreate
     * @request POST:/Schedule/ChooseSessionDate
     */
    chooseSessionDateCreate: (body: ChooseSessionDateDto, params: RequestParams = {}) =>
      this.http.request<ChooseSessionDateResponse, any>({
        path: `/Schedule/ChooseSessionDate`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name ResetSessionRequestCreate
     * @request POST:/Schedule/ResetSessionRequest
     */
    resetSessionRequestCreate: (
      body: ResetSessionDateDto,
      query?: { studentId?: string },
      params: RequestParams = {},
    ) =>
      this.http.request<ChangeStatusSessionResponse, any>({
        path: `/Schedule/ResetSessionRequest`,
        method: "POST",
        query: query,
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name SubmitDrivingToCancelCreate
     * @request POST:/Schedule/SubmitDrivingToCancel
     */
    submitDrivingToCancelCreate: (body: SubmitDrivingToCancelRequest, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/Schedule/SubmitDrivingToCancel`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name GetExamEntriesList
     * @request GET:/Schedule/GetExamEntries
     */
    getExamEntriesList: (query?: { sessionId?: string }, params: RequestParams = {}) =>
      this.http.request<ExamEntryDto[], any>({
        path: `/Schedule/GetExamEntries`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name SetExamEntryCreate
     * @request POST:/Schedule/SetExamEntry
     */
    setExamEntryCreate: (body: ExamEntryRequestDto, query?: { studentId?: string }, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/Schedule/SetExamEntry`,
        method: "POST",
        query: query,
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name ResetExamEntryCreate
     * @request POST:/Schedule/ResetExamEntry
     */
    resetExamEntryCreate: (body: ExamEntryRequestDto, query?: { studentId?: string }, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/Schedule/ResetExamEntry`,
        method: "POST",
        query: query,
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name ConfirmSessionRequestCreate
     * @request POST:/Schedule/ConfirmSessionRequest
     */
    confirmSessionRequestCreate: (
      body: ConfirmSessionRequestDto,
      query?: { studentId?: string },
      params: RequestParams = {},
    ) =>
      this.http.request<ChangeStatusSessionResponse, any>({
        path: `/Schedule/ConfirmSessionRequest`,
        method: "POST",
        query: query,
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name SetSessionResultCreate
     * @request POST:/Schedule/SetSessionResult
     */
    setSessionResultCreate: (body: SetSessionResultDto, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/Schedule/SetSessionResult`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name GetMyInstructorWorkTimeList
     * @request GET:/Schedule/GetMyInstructorWorkTime
     */
    getMyInstructorWorkTimeList: (query?: { instructorId?: string; talonType?: any }, params: RequestParams = {}) =>
      this.http.request<string[], any>({
        path: `/Schedule/GetMyInstructorWorkTime`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name GetMyWorkTimeList
     * @request GET:/Schedule/GetMyWorkTime
     */
    getMyWorkTimeList: (query?: { drivingCategoryId?: string }, params: RequestParams = {}) =>
      this.http.request<string[], any>({
        path: `/Schedule/GetMyWorkTime`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name GetAllMyWorkTimeList
     * @request GET:/Schedule/GetAllMyWorkTime
     */
    getAllMyWorkTimeList: (query?: { drivingCategoryId?: string }, params: RequestParams = {}) =>
      this.http.request<string[], any>({
        path: `/Schedule/GetAllMyWorkTime`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name GetInstructorWorkTimeList
     * @request GET:/Schedule/GetInstructorWorkTime
     */
    getInstructorWorkTimeList: (
      query?: { instructorUserId?: string; drivingCategoryId?: string },
      params: RequestParams = {},
    ) =>
      this.http.request<string[], any>({
        path: `/Schedule/GetInstructorWorkTime`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name GetSimpleSessionMasterList
     * @request GET:/Schedule/GetSimpleSessionMaster
     */
    getSimpleSessionMasterList: (query?: { sessionId?: string; sessionCategory?: any }, params: RequestParams = {}) =>
      this.http.request<SimpleSessionMasterDto, any>({
        path: `/Schedule/GetSimpleSessionMaster`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name GetSimpleSessionStudentsList
     * @request GET:/Schedule/GetSimpleSessionStudents
     */
    getSimpleSessionStudentsList: (query?: { sessionId?: string; sessionCategory?: any }, params: RequestParams = {}) =>
      this.http.request<SimpleSessionStudentDto[], any>({
        path: `/Schedule/GetSimpleSessionStudents`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name GetStudentsByInstructorWithAvailableTalonsList
     * @request GET:/Schedule/GetStudentsByInstructorWithAvailableTalons
     */
    getStudentsByInstructorWithAvailableTalonsList: (
      query?: { instructorUserId?: string; categoryId?: string },
      params: RequestParams = {},
    ) =>
      this.http.request<StudentShotInfoDto[], any>({
        path: `/Schedule/GetStudentsByInstructorWithAvailableTalons`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name GetStudentsByScheduleList
     * @request GET:/Schedule/GetStudentsBySchedule
     */
    getStudentsByScheduleList: (query?: { sessionId?: string }, params: RequestParams = {}) =>
      this.http.request<StudentExamInfoDto[], any>({
        path: `/Schedule/GetStudentsBySchedule`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name UpdateStudentBookStatusCreate
     * @request POST:/Schedule/UpdateStudentBookStatus
     */
    updateStudentBookStatusCreate: (body: UpdateBookStatusDto, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/Schedule/UpdateStudentBookStatus`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name GetInstructorCalendarSummaryList
     * @request GET:/Schedule/GetInstructorCalendarSummary
     */
    getInstructorCalendarSummaryList: (query?: { useTimeZone?: string }, params: RequestParams = {}) =>
      this.http.request<InstructorCalendarSummaryDto, any>({
        path: `/Schedule/GetInstructorCalendarSummary`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name GetInstructorCalendarList
     * @request GET:/Schedule/GetInstructorCalendar
     */
    getInstructorCalendarList: (
      query?: { InstructorUserId?: string; UseTimeZone?: string; FromDate?: string; ToDate?: string },
      params: RequestParams = {},
    ) =>
      this.http.request<InstructorCalendarDto, any>({
        path: `/Schedule/GetInstructorCalendar`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name GetStudentCalendarList
     * @request GET:/Schedule/GetStudentCalendar
     */
    getStudentCalendarList: (
      query?: { StudentUserId?: string; UseTimeZone?: string; FromDate?: string; ToDate?: string },
      params: RequestParams = {},
    ) =>
      this.http.request<StudentCalendarDto, any>({
        path: `/Schedule/GetStudentCalendar`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name GetAvailableDateTimesList
     * @request GET:/Schedule/GetAvailableDateTimes
     */
    getAvailableDateTimesList: (query?: { branchId?: string }, params: RequestParams = {}) =>
      this.http.request<AvailableDateTimesDto, any>({
        path: `/Schedule/GetAvailableDateTimes`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name GetWorkTimePlanningInfoList
     * @request GET:/Schedule/GetWorkTimePlanningInfo
     */
    getWorkTimePlanningInfoList: (params: RequestParams = {}) =>
      this.http.request<WorkTimePlanningInfoDto, any>({
        path: `/Schedule/GetWorkTimePlanningInfo`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name GetNotPlannedDaysList
     * @request GET:/Schedule/GetNotPlannedDays
     */
    getNotPlannedDaysList: (params: RequestParams = {}) =>
      this.http.request<string[], any>({
        path: `/Schedule/GetNotPlannedDays`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name GetWorkTimeForPlanningList
     * @request GET:/Schedule/GetWorkTimeForPlanning
     */
    getWorkTimeForPlanningList: (query?: { CategoryId?: string; PlanningDate?: string }, params: RequestParams = {}) =>
      this.http.request<WorkTimeForPlanningDto[], any>({
        path: `/Schedule/GetWorkTimeForPlanning`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name UpdateWorkTimeCreate
     * @request POST:/Schedule/UpdateWorkTime
     */
    updateWorkTimeCreate: (body: UpdateWorkTimesDto, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/Schedule/UpdateWorkTime`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name RemoveAllWorkTimesCreate
     * @request POST:/Schedule/RemoveAllWorkTimes
     */
    removeAllWorkTimesCreate: (body: RemoveAllWorkTimeRequest, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/Schedule/RemoveAllWorkTimes`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name AddWorkTimeExclusionCreate
     * @request POST:/Schedule/AddWorkTimeExclusion
     */
    addWorkTimeExclusionCreate: (body: AddWorkTimeExclusionDto, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/Schedule/AddWorkTimeExclusion`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name GetAvailableExclusionTimesList
     * @request GET:/Schedule/GetAvailableExclusionTimes
     */
    getAvailableExclusionTimesList: (query?: { instructorUserId?: string }, params: RequestParams = {}) =>
      this.http.request<AvailableExclusionTimeDto, any>({
        path: `/Schedule/GetAvailableExclusionTimes`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name GetInstructorForExtraDrivingList
     * @request GET:/Schedule/GetInstructorForExtraDriving
     */
    getInstructorForExtraDrivingList: (params: RequestParams = {}) =>
      this.http.request<InstructorInfoDto[], any>({
        path: `/Schedule/GetInstructorForExtraDriving`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  student = {
    /**
     * No description
     *
     * @tags Student
     * @name GetMyProfileList
     * @request GET:/Student/GetMyProfile
     */
    getMyProfileList: (params: RequestParams = {}) =>
      this.http.request<StudentUserProfileDto, any>({
        path: `/Student/GetMyProfile`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Student
     * @name GetMyTalonCountList
     * @request GET:/Student/GetMyTalonCount
     */
    getMyTalonCountList: (params: RequestParams = {}) =>
      this.http.request<number, any>({
        path: `/Student/GetMyTalonCount`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Student
     * @name ConfirmExamReadinessCreate
     * @request POST:/Student/ConfirmExamReadiness
     */
    confirmExamReadinessCreate: (query?: { studentUserId?: string }, params: RequestParams = {}) =>
      this.http.request<StudentStatus, any>({
        path: `/Student/ConfirmExamReadiness`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Student
     * @name UpdateMyProfileUpdate
     * @request PUT:/Student/UpdateMyProfile
     */
    updateMyProfileUpdate: (body: UpdatedStudentProfileDto, params: RequestParams = {}) =>
      this.http.request<StudentUserProfileDto, any>({
        path: `/Student/UpdateMyProfile`,
        method: "PUT",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Student
     * @name ChangeInstructorCreate
     * @request POST:/Student/ChangeInstructor
     */
    changeInstructorCreate: (
      query?: { studentUserId?: string; instructorUserId?: string },
      params: RequestParams = {},
    ) =>
      this.http.request<void, any>({
        path: `/Student/ChangeInstructor`,
        method: "POST",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Student
     * @name ChoiceInstructorCreate
     * @request POST:/Student/ChoiceInstructor
     */
    choiceInstructorCreate: (body: ChoiceInstructorDto, query?: { userId?: string }, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/Student/ChoiceInstructor`,
        method: "POST",
        query: query,
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Student
     * @name CancelInstructorChoiceCreate
     * @request POST:/Student/CancelInstructorChoice
     */
    cancelInstructorChoiceCreate: (
      body: ChoiceInstructorDto,
      query?: { userId?: string },
      params: RequestParams = {},
    ) =>
      this.http.request<void, any>({
        path: `/Student/CancelInstructorChoice`,
        method: "POST",
        query: query,
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Student
     * @name GetEducationCompletionInfoList
     * @request GET:/Student/GetEducationCompletionInfo
     */
    getEducationCompletionInfoList: (params: RequestParams = {}) =>
      this.http.request<EducationCompletionInfo, any>({
        path: `/Student/GetEducationCompletionInfo`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Student
     * @name GetStudentsByInstructorList
     * @request GET:/Student/GetStudentsByInstructor
     */
    getStudentsByInstructorList: (query?: { instructorUserId?: string }, params: RequestParams = {}) =>
      this.http.request<StudentShotInfoDto[], any>({
        path: `/Student/GetStudentsByInstructor`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Student
     * @name GetStudentsByInstructorWithDetailsList
     * @request GET:/Student/GetStudentsByInstructorWithDetails
     */
    getStudentsByInstructorWithDetailsList: (query?: { instructorUserId?: string }, params: RequestParams = {}) =>
      this.http.request<StudentShortDetailsWithTalons[], any>({
        path: `/Student/GetStudentsByInstructorWithDetails`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Student
     * @name GetAllMyProfilesList
     * @request GET:/Student/GetAllMyProfiles
     */
    getAllMyProfilesList: (params: RequestParams = {}) =>
      this.http.request<ProfileWithCategoryDto[], any>({
        path: `/Student/GetAllMyProfiles`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Student
     * @name GetStudentsByFilterCreate
     * @request POST:/Student/GetStudentsByFilter
     */
    getStudentsByFilterCreate: (body: StudentsFilter, params: RequestParams = {}) =>
      this.http.request<StudentDetailsDto[], any>({
        path: `/Student/GetStudentsByFilter`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Student
     * @name GetStudentVideoLessonsList
     * @request GET:/Student/GetStudentVideoLessons
     */
    getStudentVideoLessonsList: (params: RequestParams = {}) =>
      this.http.request<StudentVideoLessonDto[], any>({
        path: `/Student/GetStudentVideoLessons`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Student
     * @name GetStudentDetailsByUserIdList
     * @request GET:/Student/GetStudentDetailsByUserId
     */
    getStudentDetailsByUserIdList: (query?: { studentUserId?: string }, params: RequestParams = {}) =>
      this.http.request<StudentDetailsDto, any>({
        path: `/Student/GetStudentDetailsByUserId`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Student
     * @name GetStudentsDetailsByUserIdsCreate
     * @request POST:/Student/GetStudentsDetailsByUserIds
     */
    getStudentsDetailsByUserIdsCreate: (body: string[], params: RequestParams = {}) =>
      this.http.request<StudentDetailsDto[], any>({
        path: `/Student/GetStudentsDetailsByUserIds`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Student
     * @name GetStudentsShortsByUserIdsCreate
     * @request POST:/Student/GetStudentsShortsByUserIds
     */
    getStudentsShortsByUserIdsCreate: (body: string[], params: RequestParams = {}) =>
      this.http.request<StudentShortDetailsWithTalons[], any>({
        path: `/Student/GetStudentsShortsByUserIds`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Student
     * @name GetStudentCountForExaminerList
     * @request GET:/Student/GetStudentCountForExaminer
     */
    getStudentCountForExaminerList: (params: RequestParams = {}) =>
      this.http.request<number, any>({
        path: `/Student/GetStudentCountForExaminer`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Student
     * @name GetStudentsForExaminerList
     * @request GET:/Student/GetStudentsForExaminer
     */
    getStudentsForExaminerList: (params: RequestParams = {}) =>
      this.http.request<StudentShortForExaminerDto[], any>({
        path: `/Student/GetStudentsForExaminer`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Student
     * @name AssignExaminerToStudentsCreate
     * @request POST:/Student/AssignExaminerToStudents
     */
    assignExaminerToStudentsCreate: (body: string[], params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/Student/AssignExaminerToStudents`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Student
     * @name RemoveStudentFromExaminerCreate
     * @request POST:/Student/RemoveStudentFromExaminer
     */
    removeStudentFromExaminerCreate: (body: string, params: RequestParams = {}) =>
      this.http.request<number, any>({
        path: `/Student/RemoveStudentFromExaminer`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  user = {
    /**
     * No description
     *
     * @tags User
     * @name GetMyInfoList
     * @request GET:/User/GetMyInfo
     */
    getMyInfoList: (params: RequestParams = {}) =>
      this.http.request<InfoForUserDto, any>({
        path: `/User/GetMyInfo`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name GetUserInfoList
     * @request GET:/User/GetUserInfo
     */
    getUserInfoList: (query?: { userId?: string }, params: RequestParams = {}) =>
      this.http.request<InfoForUserDto, any>({
        path: `/User/GetUserInfo`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
}
