import {
    UserType,
    CancellationReason,
    ExclusionReason,
    TalonStatus, EventType, BaseEventDto, CallStatus, SessionCategory, CallReason
} from "../api/CoreApiClient";

export class EnumFriendlyMapper {
    public static mapSessionCategory = (session: SessionCategory) => {
        switch (session){
            case SessionCategory.Theory:
                return 'Теория'
            case SessionCategory.Practice:
                return 'Вождение'
            case SessionCategory.Offset:
                return 'Зачет'
            case SessionCategory.Exam:
                return 'Экзамен'

            default:
                return 'Неизвестно'
        }
    }

    public static mapTalonStatusDetails = (status: TalonStatus, cancellationReason: CancellationReason | null) => {
        switch (status) {
            case TalonStatus.Canceled:
                return 'Отменено. ' + EnumFriendlyMapper.mapCancellationReason(cancellationReason!)
            case TalonStatus.WaitApprove:
                return 'Ожидает подтверждения'
            case TalonStatus.Assigned:
                return 'Назначено вождение'
            default:
                return 'Не назначен'
        }
    }

    public static mapEventType = (baseEvent: BaseEventDto) => {
        const eventType = baseEvent.type!
        const friendlyEventType = EnumFriendlyMapper.mapEventTypeEnum(baseEvent.type!)
        switch (eventType) {
            case EventType.DrivingCanceled:
                return `${friendlyEventType}. ${EnumFriendlyMapper.mapCancellationReason(baseEvent.driving!.cancellationReason!)}`
            case EventType.DrivingRequestCanceled:
                return `${friendlyEventType}. ${EnumFriendlyMapper.mapCancellationReason(baseEvent.driving!.cancellationReason!)}`
            default:
                return friendlyEventType
        }
    }

    public static mapEventTypeEnum = (eventType: EventType) => {
        switch (eventType) {
            case EventType.NotifyPayment:
                return 'Напоминание о платеже'
            case EventType.OffsetFailed:
                return 'Зачет не сдан'
            case EventType.DrivingRequestConfirmed:
                return 'Запрос на вождение подтвержден'
            case EventType.DrivingCanceled:
                return 'Вождение отменено'
            case EventType.DrivingRequestCanceled:
                return 'Запрос на вождение отменен'
            case EventType.DrivingRequest:
                return 'Вождение ожидает подтверждения'
            case EventType.DrivingAssigned:
                return 'Назначено вождение'
            case EventType.DrivingLessonPassed:
                return 'Урок вождения завершился'
            case EventType.DrivingStartingSoon:
                return 'Вождение скоро начинается'
            case EventType.DrivingCancelRequest:
                return 'Запрошена отмена'
            case EventType.DrivingCancelRequestCanceled:
                return 'Запрос на отмену отказан'
            case EventType.DrivingCancelRequestApproved:
                return 'Запрос на отмену подтвержден'
            default:
                return eventType
        }
    }

    public static mapCallStatus = (status: CallStatus) => {
        switch (status){
            case CallStatus.New:
                return "Новый"
            case CallStatus.CallLater:
                return "Перезвонить"
            case CallStatus.Failed:
                return "Недозвон"
            case CallStatus.Completed:
                return "Выполненные"
        }
    }
    public static mapCallReason = (callReason: CallReason, cancellationReason: CancellationReason | undefined) => {
        switch (callReason){
            case CallReason.DrivingCanceled:
                return !!cancellationReason ? EnumFriendlyMapper.mapCancellationReason(cancellationReason) : 'Отменено вождение'
            case CallReason.IgnoredExamAssignment:
                return 'Необходимо подтвердить присутствие на экзамене'
            default:
                return callReason
        }
    }

    public static mapCancellationReason = (reason: CancellationReason) => {
        switch (reason) {
            case CancellationReason.StudentTimeOff:
                return 'Ученик отпросился.'
            case CancellationReason.StudentTruancy:
                return 'Прогул ученика. Вождение сгорает.'
            case CancellationReason.StudentHasLatePayments:
                return 'Просрочка оплаты.'
            case CancellationReason.InstructorChanged:
                return 'Сменился инструктор.'
            case CancellationReason.InstructorTimeOff:
                return 'Инструктор отпросился.'
            case CancellationReason.InstructorAbsenteeism:
                return 'Прогул инструктора.'
            case CancellationReason.InstructorGotSick:
                return 'Инструктор заболел.'
            case CancellationReason.InstructorCarMalfunction:
                return 'Неисправность авто инструктора.'
            case CancellationReason.CarAccident:
                return 'ДТП.'
            case CancellationReason.DrivingRequestExpired:
                return 'Запрос на вождение просрочился.'
            case CancellationReason.Other:
                return 'Прочее.'
        }
    }
    public static mapExclusionReason = (reason: ExclusionReason) => {
        switch (reason) {
            case ExclusionReason.InstructorTimeOff:
                return 'Инструктор отпросился.'
            case ExclusionReason.InstructorAbsenteeism:
                return 'Прогул инструктора.'
            case ExclusionReason.InstructorGotSick:
                return 'Инструктор заболел.'
            case ExclusionReason.InstructorCarMalfunction:
                return 'Несправность авто инстркутора.'
            case ExclusionReason.CarAccident:
                return 'ДТП.'
            case ExclusionReason.Other:
                return 'Прочее.'
        }
    }

    public static mapRole = (initializedBy?: UserType, privateUser?: boolean) => {

        if (!initializedBy && privateUser === true)
            return "Частное лицо"
        console.log(initializedBy, privateUser)

        switch (initializedBy) {
            case UserType.Student:
                return 'Ученик'
            case UserType.Instructor:
                return 'Инструктор'
            case UserType.Manager:
                return 'Менеджер'
            default:
                return 'Автоматически'
        }
    }
}
