import React from "react";
import {EventUserDto, UserType} from "../../services/api/CoreApiClient";
import {EnumFriendlyMapper} from "../../services/utils/EnumFriendlyMapper";
import {InstructorDetailsScene} from "../../scenes/authorized/Instructors/InstructorDetailsScene";
import {StudentDetailsScene} from "../../scenes/authorized/Students/StudentDetailsScene";
import {Link} from "react-router-dom";

export class EventUserLink extends React.Component<{ user: EventUserDto, targetUser?: boolean }> {
    render() {
        const user = this.props.user
        const role = user ? EnumFriendlyMapper.mapRole(user.userType) :  EnumFriendlyMapper.mapRole(undefined, true) 
        const userName =  user ?`${user.lastName} ${user.firstName} ${user.middleName}` : 'Частное лицо'

        let linkToUserUrl = undefined
        if (user && user.userType === UserType.Instructor) {
            linkToUserUrl = InstructorDetailsScene.route.pathFormat(user.userId!)
        } else if (user && user.userType === UserType.Student)
            linkToUserUrl = StudentDetailsScene.route.pathFormat(user.userId!)
        let linkToUser = !!linkToUserUrl ? <Link to={linkToUserUrl}>{userName}</Link> : undefined
        return <span>{role} {linkToUser} {this.props.children}</span>
    }
}
