import React from "react";
import {Avatar, Card, Col, Row, Typography} from "antd";
import {ImageUtils} from "../../services/utils/ImageUtils";
import {RouteComponentProps} from "react-router-dom";
import {InstructorDetailsScene} from "../../scenes/authorized/Instructors/InstructorDetailsScene";
import {InstructorInfoDto} from "../../services/api/CoreApiClient";
import {observer} from "mobx-react";
import {CarOutlined, PhoneOutlined} from "@ant-design/icons";

interface IInstructorCardProps extends RouteComponentProps {
    instructor: InstructorInfoDto
}

@observer
export class InstructorCard extends React.Component<IInstructorCardProps, any> {

    routeToInstructorDetails = () => {
        this.props.history.push(InstructorDetailsScene.route.pathFormat(this.props.instructor!.userId!))
    }

    render() {
        return <Card className={'mouse-hover'} onClick={this.routeToInstructorDetails}>
            <Row>
                <Col style={{marginRight: 12}} ><Avatar size={80} shape={'circle'} src={ImageUtils.UrlOrImageNotFountUrl(this.props.instructor?.imageUrl)} alt={ImageUtils.ImageNotFountUrl}/></Col>
                <Col span={12}>
                    <div><Typography.Title level={5}>{`${this.props.instructor?.name?.lastName} ${this.props.instructor?.name?.firstName} ${this.props.instructor?.name?.middleName}`}</Typography.Title></div>
                    <div>Инструктор</div>
                </Col>
            </Row>
            <div style={{marginTop: 15}}>
                <div><PhoneOutlined/> +7{this.props.instructor?.contacts?.phone}</div>
                <div><CarOutlined /> {this.props.instructor.cars?.map(car => car.model).join(", ")}</div>
            </div>
        </Card>
    }
}
