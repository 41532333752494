import {ChatStoreBase} from "./chatsStoreBase";
import * as sdk from "matrix-js-sdk";
import {ClientEvent, EventType, Filter, MsgType, RoomEvent, RoomMemberEvent, UserEvent} from "matrix-js-sdk";
import {computed, observable} from "mobx";
import {RoomList} from "./roomList";
import {Room} from "./room";
import {MatrixClient} from "matrix-js-sdk/lib/client";
import {RoomMember} from "./roomMember";
import {Message, MessageFrom} from "./message";
import moment from "moment-timezone";
import {coreClientInstance} from "../../../services/api/coreClient";

export const initialLoadEventsCount = 10
export const nextLoadEventsCount = 30

export class MatrixChatStore extends ChatStoreBase {
    private client?: sdk.MatrixClient
    @observable roomList?: RoomListMatrix;
    private refreshToken?: string
    private validUntilUtc?: moment.Moment
    @observable private credentialsLoading = true
    @observable private waitSyncing = true

    @computed get loading() {
        return this.credentialsLoading || this.waitSyncing
    }

    connect = async () => {
        try {
            if (!this.client) {
                this.credentialsLoading = true

                let chatCredentials = (await coreClientInstance.chats.getCredentialsCreate()).data

                this.waitSyncing = true
                this.credentialsLoading = false

                await this._connectToMatrix({
                    accessToken: chatCredentials.accessToken,
                    refreshToken: chatCredentials.refreshToken,
                    validUntilUtc: chatCredentials.validUntilUtc,
                    userId: chatCredentials.matrixUserId,
                    baseUrl: chatCredentials.baseUrl!
                })
            }
        } catch (e) {
            console.log(e)
            await this.disconnect()
            // Do not retry to prevent multiple error popup
            // if (InitializedStores.authStore.isAuthorized) {
            //     await new Promise(resolve => setTimeout(() => resolve(undefined), 5_000))
            //     await this.connect()
            // }
        }
    }
    private _connectToMatrix = async (options: {
        baseUrl: string,
        accessToken: string
        refreshToken: string
        validUntilUtc: string
        userId: string
    }) => {
        this.refreshToken = options.refreshToken;
        this.validUntilUtc = moment.utc(options.validUntilUtc);
        this.client = sdk.createClient({
            baseUrl: options.baseUrl,
            accessToken: options.accessToken,
            userId: options.userId,
            useAuthorizationHeader: true,
            forceTURN: false,
            timelineSupport: true
        });
        this.startRefreshTokenLoop()

        await this.client.on(ClientEvent.Sync, (state) => {
            this.waitSyncing = state !== 'SYNCING'
        })
        this.roomList = new RoomListMatrix(this.client)

        await this.client.startClient({
            initialSyncLimit: initialLoadEventsCount,
            filter: this.getDefaultFilter(options.userId)
        })
        await this.client.setPresence({presence: 'online'})
    }

    private getDefaultFilter = (ownUserId: string) => {
        const filter = new Filter(ownUserId)
        filter.setDefinition({room: {timeline: {types: [EventType.RoomMessage], limit: initialLoadEventsCount}}})
        return filter
    }

    private refreshTokenLoop?: any
    startRefreshTokenLoop = () => {
        if (!!this.refreshTokenLoop)
            return
        this.refreshTokenLoop = setInterval(async () => {
            if (this.client && this.refreshToken && this.validUntilUtc && moment.utc().add(1, 'minutes') >= this.validUntilUtc) {
                const nowUtc = moment.utc()
                const credentials = (await this.client.refreshToken(this.refreshToken))!
                this.client.setAccessToken(credentials.access_token)
                this.refreshToken = credentials.refresh_token
                this.validUntilUtc = nowUtc.add(credentials.expires_in_ms, 'milliseconds')
            }
        }, 10_000)
    }
    stopRefreshTokenLoop = () => {
        if (!!this.refreshTokenLoop)
            clearInterval(this.refreshTokenLoop)
    }

    disconnect = async () => {
        if (this.client) {
            await this.client.setPresence({presence: 'offline'})
            this.client.stopClient()
            await this.client.clearStores()
        }
        this.client = undefined
        this.roomList = undefined
        this.refreshToken = undefined
        return Promise.resolve(undefined);
    }

}

export class RoomListMatrix extends RoomList {
    constructor(client: MatrixClient) {
        super();
        this.client = client;
        this.onRoomDisposable = client.on(ClientEvent.Room, this.onRoom)
    }


    client: MatrixClient

    onRoomDisposable: any
    private onRoom = (room: sdk.Room) => {
        this._rooms.push(new RoomMatrix(room))
    }


    @observable private _activeRoom?: RoomMatrix
    get activeRoom(): RoomMatrix | undefined {
        return this._activeRoom;
    }

    selectRoom = (roomId: string | undefined) => {
        if (!!roomId)
            this._activeRoom = this.rooms.find(room => room.id === roomId)
        else
            this._activeRoom = undefined
        return Promise.resolve(undefined);
    }

    @observable private _rooms: RoomMatrix[] = []
    @computed get rooms(): RoomMatrix[] {
        return !!this._searchText?.length
            ? this._rooms.filter(room => !!room.name?.toUpperCase()?.includes(this._searchText!.toUpperCase()))
            : this._rooms
    }

    @observable private _searchText?: string
    search = (text: string | undefined) => {
        this._searchText = text
        return Promise.resolve(undefined);
    }
}

const directTag = 'direct'

export class RoomMatrix extends Room {
    constructor(room: sdk.Room) {
        super();
        this.room = room
        this.id = room.roomId
        this.unreadNotificationCount = room.getUnreadNotificationCount() ?? 0
        this.tags = Object.keys(room.tags).map(key => key)
        this.isDirect = this.tags.some(tag => tag === directTag)
            // getDMInviter не работает, если пользователь изменил displayName
            || room.getMembers().some(member => !!member.getDMInviter())
            || room.getMembers().length === 2
        if (this.isDirect) {
            const directMember = room.getMembers().find(member => member.userId !== room.myUserId)!
            const users = room.client.getUsers()
            this.directMember = new RoomMemberMatrix(directMember, users.find(user => user.userId === directMember.userId)!, room)
            this.name = directMember.name
            this.imageUrl = directMember.getMxcAvatarUrl() ?? undefined
        } else {
            this.name = room.name
            this.imageUrl = room.getMxcAvatarUrl() ?? undefined
        }

        this.syncTimeLineFromScratch()
        this.syncReceiptFromScratch()
        room.loadMembersIfNeeded().then(this.syncMembersFromScratch)
        room.on(RoomEvent.Timeline, this.syncTimeLine)
        room.on(RoomEvent.LocalEchoUpdated, this.syncTimeLine)
        room.on(RoomEvent.Receipt, this.syncReceipt)
    }

    room: sdk.Room

    id: string
    @observable name: string
    @observable unreadNotificationCount: number
    isDirect: boolean
    @observable imageUrl: string | undefined
    @observable tags: string[]
    @observable members: RoomMemberMatrix[] = []
    @observable directMember: RoomMember | undefined;
    @observable _messages: Message[] = []

    @computed get messages() {
        return this._messages
    }

    @computed get latestMessage() {
        return this.messages?.length ? this.messages[this.messages.length - 1] : undefined
    }

    @computed get firstLoadedMessage() {
        return this.messages?.length ? this.messages[0] : undefined
    }

    @computed get typingMembersExceptMe() {
        return this.members.filter(member => member.typing && !member.isMe)
    }

    @computed get onlineMembersExceptMe() {
        return this.members.filter(member => member.online && !member.isMe)
    }

    private typingTimeout = 30_000
    setTyping = async (isTyping: boolean) => {
        const member = this.members.find(member => member.id === this.room.myUserId)!
        if (member.typing !== isTyping) {
            member.typing = isTyping
            await this.room.client.sendTyping(this.id, isTyping, this.typingTimeout)
        }
    }
    sendMessage = async (text: string | undefined) => {
        await this.room.client.sendMessage(this.room.roomId, {msgtype: MsgType.Text, "body": text})
        this.setTyping(false).finally()
    }

    @observable private _messagesLoading = false

    @computed get messagesLoading() {
        return this._messagesLoading
    }

    loadLatestReadMessage = async () => {
        // const readMessageId = this.room.getReadReceiptForUserId(this.room.myUserId)?.eventId
        // if (!!readMessageId) {
        //
        //     let timeline = this.room.getTimelineForEvent(readMessageId)
        //     if (!timeline)
        //         timeline = (await this.room.client.getEventTimeline(this.room.getUnfilteredTimelineSet(), readMessageId))!
        //
        //     await this.room.client.paginateEventTimeline(timeline!, {backwards: true, limit: 10, /*initialLoadEventsCount*/})
        //     // if (timeline !== this.room.getLiveTimeline())
        //     //     await this.room.client.paginateEventTimeline(timeline!, {backwards: false, limit: initialLoadEventsCount})
        //     return readMessageId
        // }

        if (this.messagesLoading)
            return

        try {
            this._messagesLoading = true
            const readMessageId = this.room.getReadReceiptForUserId(this.room.myUserId)?.eventId
            if (!!readMessageId) {
                if (!this.room.getTimelineForEvent(readMessageId)) {
                    await this.room.client.paginateEventTimeline(
                        this.room.getLiveTimeline(),
                        {backwards: true, limit: this.room.getUnreadNotificationCount()! + initialLoadEventsCount}
                    )
                }
                return readMessageId
            }
        } finally {
            this._messagesLoading = false
        }
    }

    // TODO
    // loadNext = async (messageId: string) => {
    //     if (this.messagesLoading)
    //         return
    //     try {
    //         this._messagesLoading = true
    //
    //         const timeline = (await this.room.client.getEventTimeline(this.room.getUnfilteredTimelineSet(), messageId))!
    //         if (timeline !== this.room.getLiveTimeline())
    //             await this.room.client.paginateEventTimeline(timeline, {backwards: true, limit: nextLoadEventsCount})
    //     } finally {
    //         this._messagesLoading = false
    //     }
    // }
    loadPrevious = async () => {
        if (this.messagesLoading)
            return

        try {
            this._messagesLoading = true
            await this.room.client.scrollback(this.room, nextLoadEventsCount)

            // const tls = this.room.getUnfilteredTimelineSet();
            // const timeline = await this.room.client.getEventTimeline(tls, this.room.timeline[0].event.event_id!)
            // if (timeline) {
            //     await this.room.client.paginateEventTimeline(timeline, {backwards: true, limit: count})
            // }
        } finally {
            this._messagesLoading = false
        }
    }

    clearLoaded = () => {
        // TODO удалять из памяти лишнии сообщения
        // this._messages = this._messages.slice(-this.loadDefaultEventsCount)
    }

    public markReadUntilMessage = async (message: Message) => {
        const matrixEvent = this.room.findEventById(message.id)!
        await this.markReadUntilEvent(matrixEvent)
    }
    public markReadUntilLatestMessage = async () => {
        if (!!this.latestMessage) {
            let matrixEvent = this.room.timeline.find(event => event.event.event_id === this.latestMessage!.id)

            if (!!matrixEvent)
                await this.markReadUntilEvent(matrixEvent)
        }
    }
    private markReadUntilEvent = async (matrixEvent: sdk.MatrixEvent) => {
        if (matrixEvent.getSender() !== this.room.myUserId)
            await this.room.client.sendReadReceipt(matrixEvent)
    }

    private syncMembersFromScratch = () => {
        const users = this.room.client.getUsers()
        this.members = this.room.getMembers().map(member => new RoomMemberMatrix(member, users.find(user => user.userId === member.userId)!, this.room))
    }
    private syncTimeLineFromScratch = () => {
        this._messages = this.room.timeline
            .filter(matrixEvent => matrixEvent.event.type === "m.room.message")
            .map(matrixEvent => this.mapEventToMessage(matrixEvent))
            .sort((a, b) => a.dateTime.diff(b.dateTime))

        this.unreadNotificationCount = this.room.getUnreadNotificationCount() ?? 0
    }

    private syncTimeLine = (matrixEvent: sdk.MatrixEvent) => {
        // Пока сообщение не доставлено у него не будет реального event_id
        if (matrixEvent.status === null) {
            this.addToTimeLine(matrixEvent)
        }
    }
    private addToTimeLine = (matrixEvent: sdk.MatrixEvent) => {
        if (matrixEvent.event.type === "m.room.message") {
            let message = this.mapEventToMessage(matrixEvent)
            let _messages = this._messages.slice()
            let timeStatus: 'old' | 'new'
            let sort

            if (!this.latestMessage) {
                timeStatus = 'new'
            } else {
                timeStatus = message.dateTime < this.latestMessage.dateTime ? 'old' : 'new'
            }

            if (timeStatus === 'old') {
                _messages.unshift(message)
                sort = !!this.firstLoadedMessage && this.firstLoadedMessage.dateTime < message.dateTime
            } else {
                _messages.push(message)
                sort = !!this.latestMessage && this.latestMessage.dateTime > message.dateTime
            }

            if (sort) {
                console.warn('not sorted timeline event', timeStatus, message)
                this._messages = _messages.sort((a, b) => a.dateTime.diff(b.dateTime))
            } else {
                this._messages = _messages
            }

            this.unreadNotificationCount = this.room.getUnreadNotificationCount() ?? 0

            this.events.message.emit(message, timeStatus)
        }
    }

    private syncReceiptFromScratch = () => {
        this.unreadNotificationCount = this.room.getUnreadNotificationCount() ?? 0
    }
    private syncReceipt = this.syncReceiptFromScratch

    private mapEventToMessage = (matrixEvent: sdk.MatrixEvent) => {
        const event = matrixEvent.event
        const text = event!.content!.body
        return new Message(
            event.event_id!,
            event.sender!,
            text,
            event.sender === this.room.myUserId,
            new MessageFrom(event.sender!, this.room.getMember(event.sender!)!.rawDisplayName),
            moment.unix(event.origin_server_ts! / 1000))
    }
}

export class RoomMemberMatrix extends RoomMember {
    constructor(member: sdk.RoomMember, user: sdk.User, room: sdk.Room) {
        super()
        this.id = member.userId
        this.name = member.name
        this.imageUrl = member.getMxcAvatarUrl() ?? undefined
        this.typing = false
        this.isMe = room.myUserId === member.userId
        this.online = this.isOnline(user.presence, user.currentlyActive)
        this.room = room
        this.setLatestRead()
        member.on(RoomMemberEvent.Name, this.syncName)
        member.on(RoomMemberEvent.Typing, this.syncTyping)
        room.on(RoomEvent.Receipt, this.syncReceipt)
        user.setMaxListeners(user.getMaxListeners() + 1)
        user.on(UserEvent.Presence, this.syncPresence)
    }

    id: string
    @observable name: string
    @observable imageUrl: string | undefined
    @observable typing: boolean
    isMe: boolean
    room: sdk.Room
    @observable online: boolean = false
    @observable latestRead: {
        msgDateTime: moment.Moment,
        msgId: string
    } | undefined

    syncName = (matrixEvent: sdk.MatrixEvent, member: sdk.RoomMember) => {
        this.name = member.name
    }
    syncTyping = (matrixEvent: sdk.MatrixEvent, member: sdk.RoomMember) => {
        this.typing = member.typing
    }
    syncPresence = (matrixEvent: sdk.MatrixEvent | undefined, user: sdk.User) => {
        this.online = this.isOnline(user.presence, user.currentlyActive)
    }
    syncReceipt = (matrixEvent: sdk.MatrixEvent) => {
        this.setLatestRead()
    }

    /** TODO See bag https://redmine.actonica.ru/issues/34053 */
    private setLatestRead = async () => {
        const getLatestReadEvent = async () => {
            let readEvent: sdk.IEvent | undefined = undefined
            const latestRead = this.room.getReadReceiptForUserId(this.id)

            if (!!latestRead) {
                const readTimeLineSet = latestRead ? this.room.getTimelineForEvent(latestRead.eventId) : latestRead
                readEvent = readTimeLineSet ? readTimeLineSet!.getEvents()?.find(event => event.event.event_id === latestRead!.eventId)?.event as sdk.IEvent : undefined
                if (!readEvent) {
                    const encodeUri = (pathTemplate: string,
                                       variables: Record<string, string>) => {
                        for (const key in variables) {
                            if (!variables.hasOwnProperty(key)) {
                                continue;
                            }
                            pathTemplate = pathTemplate.replace(
                                key, encodeURIComponent(variables[key]),
                            );
                        }
                        return pathTemplate;
                    }
                    const path = encodeUri(
                        `/rooms/$roomId/context/$eventId`, {
                            $roomId: this.room.roomId,
                            $eventId: latestRead.eventId,
                        },
                    );
                    // @ts-ignore
                    let params: Record<string, string | string[]> = this.room.client.clientOpts.lazyLoadMembers
                        ? undefined
                        : {filter: JSON.stringify({lazy_load_members: true}), limit: 0};

                    // @ts-ignore
                    readEvent = (await this.room.client.http.authedRequest<IContextResponse>(undefined, sdk.Method.Get, path, params))?.event;
                }
            }

            return readEvent
        }
        const setLatestReadEvent = (readEvent: sdk.IEvent) => {
            this.latestRead = !!readEvent ? {
                msgDateTime: moment.unix(readEvent.origin_server_ts / 1000)!,
                msgId: readEvent.event_id
            } : undefined
        }

        const latestReadEvent = await getLatestReadEvent()
        if (latestReadEvent)
            setLatestReadEvent(latestReadEvent)
    }

    private isOnline = (presence: string, currentlyActive: boolean) => presence === 'online' && currentlyActive
}
