import React from "react";
import {DrivingCancellationModal, IDrivingCancellationTarget} from "./DrivingCancellationModal";
import {Button, Table} from "antd";
import {DrivingSessionDetailsScene} from "../../scenes/authorized/Sessions/DrivingSessionDetailsScene";
import {observable} from "mobx";
import {DrivingSessionDto} from "../../services/api/CoreApiClient";
import {observer} from "mobx-react";
import moment from "moment-timezone";
import {RouteComponentProps} from "react-router-dom";
import {ColumnsType} from "antd/lib/table/interface";
import {EnumFriendlyMapper} from "../../services/utils/EnumFriendlyMapper";

interface IDrivingTableProps extends RouteComponentProps {
    load: () => any;
    driving: DrivingSessionDto[]
    loading: boolean
    hideStudent?: boolean
    hideInstructor?: boolean
}

@observer
export class DrivingTable extends React.Component<IDrivingTableProps> {

    @observable drivingCancellationVisible = false
    @observable drivingCancellationTarget: IDrivingCancellationTarget | null = null
    showDrivingCancellation = (talon: DrivingSessionDto) => {
        this.drivingCancellationTarget = {
            id: talon.id!,
            dateTime: talon.dateTime!,
            optLockDateTime: talon.optLockDateTime!,
            concurrencyId: talon.concurrencyId!
        }
        this.drivingCancellationVisible = true
    }
    hideDrivingCancellation = () => {
        this.drivingCancellationVisible = false
        this.drivingCancellationTarget = null
    }
    hideDrivingCancellationAndReload = () => {
        this.hideDrivingCancellation()
        return this.props.load()
    }

    private toInstructorUserName = (a: DrivingSessionDto) => `${a.instructor?.name?.lastName} ${a.instructor?.name?.firstName} ${a.instructor?.name?.middleName}`
    private toStudentUserName = (a: DrivingSessionDto) => a.student ? `${a.student?.name?.lastName} ${a.student?.name?.firstName} ${a.student?.name?.middleName}` : "Не определен"

    render() {

        let columns: ColumnsType<DrivingSessionDto> = [
            {
                title: "Дата",
                dataIndex: 'dateTime',
                defaultSortOrder: 'descend',
                showSorterTooltip: false,
                sorter: (a, b) => (moment(a.dateTime).diff(moment(b.dateTime))),
                render: (_, record) => <div>{moment(record.dateTime).format('DD.MM.YY, HH:mm')}</div>
            },
            {
                title: "Статус",
                dataIndex: 'isCompleted',

                render: (_, record) =>
                    <div>{EnumFriendlyMapper.mapTalonStatusDetails(record.talonStatus!, record.cancellationReason!)}</div>
            }
        ]
        if (!this.props.hideStudent)
            columns.push({
                title: "Ученик",
                dataIndex: 'studentName',
                showSorterTooltip: false,
                render: (_, record) => !!this.props.hideStudent ? false : this.toStudentUserName(record),
                sorter: (a, b) => this.toStudentUserName(a).localeCompare(this.toStudentUserName(b)),
            })

        if (!this.props.hideInstructor)
            columns.push(
                {
                    title: "Инструктор",
                    dataIndex: 'instructorName',
                    showSorterTooltip: false,
                    render: (_, record) => !!this.props.hideInstructor ? false : this.toInstructorUserName(record),
                    sorter: (a, b) => this.toInstructorUserName(a).localeCompare(this.toInstructorUserName(b)),
                })

        columns.push(...[
            {
                title: "Адрес",
                dataIndex: 'placeName',
                showSorterTooltip: false,
                render: (_, record) => `${record.place?.address}`,
                sorter: (a, b) => (a.place?.address ?? '').localeCompare(b.place?.address ?? ''),
            },
            {
                title: "Действия",
                dataIndex: 'id',
                render: (_, record) => <React.Fragment>
                    <Button type={'link'}
                            onClick={_ => this.props.history.push(DrivingSessionDetailsScene.route.pathFormat(record.id!))}>К
                        деталям</Button>
                    <Button disabled={!record.isCancellationAllowed} type={'link'}
                            onClick={_ => this.showDrivingCancellation(record)}>Отменить</Button>
                </React.Fragment>
            }
        ] as ColumnsType<DrivingSessionDto>)

        const talons = <React.Fragment>
            <Table rowKey={'id'} loading={this.props.loading} dataSource={this.props.driving} columns={columns}/>
        </React.Fragment>


        return <React.Fragment>
            {this.drivingCancellationTarget && <DrivingCancellationModal visible={this.drivingCancellationVisible}
                                                                         closeModal={this.hideDrivingCancellation}
                                                                         onDrivingCanceled={this.hideDrivingCancellationAndReload}
                                                                         driving={this.drivingCancellationTarget}/>
            }
            {talons}
        </React.Fragment>;
    }
}
