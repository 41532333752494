import React from "react";
import {CallStatus} from "../../services/api/CoreApiClient";
import {Radio, Select} from "antd";
import {EnumFriendlyMapper} from "../../services/utils/EnumFriendlyMapper";

export interface ICallStatusSelectProps {
    value: CallStatus
    onChange: (status: CallStatus) => any
    mode: 'button' | 'select'
}

export class CallStatusSelect extends React.Component<ICallStatusSelectProps> {
    render() {
        if (this.props.mode === "button")
            return <Radio.Group value={this.props.value}
                                onChange={e => this.props.onChange(e.target.value as CallStatus)}>
                {Object.values(CallStatus).map(status => <Radio.Button key={status}
                    value={status}>{EnumFriendlyMapper.mapCallStatus(status)}</Radio.Button>)}
            </Radio.Group>;
        else
            return <Select style={{minWidth: 150}} value={this.props.value} onChange={this.props.onChange}>
            {Object.values(CallStatus).map(status => <Select.Option key={status} value={status}>{EnumFriendlyMapper.mapCallStatus(status)}</Select.Option>)}
        </Select>
    }
}
