import './AppLayout.less';
import * as React from 'react';
import {ReactNode} from 'react';
import {RouteComponentProps, Switch} from 'react-router-dom';

import {Layout} from 'antd';
import {inject, observer} from "mobx-react";
import SiderMenu from "../../../components/Layout/SiderMenu";
import {SpinOnCenter} from "../../../components/Loading/SpinOnCenter";
import {IMenuItemInfo} from "../../../routing/RouteSettings";
import {AuthStore} from "../../../stores/auth/authStore";
import {InjectNames} from "../../../stores/initializeStores";

const {Content} = Layout;

declare interface IProps extends RouteComponentProps {
    authStore?: AuthStore
    menuItems: IMenuItemInfo[];
    routes: ReactNode[];
    loading: boolean;
}

@inject(InjectNames.authStore)
@observer
class AppLayoutScene extends React.Component<IProps> {
    render() {
        const {history} = this.props;
        return (
            <Layout>
                {this.props.loading ? <SpinOnCenter/> :
                    <>
                        <SiderMenu layouts={this.props.menuItems}
                                   history={history}
                                   location={this.props.location}
                                   address={this.props.authStore!.userAddress}/>
                        <Layout>
                            <Content style={{margin: 16}}>
                                <Switch>
                                    {this.props.routes}
                                </Switch>
                            </Content>
                        </Layout>
                    </>
                }
            </Layout>
        );
    }
}

export default AppLayoutScene;
