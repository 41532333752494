import {observer} from "mobx-react";
import React from "react";
import {ActiveRoomHeader} from "./ActiveRoomHeader";
import {Room} from "./store/room";
import {Messages} from "./Messages";
import {MessageInput} from "./MessageInput";
import {Card, Empty, Row} from "antd";

export interface IActiveRoomsProps {
    room: Room | undefined
}

@observer
export class ActiveRoom extends React.Component<IActiveRoomsProps, any> {
    render() {
        const {room} = this.props
        return <Card type={'inner'} style={{height: '100%'}} bodyStyle={{height: '100%'}}>
            {!!room
                ?
                <div style={{height: '100%', display: "flex", flexDirection: 'column'}}>
                    <ActiveRoomHeader room={room}/>
                    <Messages room={room}/>
                    <MessageInput room={room}/>
                </div>
                :
                <Row justify={'center'} style={{alignItems: 'center', height: '100%'}}>
                    <Empty description={'Выберите чат, чтобы начать общатся'}/>
                </Row>
            }

        </Card>;
    }
}
