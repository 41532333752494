import React from "react";
import {coreClientInstance} from "../../../../services/api/coreClient";
import {Button, Col, Form, Modal, Row, Select} from "antd";
import {SessionCategory, StudentShotInfoDto} from "../../../../services/api/CoreApiClient";
import moment from "moment-timezone";
import {observable} from "mobx";
import {observer} from "mobx-react";
import {dateTimeHelper} from "../../../../services/utils/DateTimeHelper";

interface IProps {
    visible: boolean
    instructorUserId: string
    categoryId: string
    start: moment.Moment
    onCreated: () => Promise<any>
    onCanceled: () => any
}

@observer
export class DrivingCreationByInstructorWorkTimeComponent extends React.Component<IProps, any> {
    async componentDidMount() {
        await this.load()
    }

    @observable studentsLoading = false
    @observable students: StudentShotInfoDto[] = []
    load = async () => {
        try {
            this.studentsLoading = true
            this.students = (await coreClientInstance.schedule.getStudentsByInstructorWithAvailableTalonsList({
                instructorUserId: this.props.instructorUserId,
                categoryId: this.props.categoryId
            })).data
        } finally {
            this.studentsLoading = false
        }
    }

    @observable creating = false
    createDrivingSession = async (values: { studentUserId: string }) => {
        try {
            this.creating = true
            await coreClientInstance.schedule.chooseSessionDateCreate({
                category: SessionCategory.Practice,
                studentUserId: values.studentUserId,
                dateTime: dateTimeHelper.toBackendDateTime(moment(this.props.start))
            })
        } finally {
            this.creating = false
        }
        await this.props.onCreated()
    }

    render() {
        return <Modal visible={this.props.visible} title={'Создать занятие'} footer={false}
                      onCancel={this.props.onCanceled} closeIcon={<span/>}>
            <Form onFinish={this.createDrivingSession}>
                <Form.Item label={"Ученик"} name={"studentUserId"} labelCol={{span: 24}}
                           rules={[{required: true, message: 'Выберите ученика'}]}>
                    <Select showSearch={true} optionFilterProp={'children'} loading={this.studentsLoading}>
                        {this.students.map(student =>
                            <Select.Option key={student.studentUserId}
                                           value={student.studentUserId}>{student.name?.lastName} {student.name?.firstName} {student.name?.middleName} - {student.drivingCategory?.name}
                            </Select.Option>)
                        }
                    </Select>
                </Form.Item>
                <Row justify={'end'} gutter={15}>
                    <Col> <Button htmlType={'button'} onClick={this.props.onCanceled}>Отменить</Button></Col>
                    <Col><Button htmlType={'submit'} type={'primary'} loading={this.creating}>Сохранить</Button></Col>
                </Row>
            </Form>
        </Modal>;
    }
}
