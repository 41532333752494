import React from "react";
import {Button, Card, Col, DatePicker, Form, Row, Select} from "antd";
import {coreClientInstance} from "../../../services/api/coreClient";
import {InstructorSimpleDto, SessionCategory, StudentShotInfoDto} from "../../../services/api/CoreApiClient";
import moment from "moment-timezone";
import {computed, observable} from "mobx";
import {observer} from "mobx-react";
import {RouteComponentProps} from "react-router-dom";
import {DrivingSessionDetailsScene} from "./DrivingSessionDetailsScene";
import {ArrowLeftOutlined} from "@ant-design/icons";
import {SessionsScene} from "./SessionsScene";
import {dateTimeHelper} from "../../../services/utils/DateTimeHelper";
import 'moment/locale/ru';
import locale from 'antd/es/date-picker/locale/ru_RU';

@observer
export class CreateDrivingSessionScene extends React.Component<RouteComponentProps> {
    public static route = {
        path: '/create-driving',
        pathFormat: () => CreateDrivingSessionScene.route.path,
    }

    async componentDidMount() {
        try {
            this.instructorsLoading = true
            this.instructors = (await coreClientInstance.instructor.getSimpleInstructorsByFilterCreate({})).data
                .sort((a, b) => a.name!.lastName!.localeCompare(b.name!.lastName!))
        } finally {
            this.instructorsLoading = false
        }
    }

    @observable creationLoading = false

    create = async () => {
        let addedId: string
        try {
            this.creationLoading = true
            const data = (await coreClientInstance.schedule.chooseSessionDateCreate({
                category: SessionCategory.Practice,
                studentUserId: this.selectedStudentUserId!,
                dateTime: dateTimeHelper.toBackendDateTime(this.selectedTime!)
            })).data
            addedId = data.sessionId!
        } finally {
            this.creationLoading = false
        }
        this.unSelectInstructor()

        this.props.history.push(DrivingSessionDetailsScene.route.pathFormat(addedId))
    }

    selectInstructor = async (instructorUserId: string | null) => {
        this.unSelectInstructor()
        this.selectedInstructorUserId = instructorUserId
        if (!!instructorUserId) {
            try {
                this.studentsLoading = true
                this.selectedInstructorUserId = instructorUserId
                this.studentsByInstructor = (await coreClientInstance.schedule.getStudentsByInstructorWithAvailableTalonsList({instructorUserId: instructorUserId})).data
                    .sort((a, b) => a.name!.lastName!.localeCompare(b.name!.lastName!))
            } finally {
                this.studentsLoading = false
            }
        }
    }

    selectStudent = async (studentUserId: string | null) => {
        this.unSelectStudent()
        this.selectedStudentUserId = studentUserId
        if (!!this.selectedStudentUserId) {
            try {
                this.workTimeLoading = true
                const categoryId = this.selectedStudent!.drivingCategory!.id
                this.workTimeByCategory = (await coreClientInstance.schedule.getInstructorWorkTimeList({
                    drivingCategoryId: categoryId,
                    instructorUserId: this.selectedInstructor!.userId
                })).data.map(workTime => moment(workTime)).sort((a, b) => a.diff(b))
            } finally {
                this.workTimeLoading = false
            }
        }
    }
    selectDate = (dateTime: moment.Moment | null) => {
        this.unSelectDate()
        this.selectedDate = dateTime
    }
    selectTime = (time: moment.Moment | null) => {
        this.selectedTime = time
    }

    unSelectInstructor = () => {
        this.selectedInstructorUserId = null
        this.studentsByInstructor = []
        this.unSelectStudent()
    }
    unSelectStudent = () => {
        this.selectedStudentUserId = null
        this.workTimeByCategory = []
        this.unSelectDate()
    }
    unSelectDate = () => {
        this.selectedDate = null
        this.selectedTime = null
    }

    @observable instructorsLoading = false
    @observable instructors: InstructorSimpleDto[] = []
    @observable selectedInstructorUserId: string | null = null

    @computed get selectedInstructor() {
        return this.instructors.find(instructor => instructor.userId === this.selectedInstructorUserId)
    }

    @observable studentsLoading = false
    @observable studentsByInstructor: StudentShotInfoDto[] = []
    @observable selectedStudentUserId: string | null = null

    @computed get selectedStudent() {
        return this.studentsByInstructor.find(student => student.studentUserId === this.selectedStudentUserId)
    }

    @computed get selectedWorkTimes() {
        const current = this.selectedDate
        if (!current)
            return []
        return this.workTimeByCategory.filter(workTime => workTime.date() === current.date() && workTime.month() === current.month() && workTime.year() === current.year()) ?? []
    }

    @observable workTimeLoading = false
    @observable workTimeByCategory: moment.Moment[] = []
    @observable selectedDate: moment.Moment | null = null
    @observable selectedTime: moment.Moment | null = null

    render() {
        const layout = {
            labelCol: {span: 24}
        };

        const backButton = <Row style={{marginBottom: 30}}>
            <Button type={'text'}
                    icon={<ArrowLeftOutlined/>}
                    onClick={_ => this.props.history.push(SessionsScene.route.pathFormat())}>ЗАНЯТИЯ</Button>
        </Row>

        return <React.Fragment>
            {backButton}
            <Card title={"Новое занятие"}>
                <Row gutter={[15, 15]}>
                    <Col span={12}>
                        <Form.Item label={'Инструктор'} {...layout}>
                            <Select showSearch={true} optionFilterProp={'children'} onChange={this.selectInstructor}
                                    value={this.selectedInstructorUserId} loading={this.instructorsLoading}>
                                {this.instructors.map(instructor =>
                                    <Select.Option key={instructor.userId} value={instructor.userId}>
                                        {`${instructor.name?.lastName} ${instructor.name?.firstName} ${instructor.name?.middleName}`}
                                    </Select.Option>
                                )}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={'Ученик'} {...layout}>
                            <Select disabled={!this.selectedInstructorUserId} onChange={this.selectStudent}
                                    value={this.selectedStudentUserId} loading={this.studentsLoading}
                                    showSearch={true} optionFilterProp={'children'}>
                                {this.studentsByInstructor.map(student =>
                                    <Select.Option key={student.studentUserId} value={student.studentUserId}>
                                        {`${student.name?.lastName} ${student.name?.firstName} ${student.name?.middleName}`}
                                    </Select.Option>
                                )}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={'Дата'} {...layout}>
                            <DatePicker disabled={!this.selectedStudentUserId || this.workTimeLoading}
                                        onChange={this.selectDate} value={this.selectedDate}
                                        disabledDate={current => !this.workTimeByCategory.find(workTime => workTime.date() === current.date() && workTime.month() === current.month() && workTime.year() === current.year())}
                                        placeholder={"Выберите дату"} locale={locale}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={'Время'} {...layout}>
                            <Select disabled={!this.selectedDate}
                                    onChange={value => this.selectTime(moment(value))}
                                    value={dateTimeHelper.toDateTimeFormatOrNull(this.selectedTime)}
                                    placeholder={"Выберите время"}
                                    loading={this.workTimeLoading}>
                                {this.selectedWorkTimes.map(workTime =>
                                    <Select.Option key={dateTimeHelper.toDateTimeFormatOrNull(workTime)} value={dateTimeHelper.toDateTimeFormatOrNull(workTime)}>
                                        {dateTimeHelper.toTimeFormat(workTime)}
                                    </Select.Option>
                                )}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Button disabled={!this.selectedTime} loading={this.creationLoading} type={"primary"}
                        onClick={this.create}>Сохранить</Button>
            </Card>

        </React.Fragment>;
    }
}
