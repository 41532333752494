import React from "react";
import {IEventItemProps} from "./EventItem";
import {dateTimeHelper} from "../../services/utils/DateTimeHelper";
import {EventUserLink} from "./EventUserLink";

export class EventItemHeader extends React.Component<IEventItemProps> {
    render() {
        const time = ` ${dateTimeHelper.toTimeFormatFromString(this.props.historyItem!.initializedDateTime!)}`
        return <EventUserLink user={this.props.historyItem!.initializer!}>
            <span title={dateTimeHelper.toDateAndTimeFormatFromString(this.props.historyItem!.initializedDateTime!)}>
                {time}
            </span>
        </EventUserLink>
    }
}
