import './Messages.less'
import {observer} from "mobx-react";
import React from "react";
import {Room} from "./store/room";
import {Message} from "./store/message";
import {dateTimeHelper} from "../../services/utils/DateTimeHelper";
import {computed, observable} from "mobx";
import InfiniteScroll from 'react-infinite-scroll-component';
import {Divider, Spin} from "antd";
import {SpinOnCenter} from "../Loading/SpinOnCenter";
import {nextLoadEventsCount} from "./store/matrixChatStore";
import {EmptyData} from "../Common/EmptyData";

export interface IMessagesProps {
    room: Room
}

@observer
export class Messages extends React.Component<IMessagesProps, any> {

    async componentDidMount() {
        let latestReadId: string | undefined
        try {
            this.initialization = true
            if (this.props.room?.messages.length < nextLoadEventsCount)
                await this.loadPrevious()

            latestReadId = await this.props.room?.loadLatestReadMessage()
        } finally {
            this.initialization = false
        }

        if (!!latestReadId)
            this.scrollToMessage(latestReadId)

        this.props.room.events.message.on(this.handleMessage)
        await this.props.room.markReadUntilLatestMessage()
    }

    componentWillUnmount() {
        this.props.room.events.message.off(this.handleMessage)
        this.props.room.clearLoaded()
    }

    componentDidUpdate(prevProps: Readonly<IMessagesProps>, prevState: Readonly<any>, snapshot?: any) {
        if (prevProps.room.id !== this.props.room.id)
            this.scrollToBottom()
    }

    @observable initialization = true

    messagesBottomRef?: HTMLDivElement
    scrollToBottom = () => {
        setTimeout(() => this.messagesBottomRef?.scrollIntoView({behavior: 'auto'}), 100)
    }
    scrollToMessage = (messageId: string) => {
        setTimeout(() => {
            document.getElementById(messageId)?.scrollIntoView({behavior: 'auto'})
        }, 100)
    }

    handlingNewMessage = false
    handleMessage = (msg: Message, timeStatus: 'old' | 'new') => {
        if (timeStatus === 'new') {
            this.props.room.markReadUntilMessage(msg).then()

            if (!msg.fromMe) {

                if (!this.handlingNewMessage) {
                    this.handlingNewMessage = true
                    // const key = `msg_${msg.id}`
                    //
                    // notification.open({
                    //     style: {cursor: 'pointer'},
                    //     message: 'Прокрутить к вниз.',
                    //     description: 'Новое сообщение',
                    //     icon: false,
                    //     key,
                    //     onClick: () => {
                    //         notification.close(key)
                    //         this.handlingNewMessage = false
                    //         this.scrollToBottom()
                    //     },
                    //     onClose: () => this.handlingNewMessage = false,
                    //     duration: 3,
                    //     placement: 'topRight'
                    // });
                }
            } else {
                this.scrollToBottom()
            }
        }
    }

    @computed get hasMore() {
        return true
    }

    loadPrevious = async () => {
        await this.props.room.loadPrevious()
    }

    render() {
        const {room} = this.props

        const renderItem = (msg: Message, prevMsg: Message | undefined) => {
            const withoutSender = room.members
                .filter(member => member.id !== msg.senderId)
            const isRead = withoutSender
                .filter(member => member.latestRead && member.latestRead.msgDateTime >= msg.dateTime).length === withoutSender.length

            return <div key={`${msg.id}_${isRead}`}>
                {
                    (!prevMsg
                        || (!!prevMsg && msg.dateTime.local().dayOfYear() !== prevMsg.dateTime.local().dayOfYear())) &&
                    <Divider plain>{msg.dateTime.format('DD.MM.YYYY')}</Divider>
                }
                <div
                    className={`message-row ${msg.fromMe ? 'message-row-from-me' : 'message-row-from-other'}`}>
                    <div id={msg.id}
                         className={`message ${msg.fromMe ? 'message-from-me' : 'message-from-other'}`}
                    >
                        <div className={'info'}>
                            {(!room.isDirect && !msg.fromMe) && <div className={'from'}>{msg.from.name}</div>}
                            <div className={'text'}>{msg.text}</div>
                        </div>
                        <div className={'delivery'}>
                            <div className={'time'}
                                 title={dateTimeHelper.toDateAndTimeFormat(msg.dateTime)}>{dateTimeHelper.toTimeFormat(msg.dateTime)}</div>
                            {
                                msg.fromMe && <div className={'status'}>✓{isRead ? '✓' : ''}</div>
                            }
                        </div>

                    </div>
                </div>
            </div>
        }

        return <div className={'messages'} id={'messages-scroll'}>
            <InfiniteScroll
                dataLength={room.messages.length}
                next={this.loadPrevious}
                hasMore={true}
                loader={<span/>}
                scrollableTarget={'messages-scroll'}
                inverse={true}
                scrollThreshold={400}
            >
                {room.messagesLoading && <div style={{textAlign: 'center'}}><Spin/></div>}
                {this.initialization
                    ? <SpinOnCenter/>
                    : room.messages?.length
                        ? room.messages.map((message, index) => renderItem(message, room?.messages[index - 1]))
                        : <EmptyData/>}
                <div ref={ref => this.messagesBottomRef = ref!}/>
            </InfiniteScroll>
        </div>
    }
}

