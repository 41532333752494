import {action, computed, observable} from "mobx";
import {Api, AuthorizedUserDto, UserType} from "../../services/api/CoreApiClient";
import {RootStore} from "../initializeStores";
import {message} from "antd";
import { SignInType } from "../../services/api/CoreApiClient";

export class AuthStore {
    constructor(rootStore: RootStore, coreClient: Api<any>) {
        this.appClient = coreClient
        this.rootStore = rootStore
        this.token = localStorage.getItem(this.storageKeys.token);
    }

    private readonly appClient: Api<any>;
    private readonly rootStore: RootStore;
    private storageKeys = {
        token: "driving_school_token_key"
    }

    @computed get userAddress() {
        if (!this.authorizedUser)
            return null

        else if (!!this.authorizedUser.office?.officeName?.length)
            return this.authorizedUser.office.officeName
        else if (!!this.authorizedUser.branch?.branchName?.length)
            return this.authorizedUser.branch.branchName
        else
            return this.authorizedUser.company?.companyName
    }

    @observable authorizedUser: AuthorizedUserDto | undefined = undefined
    @observable loginType: SignInType | undefined = undefined
    @observable private attemptId: string | null = null
    @observable private token: string | null = null
    @observable private _signingIn = false

    @computed get authStep() {
        return !!this.attemptId ? 'otp' : 'phone'
    }

    @computed get signingIn() {
        return this._signingIn
    }

    @computed get getToken() {
        return this.token?.toString()
    }

    @computed get isAuthorized() {
        return !!this.token
    }

    @action reStartAuth = () => {
        this.attemptId = null
    }

    @action phoneVerification = async (input: { phone: string }) => {
        try {
            this._signingIn = true
            const response = await this.appClient.auth.phoneVerificationCreate({
                phone: input.phone,
            })
            
            this.attemptId = response.data.attemptId!
            this.loginType = response.data.signInType
            console.log(this.attemptId)
        } finally {
            this._signingIn = false
        }
    }

    @action otpVerification = async (input: { accessCode: string }) => {

        this._signingIn = true
        let success = false

        try {
            console.log(input.accessCode, this.attemptId)

            const response = await this.appClient.auth.otpVerificationCreate({
                accessCode: input.accessCode,
                attemptId: this.attemptId!
            })

            if (!!response.data.token!.accessToken) {
                this.token = response.data.token!.accessToken!
                localStorage.setItem(this.storageKeys.token, this.token);
                this.attemptId = null
                success = true
            }
        } catch (e: any) {
            console.error(e)
            success = false
        }

        this._signingIn = false
        return success

    }
    @action loadAuthorizedUser = async () => {
        this.authorizedUser = (await this.appClient.auth.getAuthorizedUserList()).data

        // @ts-ignore
        const checkRole = window.$ENV.DEV?.ALLOW_ANY_ROLE_LOGIN !== true
        if (checkRole && this.authorizedUser!.userType !== UserType.Manager) {
            await this.signOut()
            message.error({content: 'Вход разрешен только менеджеру.'})
        }
    }

    @action signOut = async () => {
        localStorage.removeItem(this.storageKeys.token)
        this.token = null
    }
}
