import React from "react";
import {coreClientInstance} from "../../../services/api/coreClient";
import {RouteComponentProps} from "react-router-dom";
import {computed, observable} from "mobx";
import {
    BaseEventDto,
    DrivingSessionDto,
    InstructorInfoDto,
    StudentDetailsDto
} from "../../../services/api/CoreApiClient";
import {observer} from "mobx-react";
import {Avatar, Button, Card, Col, Form, Modal, Row, RowProps, Select, Tabs} from "antd";
import {
    ArrowLeftOutlined,
    AuditOutlined,
    FieldNumberOutlined,
    FlagOutlined,
    MailOutlined,
    PhoneOutlined,
    TeamOutlined
} from "@ant-design/icons";
import {ImageUtils} from "../../../services/utils/ImageUtils";
import Title from "antd/lib/typography/Title";
import {DrivingTable} from "../../../components/DrivingSession/DrivingTable";
import {StudentsScene} from "./StudentsScene";
import {Events} from "../../../components/History/Events";
import Icon, { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";
import { StudentSchedule } from "../../../components/DrivingSession/StudentSchedule";
import { UserNotifications } from "../../../components/Notifications/UserNotifications";

interface IRouteProps {
    userId: string
}

interface IStudentDetailsSceneProps extends RouteComponentProps<IRouteProps> {

}

const unusedCellphoneSvg = () => {
    return <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 width="16px" height="16px" viewBox="0 0 217.267 217.267">
        <g>
            <path d="M188.246,182.443c17.994-19.397,29.021-45.334,29.021-73.81C217.267,48.733,168.534,0,108.633,0
                C76.947,0,48.398,13.643,28.522,35.351c-0.074,0.067-0.166,0.094-0.228,0.164c-0.067,0.07-0.082,0.158-0.14,0.231
                C10.667,55.034,0,80.613,0,108.633c0,59.898,48.73,108.633,108.633,108.633c31.241,0,59.426-13.274,79.261-34.458
                c0.072-0.064,0.161-0.092,0.223-0.16C188.178,182.586,188.194,182.504,188.246,182.443z M108.633,7.009
                c56.034,0,101.625,45.586,101.625,101.625c0,25.475-9.445,48.772-24.989,66.626l-37.832-34.827V45.113
                c0-1.934-1.566-3.504-3.504-3.504h-7.628V30.658c0-1.934-1.567-3.504-3.504-3.504c-1.94,0-3.505,1.571-3.505,3.504v10.951H73.339
                c-1.939,0-3.504,1.571-3.504,3.504v23.876L35.902,37.752C54.373,18.807,80.144,7.009,108.633,7.009z M76.843,84.974l63.589,58.538
                v25.132H76.843V84.974z M76.843,75.443V48.617h63.589v85.361L76.843,75.443z M108.633,210.257
                c-56.034,0-101.624-45.59-101.624-101.624c0-25.031,9.115-47.96,24.172-65.688l38.648,35.579v93.625
                c0,1.934,1.567,3.504,3.504,3.504h70.595c1.941,0,3.505-1.57,3.505-3.504v-22.187l33.078,30.447
                C162.101,198.834,136.678,210.257,108.633,210.257z"/>
        </g>
    </svg>
}

const UnusedCellphoneIcon = (props: Partial<CustomIconComponentProps>) => {
    return <Icon component={unusedCellphoneSvg} {...props} />
}

@observer
export class StudentDetailsScene extends React.Component<IStudentDetailsSceneProps> {
    public static route = {
        path: '/student-details/:userId',
        pathFormat: (userId: string) => `/student-details/${userId}`
    }

    getUserId = () => {
        return this.props.match.params.userId;
    }

    async componentDidMount() {
        await this.load()
    }

    async componentDidUpdate(prevProps: Readonly<IStudentDetailsSceneProps>, prevState: Readonly<{}>, snapshot?: any) {
        if (prevProps.match.params.userId !== this.getUserId())
            await this.load()
    }

    load = async () => {
        let promise: Promise<any>[] = [
            this.loadDetails(),
            this.loadAvailableInstructors()
        ]
        switch (this.tabKey) {
            case "driving":
                promise.push(this.loadDriving())
                break;
            case "history":
                promise.push(this.loadHistory())
                break;
        }

        await Promise.all(promise)
    }

    @observable student: StudentDetailsDto | null = null
    @observable detailsLoading = false
    loadDetails = async () => {
        try {
            this.detailsLoading = true
            this.student = null
            this.student = (await coreClientInstance.student.getStudentDetailsByUserIdList({studentUserId: this.getUserId()})).data
            this.selectInstructor(this.student!.instructorUserId)
        } finally {
            this.detailsLoading = false
        }
    }

    @observable availableInstructors: InstructorInfoDto[] = []
    @observable availableInstructorsLoading = false
    loadAvailableInstructors = async () => {
        try {
            this.availableInstructorsLoading = true
            this.availableInstructors = []
            this.availableInstructors = (await coreClientInstance.instructor.getInstructorsAvailableToChangeByStudentList({studentUserId: this.getUserId()})).data
                .sort((a, b) => a.name!.lastName!.localeCompare(b.name!.lastName!))
        } finally {
            this.availableInstructorsLoading = false
        }
    }

    @observable schedule: DrivingSessionDto[] = []
    @observable scheduleLoading = false
    loadDriving = async () => {
        try {
            this.scheduleLoading = true
            this.schedule = []
            this.schedule = (await coreClientInstance.schedule.getDrivingSessionsByStudentUserIdList({studentUserId: this.getUserId()})).data
        } finally {
            this.scheduleLoading = false
        }
    }


    @observable history: BaseEventDto[] = []
    @observable historyLoading = false
    loadHistory = async () => {
        try {
            this.historyLoading = true
            this.history = []
            this.history = (await coreClientInstance.events.getEventsByUserIdList({userId: this.getUserId()})).data
        } finally {
            this.historyLoading = false
        }
    }

    @observable tabKey: TabKeyType = 'driving'
    setTab = async (tabKey: TabKeyType) => {
        this.tabKey = tabKey
        await this.load()
    }


    @computed get userName() {
        if (!this.student)
            return undefined
        return `${this.student.name!.lastName} ${this.student.name!.firstName} ${this.student.name!.middleName}`
    }

    @observable instructorChanging = false
    @observable selectedInstructor?: string = undefined
    selectInstructor = (instructorUserId: string | undefined) => {
        this.selectedInstructor = instructorUserId
    }

    @computed get instructorChanged() {
        return this.selectedInstructor !== this.student!.instructorUserId
    }

    changeInstructor = async () => {
        Modal.confirm({
            title: 'Сменить инструктора?', okText: 'Сменить', cancelText: "Нет", onOk: async () => {
                try {
                    this.instructorChanging = true
                    await coreClientInstance.student.changeInstructorCreate({
                        instructorUserId: this.selectedInstructor,
                        studentUserId: this.student!.studentUserId
                    })
                    await this.load()
                } finally {
                    this.instructorChanging = false
                }
            }
        })
    }

    render() {
        const backButton = <Row style={{marginBottom: 30}}>
            <Button type={'text'}
                    icon={<ArrowLeftOutlined/>}
                    onClick={_ => this.props.history.push(StudentsScene.route.pathFormat())}>УЧЕНИКИ</Button>
        </Row>

        const infoRowProps: RowProps = {gutter: [30, 30]}
        const infoCollProps: any = {style: {width: 300}}

        const details = !this.student ? <Card loading={this.detailsLoading}/> : <React.Fragment>
            <Row gutter={15}>
                <Col span={24}><Title level={3}>{this.userName}</Title></Col>
                <Col>
                    <Avatar src={ImageUtils.UrlOrImageNotFountUrl(this.student.imageUrl)}
                            alt={ImageUtils.ImageNotFountUrl} size={190} shape={'square'}/>
                </Col>
                <Col>
                    <Row {...infoRowProps}>
                        <Col {...infoCollProps}><PhoneOutlined/> +7{this.student!.studentPhone}</Col>
                        <Col {...infoCollProps}><AuditOutlined/> {this.student!.drivingCategory?.name}</Col>
                    </Row>

                    <Row {...infoRowProps}>
                        <Col {...infoCollProps}><MailOutlined/> {this.student!.studentEmail}</Col>
                        <Col {...infoCollProps}><TeamOutlined/> {this.student!.group?.groupName}</Col>
                    </Row>

                    <Row {...infoRowProps}>
                        <Col {...infoCollProps}><FlagOutlined/> {this.student!.studentStatus!.name}
                        </Col>
                        <Col {...infoCollProps}><FieldNumberOutlined/> {this.student?.scheduledTalonsCount}/{this.student!.purchasedTalonsCount}
                        </Col>
                    </Row>
                    
                    {this.student.lastActivityTime === null ?
                     <Row>
                        <Col {...infoCollProps}><UnusedCellphoneIcon/> Мобильное приложение не установлено</Col>
                    </Row>
                    : null}
                    <Form.Item style={{marginBottom:'12px'}} label={<span>Инструктор</span>}>
                        <Row gutter={15}>
                            <Col span={12}>
                                <Select value={this.selectedInstructor}
                                        showSearch={true} optionFilterProp={'children'}
                                        onChange={this.selectInstructor}
                                        allowClear={true} style={{width: '100%'}}>
                                    {this.availableInstructors.map(instructor => <Select.Option
                                        key={instructor.userId!}>{`${instructor.name?.lastName} ${instructor.name?.firstName} ${instructor.name?.middleName}`}</Select.Option>)}
                                </Select>
                            </Col>
                            <Col span={12}>
                                <Button disabled={!this.instructorChanged} loading={this.instructorChanging}
                                        onClick={this.changeInstructor}>Изменить</Button>
                            </Col>
                        </Row>
                    </Form.Item>
                    <Row>Детализация талонов:</Row>
                    <Row {...infoRowProps}>
                        <Col>
                            <Row {...infoRowProps}>
                                <Col>Доступно: {this.student.talonDetails?.free}</Col>
                                <Col>Откатано: {this.student.talonDetails?.used}</Col>
                                <Col>Запланировано: {this.student.talonDetails?.planned}</Col>
                                {this.student.talonDetails?.preExam !== null ? 
                                    <Col>Пред экзаменационные: {this.student.talonDetails?.preExam}</Col>
                                    : ""
                                }
                                {this.student.talonDetails?.extra !== null ?
                                    <Col>Дополнительные: {this.student.talonDetails?.extra}</Col>
                                    : ""
                                }
                            </Row>
                            <Row {...infoRowProps}>
                                <Col>Сгорело: {this.student.talonDetails?.burnt}</Col>
                                <Col>По пакету: {this.student.talonDetails?.paid}</Col>
                                <Col>Экзаменационные: {this.student.talonDetails?.exam}</Col>
                                {this.student.talonDetails?.postExam !== null ? 
                                    <Col>Пост экзаменационные: {this.student.talonDetails?.postExam}</Col>
                                    : ""
                                }
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </React.Fragment>

        const talons = <React.Fragment>
            <DrivingTable {...this.props} driving={this.schedule} load={this.load} loading={this.scheduleLoading}
                          hideStudent={true}/>
        </React.Fragment>

        const history = <Events {...this.props} historyList={this.history} loading={this.historyLoading} showDateDivider={true}
                                eventItemOptions={{drivingOptions: {hideStudentDetails: true, hideInstructorDetails: true}}}
                                eventTypeTabs={{showType: 'loaded'}}/>

        return <React.Fragment>
            {backButton}
            <Row gutter={[30, 30]}>
                <Col span={24}>
                    {details}
                </Col>
                <Col span={24}>
                    <Tabs type={'card'} activeKey={this.tabKey} onChange={value => this.setTab(value as TabKeyType)}>
                        <Tabs.TabPane key={'driving'} tab={'Занятия'}>
                            {talons}
                        </Tabs.TabPane>
                        <Tabs.TabPane key={'schedule'} tab={'Расписание'}>
                            <StudentSchedule {... this.props} studentUserId={this.getUserId()} />
                        </Tabs.TabPane>
                        <Tabs.TabPane key={'history'} tab={'История действий'}>
                            {history}
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={'Уведомления'} key={'notifications'}>
                            <UserNotifications userId={this.getUserId()} />
                        </Tabs.TabPane>
                    </Tabs>
                </Col>
            </Row>
        </React.Fragment>;
    }
}

type TabKeyType = 'driving' | 'history'
