import {AuthorizedUserDto, OfficeDto} from "../../services/api/CoreApiClient";
import {inject, observer} from "mobx-react";
import React from "react";
import {computed} from "mobx";
import {Select} from "antd";
import { CompanyUnitStore } from "../../stores/companyUnitStore";
import { InjectNames } from "../../stores/initializeStores";

interface IOfficeFilterProps {
    companyUnitStore?: CompanyUnitStore
    authorizedUser?: AuthorizedUserDto
    onChange: (officeFilter: OfficeDto | undefined) => any
}

@inject((InjectNames.companyUnitStore))
@observer
export class OfficeFilter extends React.Component<IOfficeFilterProps> {
    @computed get officeFilterJson() {
        return !!this.props.companyUnitStore?.officeFilter ? JSON.stringify(this.props.companyUnitStore?.officeFilter) : undefined
    }

    setOfficeFilterJson = (value: string | undefined) => {
        if (!!value?.length)
            return this.props.onChange(JSON.parse(value))
        else
            return this.props.onChange(undefined)
    }

    formatName = (office: OfficeDto) => {
        if (!!this.props.authorizedUser?.hasMultiplyBranches)
            return `${office.branchName}. ${office.officeName}`
        else
            return office.officeName

    }

    render() {
        return <Select value={this.officeFilterJson}
                       onChange={value => this.setOfficeFilterJson(value as string | undefined)} showSearch={true}
                       allowClear={false} style={{minWidth: 200}}>
            {this.props.companyUnitStore!.sortedOffices
                ?.map(office => <Select.Option
                    key={`${office.companyId}, ${office.branchId}, ${office.officeId}`}
                    value={JSON.stringify(office)}>{this.formatName(office)}</Select.Option>)}
        </Select>;
    }
}
