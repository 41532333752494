import React from "react";
import {Button, Col, DatePicker, Form, Modal, Row, Select, Spin} from "antd";
import {ExclusionReason} from "../../../../services/api/CoreApiClient";
import {DisabledTimes, EventValue, RangeValue} from "rc-picker/lib/interface";
import moment from "moment-timezone";
import {coreClientInstance} from "../../../../services/api/coreClient";
import {EnumFriendlyMapper} from "../../../../services/utils/EnumFriendlyMapper";
import {observable} from "mobx";
import {observer} from "mobx-react";
import {RangeType} from "rc-picker/lib/RangePicker";
import {FormInstance} from "antd/lib/form/hooks/useForm";
import {dateTimeHelper} from "../../../../services/utils/DateTimeHelper";

interface IProps {
    instructorUserId: string
    onCreated: () => Promise<any>
}

@observer
export class AddWorkTimeExclusionButtonComponent extends React.Component<IProps> {
    @observable visible = false
    @observable creating = false

    show = async () => {
        this.visible = true
        await this.loadAvailableTimes()
    }
    hide = () => {
        this.visible = false
    }
    cancel = () => {
        this.hide()
    }
    create = async (values: { reason: ExclusionReason, dateRange: RangeValue<moment.Moment> }) => {
        try {
            this.creating = true
            const dateRange = values.dateRange
            await coreClientInstance.schedule.addWorkTimeExclusionCreate({
                instructorUserId: this.props.instructorUserId,
                reason: values.reason,
                from: dateTimeHelper.toBackendDateTime(dateRange![0]!.set('seconds', 0).set('milliseconds', 0)),
                to: dateTimeHelper.toBackendDateTime(dateRange![1]!.set('seconds', 0).set('milliseconds', 0)),
            })
        } finally {
            this.creating = false
        }
        this.hide()
        await this.props.onCreated()
    }

    @observable starts: moment.Moment[] = []
    @observable ends: moment.Moment[] = []

    // defaultPickerValue:[moment.Moment, moment.Moment] = [moment().startOf('day'), moment().startOf('day')]
    disabledDates = (current: moment.Moment) =>
        // false
        !this.starts.find(workTime => workTime.date() === current.date() && workTime.month() === current.month() && workTime.year() === current.year())

    _times = {
        hours: Array(24).fill(0).map((_, i) => i),
        minutes: Array(60).fill(0).map((_, i) => i),
        seconds: Array(60).fill(0).map((_, i) => i)
    }
    disabledTimes: (date: EventValue<moment.Moment>, type: RangeType) => DisabledTimes = (date: EventValue<moment.Moment>, type: RangeType) => {
        const source = (type === 'start' ? this.starts : this.ends).filter(item => item.year() === date?.year() && item.month() === date?.month() && item.date() === date?.date())
        const disabledHours = this._times.hours.filter(current => !source.find(time => time.hours() === current))
        return {
            disabledHours: () => disabledHours,
            disabledMinutes: (hour: number) => this._times.minutes.filter(current => !source.find(time => time.hours() === hour && time.minutes() === current)),
            disabledSeconds: () => this._times.seconds,
        }
    }

    formRef: FormInstance | null = null
    @observable loading = false
    loadAvailableTimes = async () => {
        try {
            this.loading = true
            const availableTimes = (await coreClientInstance.schedule.getAvailableExclusionTimesList({instructorUserId: this.props.instructorUserId})).data

            if (!!availableTimes?.fromList?.length) {
                this.starts = availableTimes.fromList.map(time => moment(time))
            }

            if (!!availableTimes?.toList?.length) {
                this.ends = availableTimes.toList.map(time => moment(time))
            }
        } catch (e) {
            console.error(e)
            this.hide()
        } finally {
            this.loading = false
        }
    }

    render() {
        return <React.Fragment>
            <Modal key={`${this.visible}`} footer={false} visible={this.visible} onCancel={this.cancel}
                   closeIcon={<span/>} title={'Исключение из расписания'}>
                {this.loading
                    ? <div style={{display: 'flex', justifyContent: 'center'}}><Spin/></div>
                    : <Form onFinish={this.create}>
                        <Form.Item name={'dateRange'} rules={[{
                            validator: (rule: any, value: [moment.Moment, moment.Moment] | null | undefined, callback: (msg?: string) => void) => {
                                let errors = []
                                if (!value?.length) {
                                    errors.push('Это поле обязательное');
                                } else {
                                    const start = value[0]
                                    const end = value[1]
                                    if (start.year() === end.year() && start.month() === end.month() && start.date() === end.date() && start.hours() === end.hours() && start.minutes() === end.minutes()) {
                                        errors.push('Начало и конец должны отличаться.')
                                    } else {
                                        const start = value[0]
                                        if (!this.starts.find(item => item.year() === start?.year() && item.month() === start?.month() && item.date() === start?.date() && item.hours() === start.hours() && item.minutes() === start.minutes())) {
                                            errors.push('Время начала нет в списке доступных.')
                                        }

                                        const end = value[1]
                                        if (!this.ends.find(item => item.year() === end?.year() && item.month() === end?.month() && item.date() === end?.date() && item.hours() === end.hours() && item.minutes() === end.minutes())) {
                                            errors.push('Время окончания нет в списке доступных.')
                                        }
                                    }
                                }
                                return !!errors.length ? Promise.reject(errors.map(msg => new Error(msg))) : Promise.resolve();
                            }
                        }]}
                                   initialValue={[moment().set({
                                       minutes: 0,
                                       seconds: 0,
                                       millisecond: 0
                                   }), moment().set({minutes: 0, seconds: 0, millisecond: 0})]}>
                            <DatePicker.RangePicker showTime={true} minuteStep={5} showSecond={false}
                                                    hideDisabledOptions={true}
                                                    placeholder={['Начало', 'Конец']} format={'DD.MM.YYYY HH:mm'}
                                                    disabledDate={this.disabledDates}
                                                    disabledTime={this.disabledTimes}
                            />
                        </Form.Item>
                        <Form.Item name={'reason'} rules={[{required: true, message: 'Это поле обязательное'}]}
                                   initialValue={ExclusionReason.CarAccident}>
                            <Select showSearch={true} optionFilterProp={'children'}>
                                {Object.values(ExclusionReason).map(reason => <Select.Option key={reason}
                                                                                             value={reason}>{EnumFriendlyMapper.mapExclusionReason(reason)}</Select.Option>)}
                            </Select>
                        </Form.Item>
                        <Row gutter={15} justify={'end'}>
                            <Col> <Button htmlType={'button'} onClick={this.cancel}>Отменить</Button></Col>
                            <Col> <Button htmlType={'submit'} type={'primary'}
                                          loading={this.creating}>Создать</Button></Col>
                        </Row>
                    </Form>
                }
            </Modal>
            <Button type={'primary'} onClick={this.show}>Добавить исключение</Button>
        </React.Fragment>;
    }
}
