import { Button, Col, Modal, Row } from 'antd';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'moment-timezone';
import React from 'react';
import { coreClientInstance } from '../../../../services/api/coreClient';

interface IConfirmSessionProps{
    visible: boolean;
    studentId: string;
    sessionId: string;
    entryId: string;
    bookedTime: string;
    onCanceled: () => any;
    onConfirmed: () => any;
    onResetTime: () => any;
}

@observer
export default class SessionConfirmTimeOrResetModal extends React.Component<IConfirmSessionProps> {

    @observable load: boolean = false;

    handleConfirm = async () => {
        try { 
            this.load = true;
            await coreClientInstance.schedule.setExamEntryCreate({
                entryId: this.props.entryId,
                examId: this.props.sessionId
            }, {studentId: this.props.studentId})
        }
        finally {
            this.load = false;
            this.props.onConfirmed();
        }
    }

    render() {
        return(
            <Modal visible={this.props.visible} title={'Подтвердите выбранное менеджером время'} footer={false}
                    onCancel={this.props.onCanceled} closeIcon={<span/>}>
                <Row>Вы действительно хотите подтвердить присутствие ученика на установленное менеджером время?</Row>
                <Row>Выбранное время: {moment(this.props.bookedTime).format("DD.MM.YYYY HH:mm")}</Row>
                <Row justify={'end'} gutter={15} style={{marginTop:5}}>
                    <Col><Button htmlType={'button'} onClick={this.props.onCanceled}>Отменить</Button></Col>
                    <Col><Button htmlType={'button'} onClick={this.props.onResetTime}>Отказаться от времени</Button></Col>
                    <Col><Button htmlType={'submit'} type={'primary'} loading={this.load} onClick={() => this.handleConfirm()}>Подтвердить</Button></Col>
                </Row>
            </Modal>
        )
    }
}