import React from 'react';
import { Button, Col, Form, Row, Select, Modal } from 'antd';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { ExamEntryDto } from '../../../../services/api/CoreApiClient';
import { coreClientInstance } from '../../../../services/api/coreClient';
import moment from 'moment-timezone';

interface ISetBookingProps{
    visible: boolean;
    sessionId: string;
    studentId: string;
    onCanceled: () => any;
    onConfirmed: () => any;
}

@observer
export default class SessionSetBookingModal extends React.Component<ISetBookingProps, any> {

    async componentDidMount() {
        this.studentLoad = true;
        const result = (await coreClientInstance.schedule.getExamEntriesList({sessionId: this.props.sessionId})).data
        this.examEntries = result.filter(entry => entry.bookingCount? entry.bookingCount > 0 : true)
        this.studentLoad = false;
    }

    @observable examEntries: ExamEntryDto[] = []
    @observable load: boolean = false;
    @observable studentLoad: boolean = false;

    handleConfirm = async (values: {entryId: any}) => {
        try{
            this.load = true;
            await coreClientInstance.schedule.setExamEntryCreate({
                entryId: values.entryId, 
                examId: this.props.sessionId
            }, {
                studentId: this.props.studentId
            });
        }
        finally{
            this.load = false
            this.props.onConfirmed();
        }
    }

    render() {
        
        return (
            <Modal visible={this.props.visible} title={'Выбор времени сдачи экзамена'} footer={false}
                    onCancel={this.props.onCanceled} closeIcon={<span/>}>
                <Form onFinish={this.handleConfirm}>
                    {this.examEntries.length < 1 ? "Свободные места закончились" : ""}
                    <Form.Item label={"Список доступных записей"} name={"entryId"} labelCol={{span: 24}}
                            rules={[{required: true, message: 'Выберите время'}]}>
                        <Select showSearch={true} optionFilterProp={'children'} loading={this.studentLoad} disabled={this.examEntries.length < 1}>
                            {this.examEntries.map(entry =>
                                <Select.Option key={ String(entry.id) + entry.startTime + entry.bookingCount} value={entry.id}>
                                    Время начала: {moment(entry.startTime).format("DD.MM.YYYY HH:mm")}, количество свободных мест {entry.bookingCount} 
                                </Select.Option>)
                            }
                        </Select>                        
                    </Form.Item>
                    
                    <Row justify={'end'} gutter={15}>
                        <Col><Button htmlType={'button'} onClick={this.props.onCanceled}>Отменить</Button></Col>
                        <Col><Button htmlType={'submit'} type={'primary'} loading={this.load}>Подтвердить</Button></Col>
                    </Row>
                </Form>
            </Modal>
        )
    }
}