import {observer} from "mobx-react";
import React from "react";
import {computed, observable} from "mobx";
import {Avatar, Badge, Col, Modal, Row} from "antd";
import {Room} from "./store/room";
import {ImageUtils} from "../../services/utils/ImageUtils";

export interface IRoomMembersListProps {
    room: Room
    trigger: any
}

@observer
export class RoomMembersList extends React.Component<IRoomMembersListProps, any> {
    @observable visible = false

    @computed get members(){
        return this.props.room.members.slice().sort((a, b) => a.name.localeCompare(b.name))
    }

    render() {
        return <>
            <Modal visible={this.visible} title={'Список участников'}
                   onCancel={() => this.visible = false}
                   onOk={() => this.visible = false}
                   footer={false}>
                <Row gutter={[15, 15]}>
                    {this.members.map(member => <Col span={24} key={member.id} >
                        <Row gutter={15}>
                            <Col>
                                <Avatar src={ImageUtils.UrlOrImageNotFountUrl(member.imageUrl)}
                                        alt={ImageUtils.ImageNotFountUrl}/>
                            </Col>
                            <Col>
                                <Row gutter={[15, 15]}>
                                    <Col span={24}>
                                        <Badge dot={member.online} status={'success'}>{member.name ?? member.id}</Badge>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>)}
                </Row>
            </Modal>
            {<div onClick={() => this.visible = true}>{this.props.trigger}</div>}
        </>;
    }
}
