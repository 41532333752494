import moment from "moment-timezone";

export abstract class RoomMember {
    abstract id: string
    abstract name: string
    abstract imageUrl: string | undefined
    abstract typing: boolean
    abstract isMe: boolean
    abstract online: boolean
    abstract latestRead: {
        msgDateTime: moment.Moment,
        msgId: string
    } | undefined
}