import * as React from 'react';
import {inject, observer} from "mobx-react";
import {Button, Card, Col, Form, Image, Input, message, Row} from 'antd';
import {observable} from "mobx";
import {RouteComponentProps} from "react-router-dom";
import {InjectNames} from "../../../stores/initializeStores";
import {AuthStore} from "../../../stores/auth/authStore";
import MaskedInput from "antd-mask-input";
import {FormInstance} from "antd/lib/form/hooks/useForm";


declare interface IProps extends RouteComponentProps {
    authStore?: AuthStore;
}


@inject(InjectNames.authStore)
@observer
export class LoginScene extends React.Component<IProps> {
    public static route = {
        path: '/login'
    }

    @observable loading = false;

    formOtpRef: FormInstance | null = null

    public render() {
        const layout = {
            labelCol: {span: 24}
        };
        const formPhone = <Form key={this.props.authStore!.authStep}
                                {...layout}
                                onFinish={this.props.authStore!.phoneVerification}>
            <Form.Item
                name="phone"
                label="Телефон"
                rules={[{required: true, message: 'Это обязательное поле.'}]}
            >
                <MaskedInput mask="+7(111)-111-11-11" autoFocus/>
            </Form.Item>

            <Row justify={"end"} gutter={15}>
                <Col><Button htmlType={"submit"} type={"primary"}
                             loading={this.props.authStore!.signingIn}>Отправить код</Button></Col>
            </Row>
        </Form>
        const formOtp = <Form
            key={this.props.authStore!.authStep}
            ref={ref => this.formOtpRef = ref}
            {...layout}
            onFinish={async data => {
                if(!await this.props.authStore!.otpVerification(data)){
                    this.formOtpRef?.resetFields()
                    message.error('Попробуйте еще раз.')
                }
            }}>
            <Form.Item
                name="accessCode"
                label={this.props.authStore?.loginType === "Otp"? "Код" : "Пароль"}
                rules={[
                    this.props.authStore?.loginType === "Otp"? {required: true, min: 6, max: 6, message: 'Нужно ввести 6 символов'}
                    : {required: true, min: 4, message: 'Нужно ввести не меньше 4-х символов'}
                ]}
            >
                {this.props.authStore?.loginType === "Otp"? <Input maxLength={6} minLength={6} autoFocus/> : <Input.Password  autoFocus/>}
            </Form.Item>
            <Row justify={"end"} gutter={15}>
                <React.Fragment>
                    <Col><Button htmlType={"button"} type={"default"}
                                 onClick={this.props.authStore!.reStartAuth}>Назад</Button></Col>
                    <Col><Button htmlType={"submit"} type={"primary"}
                                 loading={this.props.authStore!.signingIn}>Войти</Button></Col>
                </React.Fragment>
            </Row>
        </Form>

        const form = this.props.authStore!.authStep === "phone"
            ? formPhone
            : formOtp

        return <Row justify="center">
            <Col span={7}>
                <Card title={<Row justify={'center'}><Col><Image preview={false} src={'/logo.svg'}/></Col></Row>}>
                    {form}
                </Card>
            </Col>
        </Row>
    }
}
