import React from "react";
import {ChatStoreBase} from "./store/chatsStoreBase";
import {Tabs} from "antd";
import {computed} from "mobx";
import {observer} from "mobx-react";
import {Room} from "./store/room";
import {RoomListItem} from "./RoomListItem";
import moment from "moment-timezone";


export interface IRoomsGroupedByTagsProps {
    chatStore: ChatStoreBase
}

const tabTag = 'tab.'

@observer
export class RoomsGroupedByTags extends React.Component<IRoomsGroupedByTagsProps, any> {
    @computed get groupByTags() {
        const sortByName = (rooms: Room[]) => rooms.sort((a, b) => a.name.localeCompare(b.name))
        const dateIfNull = moment(1970, 'year')
        const sortByLatestMessageDate = (rooms: Room[]) => rooms.sort((a, b) => (b.latestMessage?.dateTime ?? dateIfNull).diff(a.latestMessage?.dateTime ?? dateIfNull))


        const byTags: { tag: string, rooms: Room[] }[] = []

        const sortedByReadStatus = sortByLatestMessageDate(sortByName(this.props.chatStore.roomList?.rooms?.slice() ?? []))
        sortedByReadStatus
            .forEach(room => {
                room.tags
                    .filter(tag => tag.startsWith(tabTag))
                    .forEach(tag => {
                        tag = tag.replace(tabTag, '')
                        let byTag = byTags.find(added => added.tag === tag)
                        if (!byTag) {
                            byTag = {tag: tag, rooms: []}
                            byTags.push(byTag)
                        }
                        byTag.rooms.push(room)
                    })
            })
        return byTags.sort((a, b) => a.tag.localeCompare(b.tag))
    }

    render() {
        return <>
            <Tabs type={'card'} style={{height: '100%', overflowY: 'auto'}} defaultActiveKey={this.groupByTags[0]?.tag}
                  key={this.groupByTags.length}>
                {this.groupByTags.map(byTag => <Tabs.TabPane key={byTag.tag} tab={byTag.tag} tabKey={byTag.tag}>
                        <div>
                            {byTag.rooms.map(room => <div key={room.id}>
                                <RoomListItem room={room}
                                              selected={this.props.chatStore.roomList!.activeRoom?.id === room.id}
                                              selectRoom={this.props.chatStore.roomList!.selectRoom}/>
                            </div>)}

                        </div>
                    </Tabs.TabPane>
                )}
            </Tabs>
        </>;
    }
}



