import {BranchDto} from "../../services/api/CoreApiClient";
import {inject, observer} from "mobx-react";
import React from "react";
import {computed} from "mobx";
import {Select} from "antd";
import {InjectNames} from "../../stores/initializeStores";
import {CompanyUnitStore} from "../../stores/companyUnitStore";

interface IBranchFilterProps {
    companyUnitStore?: CompanyUnitStore
    onChange: (branchFilter: BranchDto | undefined) => any
}

@inject((InjectNames.companyUnitStore))
@observer
export class BranchFilter extends React.Component<IBranchFilterProps> {
    @computed get officeFilterJson() {
        return !!this.props.companyUnitStore!.branchFilter ? JSON.stringify(this.props.companyUnitStore!.branchFilter) : undefined
    }

    setOfficeFilterJson = (valueJson: string | undefined) => {
        const value = !!valueJson?.length ? JSON.parse(valueJson) : undefined
        this.props.companyUnitStore!.setBranchParam(value)
        this.props.onChange(this.props.companyUnitStore!.branchFilter)
    }

    formatName = (branch: BranchDto) => branch.branchName

    render() {
        return <Select value={this.officeFilterJson}
                       onChange={value => this.setOfficeFilterJson(value as string | undefined)} showSearch={true}
                       allowClear={false} style={{minWidth: 200}}>
            {this.props.companyUnitStore!.sortedBranches
                ?.map(branch => <Select.Option
                    key={`${branch.companyId}, ${branch.branchId}`}
                    value={JSON.stringify(branch)}>{this.formatName(branch)}</Select.Option>)}
        </Select>;
    }
}
