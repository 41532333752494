import React from "react";
import {inject, observer} from "mobx-react";
import {InjectNames} from "../../stores/initializeStores";
import {AuthStore} from "../../stores/auth/authStore";
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { CSSProperties } from 'react';

interface IProps {
    authStore?: AuthStore
}

const formStyle: CSSProperties = ({ 
    position: 'relative',
    top: '40%',
  })

const { confirm } = Modal;
function showConfirmDeleteForm(handleSignOut: () => void) {
    confirm({
      title: 'Вы действительно хотите выйти?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Да',
      okType: 'danger',
      cancelText: 'Отменить',
      onOk:() => { 
        handleSignOut()
      },
      style: formStyle
    });
  }

@inject(InjectNames.authStore)
@observer
export class LogoutScene extends React.Component<IProps> {
    public static route = {
        path: '/logout'
    }
    componentDidMount() {
        return showConfirmDeleteForm(this.props.authStore!.signOut)
    }

    render() {
        return <div></div>;
    }
}