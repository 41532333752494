import * as React from "react";
import {ReactNode} from "react";
import {Redirect, Route} from "react-router";
import {
    CarOutlined,
    CommentOutlined,
    FormOutlined,
    HistoryOutlined,
    LogoutOutlined,
    PhoneOutlined,
    TeamOutlined
} from "@ant-design/icons";
import {LoginScene} from "../scenes/unauthorized/Login/LoginScene";
import {SessionsScene} from "../scenes/authorized/Sessions/SessionsScene";
import {CreateDrivingSessionScene} from "../scenes/authorized/Sessions/CreateDrivingSessionScene";
import {DrivingSessionDetailsScene} from "../scenes/authorized/Sessions/DrivingSessionDetailsScene";
import {StudentsScene} from "../scenes/authorized/Students/StudentsScene";
import {StudentDetailsScene} from "../scenes/authorized/Students/StudentDetailsScene";
import {HistoryScene} from "../scenes/authorized/History/HistoryScene";
import {InstructorsScene} from "../scenes/authorized/Instructors/InstructorsScene";
import {InstructorDetailsScene} from "../scenes/authorized/Instructors/InstructorDetailsScene";
import {LogoutScene} from "../scenes/authorized/Logout";
import {CallsScene} from "../scenes/authorized/Calls/CallsScene";
import {ChatScene} from "../scenes/authorized/Chats/ChatScene";

export interface IRouteInfo extends IMenuItemInfo {
    exact?: boolean;
    render: (props: any) => React.ReactNode;
    showInMenu: boolean;
}

export interface IMenuItemInfo {
    path: string;
    title: string;
    icon?: any;
}

const notAuthorizedRoutePathsInfo: IRouteInfo[] = [
    {
        path: LoginScene.route.path,
        exact: true,
        title: '',
        icon: undefined,
        render: (props => <LoginScene {...props}/>),
        showInMenu: false
    }, {
        path: '/',
        exact: false,
        title: '',
        icon: undefined,
        render: (props => <Redirect to={LoginScene.route.path}/>),
        showInMenu: false
    }
];


const authorizedRoutePathsInfo: IRouteInfo[] = [
    {
        path: CallsScene.route.path,
        exact: true,
        title: 'Обзвон',
        icon: <PhoneOutlined/>,
        render: (props => <CallsScene {...props}/>),
        showInMenu: true
    },
    {
        path: SessionsScene.route.path,
        exact: true,
        title: 'Занятия',
        icon: <FormOutlined/>,
        render: (props => <SessionsScene {...props}/>),
        showInMenu: true
    },
    {
        path: ChatScene.route.path,
        exact: true,
        title: 'Чаты',
        icon: <CommentOutlined/>,
        render: (props => <ChatScene {...props}/>),
        showInMenu: true
    },
    {
        path: CreateDrivingSessionScene.route.path,
        exact: true,
        title: '',
        render: (props => <CreateDrivingSessionScene {...props}/>),
        showInMenu: false
    },
    {
        path: DrivingSessionDetailsScene.route.path,
        exact: true,
        title: '',
        render: (props => <DrivingSessionDetailsScene {...props}/>),
        showInMenu: false
    },
    {
        path: InstructorsScene.route.path,
        exact: true,
        title: 'Инструкторы',
        icon: <CarOutlined/>,
        render: (props => <InstructorsScene {...props}/>),
        showInMenu: true
    },
    {
        path: InstructorDetailsScene.route.path,
        exact: true,
        title: '',
        render: (props => <InstructorDetailsScene {...props}/>),
        showInMenu: false
    },
    {
        path: StudentsScene.route.path,
        exact: true,
        title: 'Ученики',
        icon: <TeamOutlined/>,
        render: (props => <StudentsScene {...props}/>),
        showInMenu: true
    },
    {
        path: StudentDetailsScene.route.path,
        exact: true,
        title: '',
        render: (props => <StudentDetailsScene {...props}/>),
        showInMenu: false
    },
    {
        path: HistoryScene.route.path,
        exact: true,
        title: 'История действий',
        icon: <HistoryOutlined/>,
        render: (props => <HistoryScene {...props}/>),
        showInMenu: true
    },
    {
        path: LogoutScene.route.path,
        exact: true,
        title: 'Выход',
        icon: <LogoutOutlined/>,
        render: (props => <LogoutScene/>),
        showInMenu: true
    },
    {
        path: '/',
        exact: false,
        title: '',
        render: (props => <Redirect to={CallsScene.route.path}/>),
        showInMenu: false
    },
];

export function getNotAuthorizedRoutes(): ReactNode[] {
    return notAuthorizedRoutePathsInfo
        .map(info => <Route key={`${info.path}_${info.title}`} path={info.path} exact={info.exact}
                            render={info.render}/>)
}

export function getAuthorizedRoutes(): ReactNode[] {
    return authorizedRoutePathsInfo
        .map(info => <Route key={`${info.path}_${info.title}`} path={info.path} exact={info.exact}
                            render={info.render}/>)
}

export function getAuthorizedMenuList(): IMenuItemInfo[] {
    return authorizedRoutePathsInfo
        .filter(info => info.showInMenu);
}



