import './index.less';

import * as React from 'react';

import {Layout, Menu} from 'antd';
import {observer} from "mobx-react";
import {Link} from "react-router-dom";
import {IMenuItemInfo} from "../../../routing/RouteSettings";
import {observable} from "mobx";
import * as H from "history";


const {Sider} = Layout;

export interface ISiderMenuProps {
    history: H.History;
    location: H.Location
    layouts: IMenuItemInfo[];
    address: string | null | undefined
}

@observer
class SiderMenu extends React.Component<ISiderMenuProps> {
    @observable activeKey = undefined

    render() {
        const infoWidth = 200

        return (
            <Sider className={'sidebar'} theme={'light'} width={315} collapsed={false}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap', paddingTop: 15, paddingBottom: 10, backgroundColor: '#DFDDDD'
                }}>
                    <div><img style={{height: 54, width: infoWidth}} src={'/logo.svg'} alt={''}/></div>
                    <div style={{
                        textAlign: 'center',
                        margin: 5,
                        width: infoWidth,
                        borderRadius: '170px',
                        backgroundColor: 'white'
                    }}>
                        {this.props.address}
                    </div>
                </div>


                <Menu theme={'light'} mode="inline" selectedKeys={[this.props.location.pathname]}>
                    {this.props.layouts
                        .map((info: IMenuItemInfo, index: number) => {
                            return (
                                <Menu.Item key={info.path}>
                                    <Link to={info.path}>
                                        {info.icon}
                                        <span>{(info.title)}</span>
                                    </Link>
                                </Menu.Item>
                            );
                        })}
                </Menu>
            </Sider>
        );
    }
};

export default SiderMenu
